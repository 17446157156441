// =============================
// Email: info@ebenmonney.com
// www.ebenmonney.com/templates
// =============================

import { NgModule, ErrorHandler } from "@angular/core";
import { RouterModule } from "@angular/router";
import { HttpClientModule } from '@angular/common/http';

import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { ToastaModule } from 'ngx-toasta';
import { ChartsModule } from 'ng2-charts';
import { NguCarouselModule } from '@ngu/carousel';

import { AppRoutingModule } from './app-routing.module';
import { AppErrorHandler } from './app-error.handler';

import { SharedModule } from './shared/shared.module'
import { AdminModule } from './admin/admin.module';
import { SettingsModule } from './settings/settings.module';
import { FooterModule } from './shared/footer.component';
import { ThemePickerModule } from './shared/theme-picker.component';

import { AppTitleService } from './services/app-title.service';
import { AppTranslationService, TranslateLanguageLoader } from './services/app-translation.service';
import { ConfigurationService } from './services/configuration.service';
import { AlertService } from './services/alert.service';
import { LocalStoreManager } from './services/local-store-manager.service';
import { EndpointFactory } from './services/endpoint-factory.service';
import { NotificationService } from './services/notification.service';
import { NotificationEndpoint } from './services/notification-endpoint.service';
import { AccountService } from './services/account.service';
import { AccountEndpoint } from './services/account-endpoint.service';

import { AppComponent } from "./app.component";
import { LoginComponent } from "./components/login/login.component";
import { LoginControlComponent } from "./components/login/login-control.component";
import { LoginDialogComponent } from "./components/login/login-dialog.component";
import { HomeComponent } from "./components/home/home.component";
import { CustomersComponent } from "./components/customers/customers.component";
import { ProductsComponent } from "./components/products/products.component";
import { OrdersComponent } from "./components/orders/orders.component";
import { AboutComponent } from "./components/about/about.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";

import { BannerDemoComponent } from "./components/controls/banner-demo.component";
import { TodoDemoComponent } from "./components/controls/todo-demo.component";
import { NotificationsViewerComponent } from "./components/controls/notifications-viewer.component";
import { AddTaskDialogComponent } from './components/controls/add-task-dialog.component';
import { ReprezentariComponent } from "./components/reprezentari/reprezentari.component";
import { ReprezentantiComponent } from "./components/reprezentanti/reprezentanti.component";
import { ContulMeuComponent } from "./components/contulmeu/contulmeu.component";
import { CompanieComponent } from "./components/companie/companie.component";
import { CompanieReprezentariComponent } from "./components/companieReprezentari/companieReprezentari.component";
import { FacturiComponent } from "./components/facturi/facturi.component";
import { FacturiBackofficeComponent } from "./components/facturiBackoffice/facturiBackoffice.component";
import { CircuiteComponent } from "./components/circuite/circuite.component";
import { CircuiteClientComponent } from "./components/circuiteclient/circuiteclient.component";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { UserRegisterComponent } from '../app/admin/user-register.component';
import { UserPasswordComponent } from '../app/admin/user-password.component';
import { CompensariPreferentialeComponent } from "./components/compensaripreferentiale/compensaripreferentiale.component";
import { SelecteazaCompanieComponent } from "./components/controls/selecteazaCompanie.component";
import { AngularFileUploaderModule } from "angular-file-uploader";
import { CompensariClientComponent } from "./components/compensariclient/compensariclient.component";
import { FinanciarClientComponent } from "./components/financiarclient/financiarclient.component";
import { FinanciarBackofficeComponent } from "./components/financiarbackoffice/financiarbackoffice.component";
import { IncasariBackofficeComponent } from "./components/incasaribackoffice/incasaribackoffice.component";
import { ProformaComponent } from "./components/proforma/proforma.component";
import { ProformaClientComponent } from "./components/proformaClient/proformaClient.component";
import { CalculCircuiteComponent } from "./components/calculcircuite/calculcircuite.component";
import { CompanieDefinireComponent } from "./components/companiedefinire/companiedefinire.component";
import { AsociatiComponent } from "./components/asociati/asociati.component";
import { MatTableExporterModule } from 'mat-table-exporter';
import { CompensariNefacturateComponent } from "./components/compensarinefacturate/compensarinefacturate.component";
import { TarifeComponent } from "./components/tarife/tarife.component";
import { FinanciarRaportBackofficeComponent } from "./components/financiarRaportBackoffice/financiarRaportBackoffice.component"; //[Alex]
import { GhidUtilizare } from "./components/ghidUtilizare/ghidUtilizare.component"; //[Alex]
import { Imputernicire } from "./components/imputernicire/imputernicire.component"; //[Alex]
import { RaportListaCompaniiComponent } from "./components/raportlistacompanii/raportlistacompanii.component";
import { InformareGdprComponent } from "./components/informareGdpr/informareGdpr.component"; //[Alex]
import { RaportCircuiteFinalizateComponent } from './components/raportCircuiteFinalizate/raportCircuiteFinalizate.component';//[Alex]
import { RaportSituatiaCompensarilorFinalizateComponent } from './components/raportSituatiaCompensarilorFinalizate/raportSituatiaCompensarilorFinalizate.component'; //[Alex]
import { RaportSituatiiNrCompaniiComponent } from './components/raportSituatiiNrCompanii/raportSituatiiNrCompanii.component'; //[Alex]
import { ExtrasDeContComponent } from "./components/extrasdecont/extrasdecont.component";
import { FinanciarRaportBackofficeNewComponent } from "./components/financiarRaportBackofficeNew/financiarRaportBackofficeNew.component";

@NgModule({
  imports: [
    SharedModule,
    FooterModule,
    ThemePickerModule,
    HttpClientModule,
    AdminModule,
    SettingsModule,
    AppRoutingModule,
    AngularFileUploaderModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslateLanguageLoader
      }
    }),
    ToastaModule.forRoot(),
    ChartsModule,
    NguCarouselModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatTableExporterModule
  ],
  declarations: [
    AppComponent,
    LoginComponent, LoginControlComponent, LoginDialogComponent,
    HomeComponent,
    CustomersComponent,
    ProductsComponent,
    OrdersComponent,
    AboutComponent,
    NotFoundComponent,
    NotificationsViewerComponent,
    AddTaskDialogComponent,
    TodoDemoComponent,
    BannerDemoComponent,
    ReprezentariComponent,
    ContulMeuComponent,
    ReprezentantiComponent,
    CompanieComponent,
    CompanieReprezentariComponent,
    FacturiComponent,
    FacturiBackofficeComponent,
    UserRegisterComponent,
    UserPasswordComponent,
    CircuiteComponent,
    CompensariPreferentialeComponent,
    SelecteazaCompanieComponent,
    CompensariClientComponent,
    CircuiteClientComponent,
    FinanciarClientComponent,
    FinanciarBackofficeComponent,
    IncasariBackofficeComponent,
    ProformaComponent,
    ProformaClientComponent,
    CalculCircuiteComponent,
    CompanieDefinireComponent,
    AsociatiComponent,
    CompensariNefacturateComponent,
    TarifeComponent,
    FinanciarRaportBackofficeComponent,
    Imputernicire,
    GhidUtilizare,
    RaportListaCompaniiComponent,
    InformareGdprComponent,
    RaportCircuiteFinalizateComponent,
    RaportSituatiaCompensarilorFinalizateComponent,
    RaportSituatiiNrCompaniiComponent,
    ExtrasDeContComponent,
    FinanciarRaportBackofficeNewComponent
  ],
  providers: [
    { provide: 'BASE_URL', useFactory: getBaseUrl },
    { provide: ErrorHandler, useClass: AppErrorHandler },
    AlertService,
    ConfigurationService,
    AppTitleService,
    AppTranslationService,
    NotificationService,
    NotificationEndpoint,
    AccountService,
    AccountEndpoint,
    LocalStoreManager,
    EndpointFactory
  ],
  entryComponents: [
    LoginDialogComponent,
    AddTaskDialogComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}



export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}
