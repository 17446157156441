// =============================
// Email: info@ebenmonney.com
// www.ebenmonney.com/templates
// =============================

export class ContulMeu
{
  public id: string;
  public nume: string;
  public prenume: string;
  public cnp: string;
  public document: string;
  public localitate: string;
  public adresa: string;
  public banca: string;
  public contBancar: string;
  public telefon: string;
  public stare: string;
  public stareAfisare: string;
  public fisierCI: string;
  public companiiReprezentateString: string;
  public documentCI: File;
}
