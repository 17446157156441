FileSaver// =============================
// Email: info@ebenmonney.com
// www.ebenmonney.com/templates
// =============================

import { Component } from '@angular/core';
import { fadeInOut } from '../../services/animations';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { AccountService } from "../../services/account.service";
import { ContulMeu } from 'src/app/models/contulMeu.model';
import * as FileSaver from 'file-saver'; 

@Component({
  selector: 'contulmeu',
  templateUrl: './contulmeu.component.html',
  styleUrls: ['./contulmeu.component.scss'],
    animations: [fadeInOut]
})
export class ContulMeuComponent
{
  private isSaving: boolean;
  private isNew: boolean;
  reprezentareForm: FormGroup;
  contulMeu: ContulMeu;
  
  afuConfig = {
    uploadAPI: {
      url: "https://example-file-upload-api"
    }
  };

  constructor(
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
  )
  {
    this.buildForm();
  }

  ngOnInit() {
    this.contulMeu = new ContulMeu();
    this.afiseazaDateDinContulMeu();
  }

  afiseazaDateDinContulMeu() {
    this.accountService
      .getUserContulMeu()
      .subscribe(result => {
        this.contulMeu = result;
      },
        error => {
        });
  }

  save() {
    if (this.reprezentareForm.valid) {
      this.accountService.newContulMeu(this.contulMeu).subscribe(
        role => this.saveSuccessHelper(this.contulMeu),
        error => this.saveFailedHelper(error));
    }
    else {
      
    }
  }

  private saveSuccessHelper(contulMeu?: ContulMeu) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage("Success", `Datele contului au fost salvate cu succes!`, MessageSeverity.success);
    this.afiseazaDateDinContulMeu();
  }

  private saveFailedHelper(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage("Save Error", "In procesul de salvare s-au produs urmatoarele erori: ", MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  private buildForm() {
    this.reprezentareForm = this.formBuilder.group({
      tbNume: ['', Validators.required],
      tbPrenume: ['', Validators.required],
      tbCNP: ['', Validators.required],
      tbDocument: ['', Validators.required],
      tbLocalitate: ['', Validators.required],
      tbAdresa: ['', Validators.required],
      //tbBanca: ['', Validators.required],
      //tbContBancar: ['', Validators.required],
      tbTelefon: ['', Validators.required],
      tbStare: new FormControl({ value: '' })
    });
  }

  get tbNume() {
    return this.reprezentareForm.get('tbNume');
  }

  get tbPrenume() {
    return this.reprezentareForm.get('tbPrenume');
  }

  get tbCNP() {
    return this.reprezentareForm.get('tbCNP');
  }

  get tbDocument() {
    return this.reprezentareForm.get('tbDocument');
  }

  get tbLocalitate() {
    return this.reprezentareForm.get('tbLocalitate');
  }

  get tbAdresa() {
    return this.reprezentareForm.get('tbAdresa');
  }

  //get tbBanca() {
  //  return this.reprezentareForm.get('tbBanca');
  //}

  //get tbContBancar() {
  //  return this.reprezentareForm.get('tbContBancar');
  //}

  get tbTelefon() {
    return this.reprezentareForm.get('tbTelefon');
  }

  get tbStare() {
    return this.reprezentareForm.get('tbStare');
  }
  
  private cancel() {

  }

  handleFileInputCI(files: FileList) {
    this.contulMeu.documentCI = files.item(0);
  }

  incarcaFisierCI() {
    if (this.contulMeu.documentCI == null) {
      this.alertService.stopLoadingMessage();
      this.alertService.showStickyMessage("Eroare incarcare fisier", "Fisierul nu este selectat!", MessageSeverity.error);
      return;
    }

    const formData = new FormData();
    formData.append('file', this.contulMeu.documentCI, this.contulMeu.documentCI.name);

    this.accountService.uploadFisierDocumentCI(this.contulMeu.id, formData).subscribe((result) => {
      this.alertService.stopLoadingMessage();
      this.alertService.showMessage("Incarcare fisier", "Fisier incarcat cu succes!", MessageSeverity.success);
      this.afiseazaDateDinContulMeu();
    },
      error => {
        this.alertService.stopLoadingMessage();
        this.alertService.showStickyMessage("Eroare incarcare ordin", "In procesul de incarcare s-au produs urmatoarele erori: ", MessageSeverity.error, error);
        this.alertService.showStickyMessage(error, null, MessageSeverity.error);
        console.log(error);
      });
  }

  descarcaFisierCI(fisier: string) {
    this.accountService.downloadDocCI(fisier).subscribe(result => {
      let blob = new Blob(result, {
        type: 'application/pdf' // must match the Accept type
      });

      let filename = 'CI_' + this.contulMeu.id + '.pdf';
      FileSaver.saveAs(blob, filename);
    });
  }
}
