
import { Component, OnInit, OnDestroy, AfterViewInit, Input, TemplateRef, ViewChild } from '@angular/core';
import { fadeInOut } from '../../services/animations';
import { debounceTime, distinctUntilChanged, switchMap, catchError } from 'rxjs/operators';
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar, MatDialog } from '@angular/material';
import { AccountService } from "../../services/account.service";
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { of } from 'rxjs';
import { Companie } from 'src/app/models/companie.model';
import { DetaliuCircuit } from 'src/app/models/DetaliuCircuit.model';
import { Compensare } from 'src/app/models/compensare.model';
import { CompensareDetaliu } from 'src/app/models/CompensareDetaliu.model';
import * as FileSaver from 'file-saver';
import { CompensareFacturare, GenerareFactura } from 'src/app/models/CompensareFacturare.model';
import { Factura } from 'src/app/models/factura.model';
import { FacturaServiciu } from 'src/app/models/FacturaServiciu.model';
import { DateTimeAdapter } from 'ng-pick-datetime';
import { RandRaportCircuiteFinalizate } from '../../models/RandRaportCircuiteFinalizate';

@Component({
  selector: 'raportCircuiteFinalizate',
  templateUrl: './raportCircuiteFinalizate.component.html',
  styleUrls: ['./raportCircuiteFinalizate.component.scss'],
  animations: [fadeInOut]
})
export class RaportCircuiteFinalizateComponent {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  dataInceput: Date;
  dataFinal: Date;
  displayedColumns = ['DenumireLuna', 'NrNod2', 'NrNod3', 'NrNod4', 'NrNod5', 'NrNod6', 'NrNod7', 'NrNod8', 'NrNod9', 'NrNod10', 'NrNodPeste10', 'TotalNoduri'];

  isDataLoaded: boolean = false;
  loadingIndicator: boolean = true;
  formResetToggle: boolean = true;
  data: RandRaportCircuiteFinalizate[];
  dataSource: MatTableDataSource<RandRaportCircuiteFinalizate>;
  //rand: RandRaportCircuiteFinalizate = new RandRaportCircuiteFinalizate();
  constructor(
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    dateTimeAdapter: DateTimeAdapter<any>
  ) {
    dateTimeAdapter.setLocale('ro-RO');
    this.dataSource = new MatTableDataSource<RandRaportCircuiteFinalizate>();
  }

  ngOnInit() {
    this.dataFinal = new Date();
    this.dataInceput = new Date();
    this.dataInceput.setDate(this.dataInceput.getDate() - 10);

    this.afiseazaDate();
  }

  afiseazaDate() {

    let dataInceput = this.dataInceput == null ? "undefined" : this.formatOnlyDate(this.dataInceput, ".");
    let dataFinal = this.dataFinal == null ? "undefined" : this.formatOnlyDate(this.dataFinal, ".");

    this.loadingIndicator = true;

    this.accountService
      .getRaportCircuiteFinalizate(dataInceput, dataFinal)
      .subscribe(results => {

        let linii = results[0];

        let liniiReconverted = JSON.parse(JSON.stringify(linii));

        this.data = liniiReconverted;
        console.log(this.data);

        this.dataSource.data = this.data;

        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        setTimeout(() => { this.loadingIndicator = false; }, 1500);
      },
        error => {
        });
  }

  formatOnlyDate(value: Date, separator: string): string {
    var date = new Date(value);
    var mm = date.getMonth() + 1; // getMonth() is zero-based
    var dd = date.getDate();

    return [
      (dd > 9 ? '' : '0') + dd,
      (mm > 9 ? '' : '0') + mm,
      date.getFullYear()
    ].join(separator);
  }
}
