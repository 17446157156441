import { Component, OnInit, OnDestroy, AfterViewInit, Input, TemplateRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar, MatDialog, PageEvent } from '@angular/material';
import { fadeInOut } from '../../services/animations';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { AccountService } from "../../services/account.service";
import { Factura, FacturaJurnal } from 'src/app/models/factura.model';
import { OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { DateTimeAdapter } from 'ng-pick-datetime';
import { Companie } from 'src/app/models/companie.model';
import { debounceTime, distinctUntilChanged, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { forEach } from '@angular/router/src/utils/collection';
import { FacturaFisier, IncarcareFactura } from 'src/app/models/FacturaFisier.model';
import * as FileSaver from 'file-saver';
import { DetaliuCircuit } from 'src/app/models/DetaliuCircuit.model';
import { SelecteazaCompanieComponent } from '../controls/selecteazaCompanie.component';
import { ElementRef } from '@angular/core';

@Component({
    selector: 'facturi',
  templateUrl: './facturi.component.html',
  styleUrls: ['./facturi.component.scss'],
  animations: [fadeInOut],
})

export class FacturiComponent
{
  private isSaving: boolean;
  private isNew: boolean;
  facturaForm: FormGroup;
  factura: Factura = new Factura();
  definire: string = "C";
  companieReprezentata: Companie = new Companie();
  companiiCautate: Companie[];
  serie: string = '';
  numar: string = '';
  stare: string = '';
  valoare_minima: string = '';
  valoare_maxima: string = '';
  sold_minim: string = '';
  sold_maxim: string = '';
  data_facturare_inceput: Date;
  data_facturare_final: Date;

  data_inceput: Date;
  data_final: Date;
  utilizator_incarcare: string = '';
  denumire_fisier_incarcat: string = '';
  displayedColumns = ['selectie', 'client', 'furnizor', 'serie', 'numar', 'data', 'dataScadenta', 'valoare', 'sold', 'status', 'actions'];
  displayedColumnsFacturiIncarcate = ['denumireFisier', 'timpRulare', 'statusRulare', 'rezultat', 'numeUser', 'data', 'actions'];
  displayedColumnsSumarFacturi: string[] = ['cuiCreditor', 'cuiDebitor', 'serie', 'numar', 'dataEmitereString', 'dataScadentaString', 'valoareString', 'soldString', 'sumarIncarcare'];
  isDataLoaded: boolean = false;
  loadingIndicator: boolean = true;
  formResetToggle: boolean = true;
  _currentUserId: string;
  data: Factura[];
  jurnalFacturaData: FacturaJurnal[];
  dataSource: MatTableDataSource<Factura>;
  dataSourceSumarIncarcare: MatTableDataSource<FacturaFisier>;
  partener: DetaliuCircuit = new DetaliuCircuit();
  furnizor: DetaliuCircuit = new DetaliuCircuit();
  client: DetaliuCircuit = new DetaliuCircuit();
  afisareIncarcareFacturi: boolean = false;
  incarcareFacturaList: MatTableDataSource<IncarcareFactura>;
  denumireFurnizor = 'Furnizor';
  denumirePartener = 'Partener';
  denumireClient = 'Client';
  @ViewChild('inputFile') myInputVariable: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('paginatorSumarIncarcare') paginatorSumarIncarcare!: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('selecteazaCompaniePartenerControl') selecteazaCompaniePartenerControl: SelecteazaCompanieComponent;
  @ViewChild('selecteazaCompanieFurnizorControl') selecteazaCompanieFurnizorControl: SelecteazaCompanieComponent;
  @ViewChild('selecteazaCompanieClientControl') selecteazaCompanieClientControl: SelecteazaCompanieComponent;
  totalItemsSumarIncarcare = 0;
  pageSizeSumarIncarcare = 100;
  pageNumberSumarIncarcare = 1;
  randCurentIncarcareFactura: IncarcareFactura;

  afiseazaDate() {

    if (this.companieReprezentata == null) {
      this.alertService.showMessage("Info", "Pentru a vizualiza facturile, trebuie sa selectati mai intai compania pe care o reprezentati din modulul Reprezentari!", MessageSeverity.warn);
      return;
    }
    
    this.loadingIndicator = true;

    let partener = "undefined";
    if (this.partener != null) {
      partener = this.partener.companieId;
    }

    let furnizor = "undefined";
    if (this.furnizor != null) {
      furnizor = this.furnizor.companieId;
    }

    let client = "undefined";
    if (this.client != null) {
      client = this.client.companieId;
    }

    let serie = this.serie == null || this.serie == "" ? "undefined" : this.serie;
    let numar = this.numar == null || this.numar == "" ? "undefined" : this.numar;

    let stare = this.stare == null || this.stare == "" ? "undefined" : this.stare;
    let data_facturare_inceput = this.data_facturare_inceput == null ? "undefined" : this.formatOnlyDate(this.data_facturare_inceput, ".");
    let data_facturare_final = this.data_facturare_final == null ? "undefined" : this.formatOnlyDate(this.data_facturare_final, ".");
    let valoare_minima = this.valoare_minima == null || this.valoare_minima == "" ? "undefined" : this.valoare_minima;
    let valoare_maxima = this.valoare_maxima == null || this.valoare_maxima == "" ? "undefined" : this.valoare_maxima;
    let sold_minim = this.sold_minim == null || this.sold_minim == "" ? "undefined" : this.sold_minim;
    let sold_maxim = this.sold_maxim == null || this.sold_maxim == "" ? "undefined" : this.sold_maxim;

    if (data_facturare_inceput === "undefined") {
      this.alertService.showStickyMessage("Eroare data inceput", "Introduceti o data facturare inceput corecta!", MessageSeverity.error);
      this.loadingIndicator = false;
      return;
    }

    if (data_facturare_final === "undefined") {
      this.alertService.showStickyMessage("Eroare data inceput", "Introduceti o data facturare sfarsit corecta!", MessageSeverity.error);
      this.loadingIndicator = false;
      return;
    }

    this.accountService
      .getFacturiList(this.companieReprezentata.id, partener, furnizor, client, serie, numar, stare, data_facturare_inceput, data_facturare_final, valoare_minima, valoare_maxima, sold_minim, sold_maxim)
      .subscribe(results => {

        let linii = results[0];

        let liniiReconverted = JSON.parse(JSON.stringify(linii));

        this.data = liniiReconverted;

        this.dataSource.data = this.data;

        this.isDataLoaded = true;

        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        setTimeout(() => { this.loadingIndicator = false; }, 1500);
      },
        error => {
          this.alertService.showStickyMessage("Eroare afisare facturi", "In procesul de afisare s-au produs urmatoarele erori: ", MessageSeverity.error, error);
          this.alertService.showStickyMessage(error, null, MessageSeverity.error);
          this.loadingIndicator = false;
        });

    setTimeout(() => { this.loadingIndicator = false; }, 1500);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.incarcareFacturaList.paginator = this.paginator;
    this.incarcareFacturaList.sort = this.sort;
    if (this.paginatorSumarIncarcare != undefined) {
      this.dataSourceSumarIncarcare.sort = this.sort;
    }
  }

  reset() {
    this.serie = '';
    this.numar = '';
    this.partener = new DetaliuCircuit();
    this.furnizor = new DetaliuCircuit();
    this.client = new DetaliuCircuit();
    this.selecteazaCompaniePartenerControl.reset();
    this.selecteazaCompanieFurnizorControl.reset();
    this.selecteazaCompanieClientControl.reset();
  }

  constructor(
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    dateTimeAdapter: DateTimeAdapter<any>,
    private cdr: ChangeDetectorRef
  )
  {
    dateTimeAdapter.setLocale('ro-RO');
    this.dataSource = new MatTableDataSource<Factura>();
    this.incarcareFacturaList = new MatTableDataSource<IncarcareFactura>();
    this.dataSourceSumarIncarcare = new MatTableDataSource<FacturaFisier>();
    this.buildForm();
  }

  ngOnInit() {
    this.data_facturare_final = new Date();
    this.data_facturare_inceput = new Date();
    this.data_facturare_inceput.setDate(this.data_facturare_inceput.getDate() - 10);
    this.data_final = new Date();
    this.data_inceput = new Date();
    this.data_inceput.setDate(this.data_inceput.getDate() - 7);

    this.accountService
      .getCompanieSelectata()
      .subscribe(result => {
        this.companieReprezentata = result;
        this.afiseazaDate();
      },
        error => {
        });

    this.factura = new Factura();
  }

  retriveListOfFurnizor() {
    this.tbFurnizorAutocomplete
      .valueChanges
      .pipe(debounceTime(300),
        distinctUntilChanged(),
        switchMap(term => this.accountService.getCompaniiReprezentateListForAutocomplete(term)),
        catchError(error => {
          return of<any[]>([]);
        })
      )
      .subscribe((result) => {
        if (result != null) {
          if (result.length > 0) {
            let liniiReconverted = JSON.parse(JSON.stringify(result[0]));
            this.companiiCautate = liniiReconverted;
          }
        }
      })
  }

  getCompanieFurnizor(event: any) {
    let searchTerm = '';
    searchTerm += event.target.value;
    if (searchTerm.length >= 2) {
      this.retriveListOfFurnizor();
    }
  }

  retriveListOfClient() {
    this.tbClientAutocomplete
      .valueChanges
      .pipe(debounceTime(300),
        distinctUntilChanged(),
        switchMap(term => this.accountService.getCompaniiReprezentateListForAutocomplete(term)),
        catchError(error => {
          return of<any[]>([]);
        })
      )
      .subscribe((result) => {
        if (result != null) {
          if (result.length > 0) {
            let liniiReconverted = JSON.parse(JSON.stringify(result[0]));
            this.companiiCautate = liniiReconverted;
          }
        }
      })
  }

  getCompanieClient(event: any) {
    let searchTerm = '';
    searchTerm += event.target.value;
    if (searchTerm.length >= 2) {
      this.retriveListOfClient();
    }
  }

  selecteazaCompanieFurnizor(companie: Companie) {
    this.tbFurnizorAutocomplete.setValue(companie.denumire);
    this.factura.furnizorId = companie.id;
  }

  selecteazaCompanieClient(companie: Companie) {
    this.tbClientAutocomplete.setValue(companie.denumire);
    this.factura.clientId = companie.id;
  }

  save() {
    if (this.facturaForm.valid) {

      if (0 > this.factura.valoare) {
        this.alertService.showMessage("Info", "Valoarea facturii nu poate fi negativa!", MessageSeverity.warn);
        return;
      }

      if (0 > this.factura.sold) {
        this.alertService.showMessage("Info", "Valoarea soldului nu poate fi negativa!", MessageSeverity.warn);
        return;
      }

      if (this.factura.sold > this.factura.valoare) {
        this.alertService.showMessage("Info", "Valoarea soldului nu trebuie sa depaseasca valoarea facturii!", MessageSeverity.warn);
        return;
      }

      /*
      if (this.factura.data > this.factura.dataScadenta) {
        this.alertService.showMessage("Info", "Data facturii nu poate fi mai mare decat data scadentei!", MessageSeverity.warn);
        return;
      }
      */

      this.factura.dataString = this.formatOnlyDate(this.factura.data, "/");

      try {
        this.factura.dataScadentaString = this.formatOnlyDate(this.factura.dataScadenta, "/");
      }
      catch{ }

      this.accountService.definireFactura(this.factura).subscribe(
        role => this.saveSuccessHelper(this.factura, `Factura a fost salvata cu succes!`),
        error => this.saveFailedHelper(error));
    }
    else {
      
    }
  }

  formatOnlyDate(value: Date, separator: string): string {
    var date = new Date(value);
    var mm = date.getMonth() + 1; // getMonth() is zero-based
    var dd = date.getDate();

    return [
      (dd > 9 ? '' : '0') + dd,
      (mm > 9 ? '' : '0') + mm,
      date.getFullYear()
    ].join(separator);
  }

  private saveSuccessHelper(factura?: Factura, mesaj?: string) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();

    this.alertService.showMessage("Success", mesaj, MessageSeverity.success);

    this.definire = "C";
    this.afiseazaDate();
  }

  private saveFailedHelper(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage("Save Error", "In procesul de salvare s-au produs urmatoarele erori: ", MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  private buildForm() {
    
    this.facturaForm = this.formBuilder.group({
      tbFurnizor: [''],
      tbFurnizorAutocomplete: [''],
      tbClient: [''],
      tbClientAutocomplete: [''],
      tbSerie: [''],
      tbNumar: ['', Validators.required],
      tbData: ['', Validators.required],
      tbDataScadenta: [''],
      tbValoare: ['', Validators.required],
      tbSold: ['', Validators.required],
    });
    
  }

  get tbFurnizor() {
    return this.facturaForm.get('tbDenumire');
  }

  get tbClient() {
    return this.facturaForm.get('tbClient');
  }

  get tbSerie() {
    return this.facturaForm.get('tbSerie');
  }

  get tbNumar() {
    return this.facturaForm.get('tbNumar');
  }

  get tbData() {
    return this.facturaForm.get('tbData');
  }

  get tbDataScadenta() {
    return this.facturaForm.get('tbDataScadenta');
  }

  get tbValoare() {
    return this.facturaForm.get('tbValoare');
  }

  get tbSold() {
    return this.facturaForm.get('tbSold');
  }

  get tbFurnizorAutocomplete() {
    return this.facturaForm.get('tbFurnizorAutocomplete');
  }

  get tbClientAutocomplete() {
    return this.facturaForm.get('tbClientAutocomplete');
  }

  private cancel() {
    this.definire = "C";
    this.afiseazaDate();
  }

  definire_CatreClient_sau_catreFurnizor: string = "";

  adaugare() {
    this.doarEditSoldSiValoare = true;
    this.companiiCautate = null;
    this.definire_CatreClient_sau_catreFurnizor = "CatreClient";
    this.definire = "A";

    this.factura = new Factura();
    this.factura.furnizor = this.companieReprezentata.denumire;
    this.factura.furnizorId = this.companieReprezentata.id;
    this.factura.data = new Date();
    this.factura.dataScadenta = new Date();
    //this.factura.dataScadenta.setDate(this.factura.data.getDate() + 30);
    this.factura.validataFurnizor = "Y";
  }

  adaugareFacturaFurnizor() {
    this.doarEditSoldSiValoare = true;
    this.companiiCautate = null;
    this.definire_CatreClient_sau_catreFurnizor = "CatreFurnizor";
    this.definire = "A";

    this.factura = new Factura();
    this.factura.client = this.companieReprezentata.denumire;
    this.factura.clientId = this.companieReprezentata.id;
    this.factura.data = new Date();
    this.factura.dataScadenta = new Date();
    this.factura.dataScadenta.setDate(this.factura.data.getDate() + 30);
    this.factura.validataClient = "Y";
  }

  valideazaCereriSelectate() {
    let facturiSelectate: Factura[] = [];

    for (let factura of this.data) {
      if (factura.selectata) {
        if ((factura.validataFurnizor != 'Y' && factura.validataClient == 'Y' && this.companieReprezentata.id == factura.furnizorId) ||
            (factura.validataFurnizor == 'Y' && factura.validataClient != 'Y' && this.companieReprezentata.id == factura.clientId)) { 
        facturiSelectate.push(factura);
        }
      }
    }

    this.accountService.valideazaFacturi(facturiSelectate).subscribe(
      role => this.saveSuccessHelperValidareFacturi(this.data, `Validarea s-a realizat cu succes!`),
      error => this.saveFailedHelper(error));
  }

  private saveSuccessHelperValidareFacturi(facturi: Factura[], mesaj?: string) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();

    this.alertService.showMessage("Success", mesaj, MessageSeverity.success);

    this.definire = "C";
    this.afiseazaDate();
  }

  doarEditSoldSiValoare: boolean;
  modificaFactura(facturaModificata: Factura) {
    
    this.doarEditSoldSiValoare = false;
    this.companiiCautate = null;
    this.definire = "A";

    this.factura = new Factura();
    this.factura.id = facturaModificata.id;
    this.factura.client = facturaModificata.client;
    this.factura.clientId = facturaModificata.clientId;
    this.factura.furnizor = facturaModificata.furnizor;
    this.factura.furnizorId = facturaModificata.furnizorId;
    this.factura.data = facturaModificata.data;
    this.factura.dataScadenta = facturaModificata.dataScadenta;

    // Acestea le-am comentat, intrucat le updatam mai sus
    //this.factura.validataClient = facturaModificata.validataClient;
    //this.factura.validataFurnizor = facturaModificata.validataFurnizor;
    if (facturaModificata.clientId == this.companieReprezentata.id) {
      this.definire_CatreClient_sau_catreFurnizor = "CatreFurnizor";
      this.factura.validataFurnizor = null;
      this.factura.validataClient = "Y";
    }
    else {
      this.definire_CatreClient_sau_catreFurnizor = "CatreClient";
      this.factura.validataFurnizor = "Y";
      this.factura.validataClient = null;
    }
    //

    this.factura.serie = facturaModificata.serie;
    this.factura.numar = facturaModificata.numar;
    this.factura.valoare = facturaModificata.valoare;
    this.factura.sold = facturaModificata.sold;
  }

  cereStergereFactura(facturaModificata: Factura) {
    this.alertService.showDialog('Confirmati', 'Confirmati stergerea facturii ?', DialogType.confirm, () => this.stergeFactura(facturaModificata));
  }

  stergeFactura(facturaModificata: Factura) {
    facturaModificata.anulata = true;
    this.accountService.anuleazaFactura(facturaModificata).subscribe(
      role => this.saveSuccessHelper(facturaModificata, `Factura a fost anulata!`),
      error => this.saveFailedHelper(error));
  }

  jurnalFactura(factura: Factura) {
    this.accountService
      .getJurnalFactura(factura.id)
      .subscribe(results => {

        let linii = results[0];

        let liniiReconverted = JSON.parse(JSON.stringify(linii));

        this.jurnalFacturaData = liniiReconverted;

        this.definire = "J";
        
      },
        error => {
        });
  }

  cancelFacturiNincarcate() {
    this.definire = "I";
    this.getIncarcareFactura();
  }

  getIncarcareFactura() {
    this.loadingIndicator = true;
    let data_inceput = this.data_inceput == null ? "undefined" : this.formatOnlyDate(this.data_inceput, ".");
    let data_final = this.data_final == null ? "undefined" : this.formatOnlyDate(this.data_final, ".");
    let utilizator_incarcare = this.utilizator_incarcare == null || this.utilizator_incarcare == "" ? "undefined" : this.utilizator_incarcare;
    let denumire_fisier_incarcat = this.denumire_fisier_incarcat == null || this.denumire_fisier_incarcat == "" ? "undefined" : this.denumire_fisier_incarcat;


    if (data_inceput === "undefined") {
      this.alertService.showStickyMessage("Eroare data inceput", "Introduceti o data inceput corecta!", MessageSeverity.error);
      this.loadingIndicator = false;
      return;
    }

    if (data_final === "undefined") {
      this.alertService.showStickyMessage("Eroare data sfarsit", "Introduceti o data sfarsit corecta!", MessageSeverity.error);
      this.loadingIndicator = false;
      return;
    }
    this.accountService
      .getIncarcareFactura(data_inceput, data_final, utilizator_incarcare, denumire_fisier_incarcat)
      .subscribe(results => {

        let linii = results[0];

        let liniiReconverted = JSON.parse(JSON.stringify(linii));

        this.incarcareFacturaList.data = liniiReconverted;

        this.isDataLoaded = true;

        this.incarcareFacturaList.paginator = this.paginator;
        this.incarcareFacturaList.sort = this.sort;

        this.loadingIndicator = false;
      },
        error => {
          this.loadingIndicator = false;
        });
  }

  openPanel_IncarcaFisierFacturi() {
    this.definire = 'I';
    this.getIncarcareFactura();
  }

  fileToUpload: File = null;
  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    this.afisareIncarcareFacturi = true;
  }
  
  uploadFileToActivity() {
    this.afisareIncarcareFacturi = false;
    this.alertService.showMessage("Info", "S-a pornit un job de procesare a fisierului! Rezultatul rularii va fi prezentat in lista de mai jos.", MessageSeverity.warn);
    
    if (this.fileToUpload === null) {
      this.alertService.stopLoadingMessage();
      this.alertService.showStickyMessage("Eroare incarcare fisier", "Fisierul nu este selectat!", MessageSeverity.error);
      return;
    }

    const formData = new FormData();
    formData.append('file', this.fileToUpload, this.fileToUpload.name);

    this.accountService.uploadFile("particular", "nedefinit", formData).subscribe((result) => {
      if (this.myInputVariable != undefined) {
        this.myInputVariable.nativeElement.value = '';
        setTimeout(() => {
          this.getIncarcareFactura();
        }, 5000);
      }
    },
      error => {
        console.log(error);
        if (this.myInputVariable != undefined) {
          this.myInputVariable.nativeElement.value = '';
          setTimeout(() => {
            this.getIncarcareFactura();
          }, 5000);
        }
      });
  }

  incarcaFisier() {
    this.uploadFileToActivity();
  }

  descarcaModelImportFacturi() {
    this.accountService.descarcaModelImportFacturi().subscribe(result => {
      let blob = new Blob(result, {
        type: 'application/vnd.ms-excel' // must match the Accept type
      });

      let filename = 'Model_import.xlsx';
      FileSaver.saveAs(blob, filename);
    });
  }

  // Gestionarea evenimentului de schimbare a paginii
  onPageChangeSumarIncarcare(event: PageEvent) {
    this.pageNumberSumarIncarcare = event.pageIndex;
    this.pageSizeSumarIncarcare = event.pageSize;

    // Încarcă datele pentru noua pagină
    this.detaliiRulare(this.randCurentIncarcareFactura, this.pageNumberSumarIncarcare, this.pageSizeSumarIncarcare);
  }

  detaliiRulare(rand: IncarcareFactura, pageNumber: number, pageSize: number) {
    this.randCurentIncarcareFactura = rand;

    this.loadingIndicator = true;
    this.accountService
      .getDetaliiIncarcareFactura(rand.id, pageNumber, pageSize)
      .subscribe(result => {
        this.loadingIndicator = false;

        let linie = result[0];

        let paginatedResponse = JSON.parse(JSON.stringify(linie));

        this.definire = "X";

        this.totalItemsSumarIncarcare = paginatedResponse.totalCount;

        setTimeout(() => {
          this.dataSourceSumarIncarcare.data = paginatedResponse.items;
          this.dataSourceSumarIncarcare.sort = this.sort;
          this.paginatorSumarIncarcare.length = paginatedResponse.totalCount;
          this.cdr.detectChanges();
        });
      },
        error => {
          this.loadingIndicator = false;
        });
  }
}
