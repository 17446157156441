// =============================
// Email: info@ebenmonney.com
// www.ebenmonney.com/templates
// =============================

export class Companie
{
  public id: string;
  public denumire: string;
  public cif: string;
  public regCom: string;
  public localitate: string;
  public adresa: string;
  public banca: string;
  public contBancar: string;
  public telefon: string;
  public email: string;
  public reprezentant: string;
  public reprezentantid: string;
  public companieid: string;
  public stare: string;
  public judet: string;
  public stareafisare: string;
  public companieSelectata: boolean;
  public tipNotificare: string;
  public fisierCUI: string;
  public fisierBI: string;
  public fisierImputernicire: string;
  public fisierAprobareAdministratorJudiciar: string;
  public fisierAcordCompensareCuNerezidenti: string;

  public documentCUI: File;
  public documentBI: File;
  public documentImputernicire: File;
  public documentAprobareAdministratorJudiciar: File;
  public documentAcordCompensareCuNerezidenti: File;

}

