// =============================
// Email: info@ebenmonney.com
// www.ebenmonney.com/templates
// =============================

import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';

import { EndpointFactory } from './endpoint-factory.service';
import { ConfigurationService } from './configuration.service';
import { ExtrasDeCont } from '../models/ExtrasDeCont';
import { Data } from '@angular/router';

@Injectable()
export class AccountEndpoint extends EndpointFactory {

  private readonly _usersUrl: string = "/api/account/users";
  private readonly _userByUserNameUrl: string = "/api/account/users/username";
  private readonly _currentUserUrl: string = "/api/account/users/me";
  private readonly _currentUserPreferencesUrl: string = "/api/account/users/me/preferences";
  private readonly _unblockUserUrl: string = "/api/account/users/unblock";
  private readonly _rolesUrl: string = "/api/account/roles";
  private readonly _roleByRoleNameUrl: string = "/api/account/roles/name";
  private readonly _permissionsUrl: string = "/api/account/permissions";
  private readonly _contulMeuUrl: string = "/api/account/contulMeu";
  private readonly _contulMeuListUrl: string = "/api/account/contulMeuList";
  private readonly _aprobareContulMeuUrl: string = "/api/account/aprobareContulMeu";
  private readonly _companieUrl: string = "/api/account/companie";
  private readonly _saveCompanieUrl: string = "/api/account/saveCompanie";
  private readonly _companiiReprezentateUrl: string = "/api/account/companiiReprezentate";
  private readonly _companiiListUrl: string = "/api/account/companiiList";
  private readonly _companiiReprezentateAutocompleteUrl: string = "/api/account/companiiReprezentateAutocomplete";
  private readonly _aprobarereprezentareCompanie: string = "/api/account/aprobarereprezentareCompanie";
  private readonly _anuleazareprezentareCompanie: string = "/api/account/anuleazareprezentareCompanie";  
  private readonly _selectieCompanie: string = "/api/account/selectieCompanie";
  private readonly _getCompanieSelectata: string = "/api/account/getCompanieSelectata";
  private readonly _definireFacturaUrl: string = "/api/account/definireFactura";
  private readonly _definireExtrasUrl: string = "/api/account/definireExtras";
  private readonly _sendMailExtrasUrl: string = "/api/account/sendMailExtras";
  private readonly _anuleazaFacturaServiciuUrl: string = "/api/account/anuleazaFacturaServiciu";
  private readonly _facturiListUrl: string = "/api/account/facturiList";
  private readonly _jurnalFacturaUrl: string = "/api/account/jurnalFactura";
  private readonly _getIncarcareFacturaUrl: string = "/api/files/getIncarcareFactura";
  private readonly _getDetaliiIncarcareFacturaUrl: string = "/api/files/getDetaliiIncarcareFactura";
  private readonly _valideazaFacturiUrl: string = "/api/account/valideazaFacturi";
  private readonly _stergeFacturiUrl: string = "/api/account/stergeFacturi";
  private readonly _usersFromOutsideUrl: string = "/api/accountFromOutside/usersFromOutside";
  private readonly _sendMailForPasswordRecoveryUrl: string = "/api/accountFromOutside/sendMailForPasswordRecovery";
  private readonly _confirmUserUrl: string = "/api/accountFromOutside/confirmUser";
  private readonly _getCompaniiUrl: string = "/api/accountFromOutside/getCompanii";
  private readonly _definireCircuitCompensareUrl: string = "/api/account/definireCircuitCompensare";
  private readonly _definireAsociatUrl: string = "/api/account/definireAsociat";
  private readonly _stergeAsociatUrl: string = "/api/account/stergeAsociat";
  private readonly _definireCircuitUrl: string = "/api/account/definireCircuit";
  private readonly _aprobaCircuitUrl: string = "/api/account/aprobaCircuit";
  private readonly _getCompensariListUrl: string = "/api/account/getCompensariList";
  private readonly _getAsociatListUrl: string = "/api/account/getAsociatList"; 
  private readonly _getCompensariCircuiteListUrl: string = "/api/account/getCompensariCircuiteList";
  private readonly _getCompensariListPtFacturareUrl: string = "/api/account/getCompensariListPtFacturare";
  private readonly _getFacturiClientListUrl: string = "/api/account/getFacturiClientList";
  private readonly _getFacturiProformeListUrl: string = "/api/account/getFacturiProformeList";
  private readonly _getIncasariEndpointUrl: string = "/api/account/getIncasari";
  private readonly _getCircuiteListUrl: string = "/api/account/getCircuiteList";
  private readonly _getCircuiteListCuFiltraretUrl: string = "/api/account/getCircuiteListCuFiltrare";
  private readonly _getDetaliiCompensareUrl: string = "/api/account/getDetaliiCompensare";
  private readonly _getDetaliiCircuitUrl: string = "/api/account/getDetaliiCircuit";
  private readonly _implementareCompensareUrl: string = "/api/account/implementareCompensare";
  private readonly _modificareCompensareInGenerareCircuitUrl: string = "/api/account/modificareCompensareInGenerareCircuit";
  private readonly _salveazaModificareBorderouUrl: string = "/api/account/salveazaModificareBorderou"; 
  private readonly _aprobareFinalaCompensareUrl: string = "/api/account/aprobareFinalaCompensare";
  private readonly _uploadFilesUrl: string = "/api/files/uploadFiles";
  private readonly _getDownloadFileUrl: string = "/api/account/download";
  private readonly _getDownloadBorderouFileUrl: string = "/api/account/downloadBorderou";
  private readonly _getDownloadProcesVerbalFileUrl: string = "/api/account/downloadProcesVerbal";
  private readonly _uploadOrdinFilesUrl: string = "/api/files/uploadOrdinFiles";
  private readonly _uploadBorderouFilesUrl: string = "/api/files/uploadBorderouFiles";
  private readonly _getDownloadFileSemnatUrl: string = "/api/account/downloadOrdinSemnat";
  private readonly _getDownloadBorderouFileSemnatUrl: string = "/api/account/downloadBorderouSemnat";
  private readonly _getDownloadFileSemnatFinalUrl: string = "/api/account/downloadOrdinSemnatFinal";
  private readonly _getDownloadBorderouFileSemnatFinalUrl: string = "/api/account/downloadBorderouSemnatFinal";
  private readonly _uploadDocumentCiUrl: string = "/api/files/uploadDocumentCiUrl";
  private readonly _getDownloadFileCIUrl: string = "/api/account/downloadCI";
  private readonly _getModelImportFacturiUrl: string = "/api/account/getModelImportFacturi";
  private readonly _uploadDocumentCompanieUrl: string = "/api/files/uploadDocumentCompanie";
  private readonly _getDownloadDocumentCompanieUrl: string = "/api/account/downloadDocumentCompanie";
  private readonly _genereazaFacturaServiciuUrl: string = "/api/account/genereazaFacturaServiciu";
  private readonly _genereazaFacturaProformaUrl: string = "/api/account/genereazaFacturaProforma";
  private readonly _genereazaIncasareUrl: string = "/api/account/genereazaIncasare";
  private readonly _genereazaFacturaSiChitantaServiciuUrl: string = "/api/account/genereazaFacturaSiChitantaServiciu";
  private readonly _getDownloadFacturaFileUrl: string = "/api/account/getDownloadFacturaFile";
  private readonly _getDownloadFacturaProformaFileUrl: string = "/api/account/getDownloadFacturaProformaFile";
  private readonly _getDownloadIncasareFileUrl: string = "/api/account/getDownloadIncasareFile";
  private readonly _getDownloadExtrasFileUrl: string = "/api/account/getDownloadExtrasFile";
  private readonly _getRaportListaCompaniiUrl: string = "/api/account/getRaportListaCompanii";
  private readonly _getImputernicire: string ="/api/account/getImputernicire"; //[Alex]
  private readonly _getFormular: string = "/api/account/getFormular"; //[Alex]
  private readonly _getRaportCircuiteFinalizateUrl: string = "/api/account/getRaportCircuiteFinalizate"; //[Alex]
  private readonly _getRaportSituatiaCompensarilorFinalizateUrl: string = "/api/account/getRaportSituatiaCompensarilorFinalizate" //[Alex]
  private readonly _getRaportSituatiiNrCompaniiUrl: string = "/api/account/getRaportSituatiiNrCompanii" //[Alex]
  private readonly _getExtrasDeContListUrl: string = "/api/account/getExtrasDeContList";
  private readonly _getFacturiInExtrasListUrl: string = "/api/account/getFacturiInExtrasList";
  private readonly _getCompensariListPtFacturareProformaUrl: string = "/api/account/getCompensariListPtFacturareProforma";

  get usersUrl() { return this.configurations.baseUrl + this._usersUrl; }
  get userByUserNameUrl() { return this.configurations.baseUrl + this._userByUserNameUrl; }
  get currentUserUrl() { return this.configurations.baseUrl + this._currentUserUrl; }
  get currentUserPreferencesUrl() { return this.configurations.baseUrl + this._currentUserPreferencesUrl; }
  get unblockUserUrl() { return this.configurations.baseUrl + this._unblockUserUrl; }
  get rolesUrl() { return this.configurations.baseUrl + this._rolesUrl; }
  get roleByRoleNameUrl() { return this.configurations.baseUrl + this._roleByRoleNameUrl; }
  get permissionsUrl() { return this.configurations.baseUrl + this._permissionsUrl; }
  get contulMeuUrl() { return this.configurations.baseUrl + this._contulMeuUrl; }
  get contulMeuListUrl() { return this.configurations.baseUrl + this._contulMeuListUrl; }
  get aprobareContulMeu() { return this.configurations.baseUrl + this._aprobareContulMeuUrl; }
  get companieUrl() { return this.configurations.baseUrl + this._companieUrl; }
  get saveCompanieUrl() { return this.configurations.baseUrl + this._saveCompanieUrl; }
  get companiiReprezentateUrl() { return this.configurations.baseUrl + this._companiiReprezentateUrl; }
  get companiiListUrl() { return this.configurations.baseUrl + this._companiiListUrl; }
  get companiiReprezentateAutocompleteUrl() { return this.configurations.baseUrl + this._companiiReprezentateAutocompleteUrl; } 
  get aprobarereprezentareCompanie() { return this.configurations.baseUrl + this._aprobarereprezentareCompanie; }
  get anuleazareprezentareCompanie() { return this.configurations.baseUrl + this._anuleazareprezentareCompanie; }
  get selectieCompanie() { return this.configurations.baseUrl + this._selectieCompanie; }
  get getCompanieSelectata() { return this.configurations.baseUrl + this._getCompanieSelectata; }
  get definireFacturaUrl() { return this.configurations.baseUrl + this._definireFacturaUrl; }
  get definireExtrasUrl() { return this.configurations.baseUrl + this._definireExtrasUrl; }
  get sendMailExtrasUrl() { return this.configurations.baseUrl + this._sendMailExtrasUrl; }
  get anuleazaFacturaServiciuUrl() { return this.configurations.baseUrl + this._anuleazaFacturaServiciuUrl; }
  get facturiListUrl() { return this.configurations.baseUrl + this._facturiListUrl; }
  get jurnalFacturaUrl() { return this.configurations.baseUrl + this._jurnalFacturaUrl; }
  get getIncarcareFacturaUrl() { return this.configurations.baseUrl + this._getIncarcareFacturaUrl; }
  get getDetaliiIncarcareFacturaUrl() { return this.configurations.baseUrl + this._getDetaliiIncarcareFacturaUrl; }
  get valideazaFacturiUrl() { return this.configurations.baseUrl + this._valideazaFacturiUrl; }
  get stergeFacturiUrl() { return this.configurations.baseUrl + this._stergeFacturiUrl; }
  get usersFromOutsideUrl() { return this.configurations.baseUrl + this._usersFromOutsideUrl; }
  get sendMailForPasswordRecoveryUrl() { return this.configurations.baseUrl + this._sendMailForPasswordRecoveryUrl; }
  get confirmUserUrl() { return this.configurations.baseUrl + this._confirmUserUrl; }
  get getCompaniiUrl() { return this.configurations.baseUrl + this._getCompaniiUrl; }
  get definireCircuitCompensareUrl() { return this.configurations.baseUrl + this._definireCircuitCompensareUrl; }
  get definireAsociatUrl() { return this.configurations.baseUrl + this._definireAsociatUrl; }
  get stergeAsociatUrl() { return this.configurations.baseUrl + this._stergeAsociatUrl; }
  get definireCircuitUrl() { return this.configurations.baseUrl + this._definireCircuitUrl; }
  get aprobaCircuitUrl() { return this.configurations.baseUrl + this._aprobaCircuitUrl; }
  get getCompensariListUrl() { return this.configurations.baseUrl + this._getCompensariListUrl; }
  get getAsociatListUrl() { return this.configurations.baseUrl + this._getAsociatListUrl; } 
  get getCompensariCircuiteListUrl() { return this.configurations.baseUrl + this._getCompensariCircuiteListUrl; }
  get getCompensariListPtFacturareUrl() { return this.configurations.baseUrl + this._getCompensariListPtFacturareUrl; }
  get getFacturiClientListUrl() { return this.configurations.baseUrl + this._getFacturiClientListUrl; }
  get getFacturiProformeListUrl() { return this.configurations.baseUrl + this._getFacturiProformeListUrl; }
  get getIncasariEndpointUrl() { return this.configurations.baseUrl + this._getIncasariEndpointUrl; }
  get getCircuiteListUrl() { return this.configurations.baseUrl + this._getCircuiteListUrl; }
  get getCircuiteListCuFiltraretUrl() { return this.configurations.baseUrl + this._getCircuiteListCuFiltraretUrl; }
  get getDetaliiCompensareUrl() { return this.configurations.baseUrl + this._getDetaliiCompensareUrl; }
  get getDetaliiCircuitUrl() { return this.configurations.baseUrl + this._getDetaliiCircuitUrl; }
  get implementareCompensareUrl() { return this.configurations.baseUrl + this._implementareCompensareUrl; }
  get modificareCompensareInGenerareCircuitUrl() { return this.configurations.baseUrl + this._modificareCompensareInGenerareCircuitUrl; }
  get salveazaModificareBorderouUrl() { return this.configurations.baseUrl + this._salveazaModificareBorderouUrl; }
  get aprobareFinalaCompensareUrl() { return this.configurations.baseUrl + this._aprobareFinalaCompensareUrl; }
  get uploadFilesUrl() { return this.configurations.baseUrl + this._uploadFilesUrl; }
  get getDownloadFileUrl() { return this.configurations.baseUrl + this._getDownloadFileUrl; }
  get getDownloadBorderouFileUrl() { return this.configurations.baseUrl + this._getDownloadBorderouFileUrl; }
  get getDownloadProcesVerbalFileUrl() { return this.configurations.baseUrl + this._getDownloadProcesVerbalFileUrl; }
  get uploadOrdinFilesUrl() { return this.configurations.baseUrl + this._uploadOrdinFilesUrl; }
  get uploadBorderouFilesUrl() { return this.configurations.baseUrl + this._uploadBorderouFilesUrl; }
  get getDownloadFileSemnatUrl() { return this.configurations.baseUrl + this._getDownloadFileSemnatUrl; }
  get getDownloadBorderouFileSemnatUrl() { return this.configurations.baseUrl + this._getDownloadBorderouFileSemnatUrl; }
  get getDownloadFileSemnatFinalUrl() { return this.configurations.baseUrl + this._getDownloadFileSemnatFinalUrl; }
  get getDownloadBorderouFileSemnatFinalUrl() { return this.configurations.baseUrl + this._getDownloadBorderouFileSemnatFinalUrl; }
  get uploadDocumentCiUrl() { return this.configurations.baseUrl + this._uploadDocumentCiUrl; }
  get getDownloadFileCIUrl() { return this.configurations.baseUrl + this._getDownloadFileCIUrl; }
  get getModelImportFacturiUrl() { return this.configurations.baseUrl + this._getModelImportFacturiUrl; }
  get uploadDocumentCompanieUrl() { return this.configurations.baseUrl + this._uploadDocumentCompanieUrl; }
  get getDownloadDocumentCompanieUrl() { return this.configurations.baseUrl + this._getDownloadDocumentCompanieUrl; }
  get genereazaFacturaServiciuUrl() { return this.configurations.baseUrl + this._genereazaFacturaServiciuUrl; }
  get genereazaFacturaProformaUrl() { return this.configurations.baseUrl + this._genereazaFacturaProformaUrl; }
  get genereazaFacturaSiChitantaServiciuUrl() { return this.configurations.baseUrl + this._genereazaFacturaSiChitantaServiciuUrl; }
  get genereazaIncasareUrl() { return this.configurations.baseUrl + this._genereazaIncasareUrl; }
  get getDownloadFacturaFileUrl() { return this.configurations.baseUrl + this._getDownloadFacturaFileUrl; }
  get getDownloadFacturaProformaFileUrl() { return this.configurations.baseUrl + this._getDownloadFacturaProformaFileUrl; }
  get getDownloadIncasareFileUrl() { return this.configurations.baseUrl + this._getDownloadIncasareFileUrl; }
  get getDownloadExtrasFileUrl() { return this.configurations.baseUrl + this._getDownloadExtrasFileUrl; }
  get getRaportListaCompaniiUrl() { return this.configurations.baseUrl + this._getRaportListaCompaniiUrl; }
  get getImputernicireUrl() { return this.configurations.baseUrl + this._getImputernicire; } //[Alex]   
  get getFormularUrl() { return this.configurations.baseUrl + this._getFormular; } //[Alex]
  get getRaportCircuiteFinalizateUrl() { return this.configurations.baseUrl + this._getRaportCircuiteFinalizateUrl; } //[Alex]
  get getRaportSituatiaCompensarilorFinalizateUrl() { return this.configurations.baseUrl + this._getRaportSituatiaCompensarilorFinalizateUrl; } //[Alex]
  get getRaportSituatiiNrCompaniiUrl() { return this.configurations.baseUrl + this._getRaportSituatiiNrCompaniiUrl; } //[Alex]
  get getExtrasDeContListUrl() { return this.configurations.baseUrl + this._getExtrasDeContListUrl; }
  get getFacturiInExtrasListUrl() { return this.configurations.baseUrl + this._getFacturiInExtrasListUrl; }
  get getCompensariListPtFacturareProformaUrl() { return this.configurations.baseUrl + this._getCompensariListPtFacturareProformaUrl; }


 

  
  
  constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector) {

    super(http, configurations, injector);
  }

  getUserEndpoint<T>(userId?: string): Observable<T> {
    let endpointUrl = userId ? `${this.usersUrl}/${userId}` : this.currentUserUrl;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUserEndpoint(userId));
      }));
  }

  getUserByUserNameEndpoint<T>(userName: string): Observable<T> {
    let endpointUrl = `${this.userByUserNameUrl}/${userName}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUserByUserNameEndpoint(userName));
      }));
  }

  getUsersEndpoint<T>(page?: number, pageSize?: number): Observable<T> {
    let endpointUrl = page && pageSize ? `${this.usersUrl}/${page}/${pageSize}` : this.usersUrl;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUsersEndpoint(page, pageSize));
      }));
  }

  getNewUserEndpoint<T>(userObject: any): Observable<T> {

    return this.http.post<T>(this.usersUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getNewUserEndpoint(userObject));
      }));
  }

  confirmUserEndpoint<T>(userObject: any): Observable<T> {
    return this.http.post<T>(this.confirmUserUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.confirmUserEndpoint(userObject));
      }));
  }
  
  getNewUserFromOutsideEndpoint<T>(userObject: any): Observable<T> {
    return this.http.post<T>(this.usersFromOutsideUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getNewUserFromOutsideEndpoint(userObject));
      }));
  }

  sendMailForPasswordRecoveryEndpoint<T>(userObject: any): Observable<T> {

    return this.http.post<T>(this.sendMailForPasswordRecoveryUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.sendMailForPasswordRecoveryEndpoint(userObject));
      }));
  }

  


  getUpdateUserEndpoint<T>(userObject: any, userId?: string): Observable<T> {
    let endpointUrl = userId ? `${this.usersUrl}/${userId}` : this.currentUserUrl;

    return this.http.put<T>(endpointUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUpdateUserEndpoint(userObject, userId));
      }));
  }

  getPatchUpdateUserEndpoint<T>(patch: {}, userId?: string): Observable<T>
  getPatchUpdateUserEndpoint<T>(value: any, op: string, path: string, from?: any, userId?: string): Observable<T>
  getPatchUpdateUserEndpoint<T>(valueOrPatch: any, opOrUserId?: string, path?: string, from?: any, userId?: string): Observable<T> {
    let endpointUrl: string;
    let patchDocument: {};

    if (path) {
      endpointUrl = userId ? `${this.usersUrl}/${userId}` : this.currentUserUrl;
      patchDocument = from ?
        [{ "value": valueOrPatch, "path": path, "op": opOrUserId, "from": from }] :
        [{ "value": valueOrPatch, "path": path, "op": opOrUserId }];
    }
    else {
      endpointUrl = opOrUserId ? `${this.usersUrl}/${opOrUserId}` : this.currentUserUrl;
      patchDocument = valueOrPatch;
    }

    return this.http.patch<T>(endpointUrl, JSON.stringify(patchDocument), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getPatchUpdateUserEndpoint(valueOrPatch, opOrUserId, path, from, userId));
      }));
  }

  getUserPreferencesEndpoint<T>(): Observable<T> {

    return this.http.get<T>(this.currentUserPreferencesUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUserPreferencesEndpoint());
      }));
  }

  getUpdateUserPreferencesEndpoint<T>(configuration: string): Observable<T> {
    return this.http.put<T>(this.currentUserPreferencesUrl, JSON.stringify(configuration), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUpdateUserPreferencesEndpoint(configuration));
      }));
  }

  getUnblockUserEndpoint<T>(userId: string): Observable<T> {
    let endpointUrl = `${this.unblockUserUrl}/${userId}`;

    return this.http.put<T>(endpointUrl, null, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUnblockUserEndpoint(userId));
      }));
  }

  getDeleteUserEndpoint<T>(userId: string): Observable<T> {
    let endpointUrl = `${this.usersUrl}/${userId}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDeleteUserEndpoint(userId));
      }));
  }

  getRoleEndpoint<T>(roleId: string): Observable<T> {
    let endpointUrl = `${this.rolesUrl}/${roleId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getRoleEndpoint(roleId));
      }));
  }

  getRoleByRoleNameEndpoint<T>(roleName: string): Observable<T> {
    let endpointUrl = `${this.roleByRoleNameUrl}/${roleName}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getRoleByRoleNameEndpoint(roleName));
      }));
  }

  getRolesEndpoint<T>(page?: number, pageSize?: number): Observable<T> {
    let endpointUrl = page && pageSize ? `${this.rolesUrl}/${page}/${pageSize}` : this.rolesUrl;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getRolesEndpoint(page, pageSize));
      }));
  }

  getNewRoleEndpoint<T>(roleObject: any): Observable<T> {

    return this.http.post<T>(this.rolesUrl, JSON.stringify(roleObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getNewRoleEndpoint(roleObject));
      }));
  }

  getUpdateRoleEndpoint<T>(roleObject: any, roleId: string): Observable<T> {
    let endpointUrl = `${this.rolesUrl}/${roleId}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(roleObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUpdateRoleEndpoint(roleObject, roleId));
      }));
  }

  getDeleteRoleEndpoint<T>(roleId: string): Observable<T> {
    let endpointUrl = `${this.rolesUrl}/${roleId}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDeleteRoleEndpoint(roleId));
      }));
  }

  getPermissionsEndpoint<T>(): Observable<T> {

    return this.http.get<T>(this.permissionsUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getPermissionsEndpoint());
      }));
  }

  getNewContulMeuEndpoint<T>(userObject: any): Observable<T> {
    return this.http.post<T>(this.contulMeuUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getNewContulMeuEndpoint(userObject));
      }));
  }

  getUserContulMeuEndpoint<T>(userId?: string): Observable<T> {
    let endpointUrl = userId ? `${this.contulMeuUrl}/${userId}` : `${this.contulMeuUrl}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUserContulMeuEndpoint(userId));
      }));
  }

  getContulMeuListEndpoint<T>(nume?: string, companie?: string, stare?: string): Observable<T> {
    let endpointUrl = `${this.contulMeuListUrl}/${nume}&${companie}&${stare}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getContulMeuListEndpoint(nume, companie, stare));
      }));
  }

  getAprobareContulMeuEndpoint<T>(userObject: any): Observable<T> {
    return this.http.post<T>(this.aprobareContulMeu, JSON.stringify(userObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getAprobareContulMeuEndpoint(userObject));
      }));
  }

  getNewCompanieEndpoint<T>(userObject: any): Observable<T> {
    return this.http.post<T>(this.companieUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getNewCompanieEndpoint(userObject));
      }));
  }

  getSaveCompanieEndpoint<T>(userObject: any): Observable<T> {
    return this.http.post<T>(this.saveCompanieUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getSaveCompanieEndpoint(userObject));
      }));
  }

  getCompaniiReprezentateAutocompleteListEndpoint<T>(denumire?: string, cif?: string, all?: string): Observable<T> {
    let endpointUrl = `${this.companiiReprezentateAutocompleteUrl}/${denumire}&${cif}&${all}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCompaniiReprezentateAutocompleteListEndpoint(denumire, cif, all));
      }));
  }
  
  getCompaniiReprezentateListEndpoint<T>(denumire?: string, judet?: string, reprezentant?: string, stare?: string, all?: string): Observable<T> {
    let endpointUrl = `${this.companiiReprezentateUrl}/${denumire}&${judet}&${reprezentant}&${stare}&${all}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCompaniiReprezentateListEndpoint(denumire, judet, reprezentant, stare, all));
      }));
  }

  getCompaniiListEndpoint<T>(denumire?: string, judet?: string): Observable<T> {
    let endpointUrl = `${this.companiiListUrl}/${denumire}&${judet}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCompaniiReprezentateListEndpoint(denumire, judet));
      }));
  }

  aprobareReprezentareCompanieEndpoint<T>(userObject: any): Observable<T> {
    return this.http.post<T>(this.aprobarereprezentareCompanie, JSON.stringify(userObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.aprobareReprezentareCompanieEndpoint(userObject));
      }));
  }

  anuleazaReprezentareCompanieEndpoint<T>(userObject: any): Observable<T> {
    return this.http.post<T>(this.anuleazareprezentareCompanie, JSON.stringify(userObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.anuleazaReprezentareCompanieEndpoint(userObject));
      }));
  }

  selectieCompanieEndpoint<T>(userObject: any): Observable<T> {
    return this.http.post<T>(this.selectieCompanie, JSON.stringify(userObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.selectieCompanieEndpoint(userObject));
      }));
  }

  getCompanieSelectataEndpoint<T>(): Observable<T> {
    let endpointUrl = this.getCompanieSelectata;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCompanieSelectataEndpoint());
      }));
  }

  getDefinireFacturaEndpoint<T>(userObject: any): Observable<T> {
    return this.http.post<T>(this.definireFacturaUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDefinireFacturaEndpoint(userObject));
      }));
  }

  getDefinireExtrasEndpoint<T>(idCompanie: string, data: string, numar: string, dataNumar: string): Observable<T> {
    let endpointUrl = `${this.definireExtrasUrl}/${idCompanie}&${data}&${numar}&${dataNumar}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDefinireExtrasEndpoint(idCompanie, data, numar, dataNumar));
      }));
  }

  sendMailExtrasEndpoint<T>(listaExtrase: ExtrasDeCont[]): Observable<T> {
    return this.http.post<T>(this.sendMailExtrasUrl, JSON.stringify(listaExtrase), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.sendMailExtrasEndpoint(listaExtrase));
      }));
  }

  anuleazaFacturaServiciuEndpoint<T>(userObject: any): Observable<T> {
    return this.http.post<T>(this.anuleazaFacturaServiciuUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.anuleazaFacturaServiciuEndpoint(userObject));
      }));
  }

  getFacturiListEndpoint<T>(companieReprezentata?: string, partener?: string, furnizor?: string, client?: string, serie?: string, numar?: string,
    stare?: string, data_facturare_inceput?: string, data_facturare_final?: string, valoare_minima?: string, valoare_maxima?: string, sold_minim?: string, sold_maxim?: string): Observable<T> {
    let endpointUrl = `${this.facturiListUrl}/${companieReprezentata}&${partener}&${furnizor}&${client}&${serie}&${numar}&${stare}&${data_facturare_inceput}&${data_facturare_final}&${valoare_minima}&${valoare_maxima}&${sold_minim}&${sold_maxim}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getFacturiListEndpoint(partener, furnizor, client, serie, numar, stare, data_facturare_inceput, data_facturare_final, valoare_minima, valoare_maxima, sold_minim, sold_maxim));
      }));
  }

  getJurnalFacturaEndpoint<T>(idFactura?: string): Observable<T> {
    let endpointUrl = `${this.jurnalFacturaUrl}/${idFactura}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getJurnalFacturaEndpoint(idFactura));
      }));
  }

  getIncarcareFacturaEndpoint<T>(data_inceput?: string, data_final?: string, utilizator_incarcare?: string, denumire_fisier_incarcat?: string): Observable<T> {
    let endpointUrl = `${this.getIncarcareFacturaUrl}/${data_inceput}&${data_final}&${utilizator_incarcare}&${denumire_fisier_incarcat}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getIncarcareFacturaEndpoint(data_inceput, data_final, utilizator_incarcare, denumire_fisier_incarcat));
      }));
  }

  getDetaliiIncarcareFacturaEndpoint<T>(id: string): Observable<T> {
    let endpointUrl = `${this.getDetaliiIncarcareFacturaUrl}/${id}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDetaliiIncarcareFacturaEndpoint(id));
      }));
  }

  valideazaFacturiEndpoint<T>(userObject: any): Observable<T> {
    return this.http.post<T>(this.valideazaFacturiUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDefinireFacturaEndpoint(userObject));
      }));
  }

  stergeFacturiEndpoint<T>(userObject: any): Observable<T> {
    return this.http.post<T>(this.stergeFacturiUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.stergeFacturiEndpoint(userObject));
      }));
  }
  
  getCompaniiListForAutocompleteEndpoint<T>(denumire?: string, cif?: string, all?: string): Observable<T> {
    let endpointUrl = `${this.getCompaniiUrl}/${denumire}&${cif}&${all}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCompaniiListForAutocompleteEndpoint(denumire, cif, all));
      }));
  }

  definireCircuitEndpoint<T>(userObject: any): Observable<T> {
    return this.http.post<T>(this.definireCircuitUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.definireCircuitEndpoint(userObject));
      }));
  }

  aprobaCircuitEndpoint<T>(userObject: any): Observable<T> {
    return this.http.post<T>(this.aprobaCircuitUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.aprobaCircuitEndpoint(userObject));
      }));
  }

  definireCircuitCompensareEndpoint<T>(userObject: any): Observable<T> {
    return this.http.post<T>(this.definireCircuitCompensareUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.definireCircuitCompensareEndpoint(userObject));
      }));
  }

  definireAsociatEndpoint<T>(userObject: any): Observable<T> {
    return this.http.post<T>(this.definireAsociatUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.definireAsociatEndpoint(userObject));
      }));
  }

  stergeAsociatEndpoint<T>(userObject: any): Observable<T> {
    return this.http.post<T>(this.stergeAsociatUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.stergeAsociatEndpoint(userObject));
      }));
  }

  implementareCompensareEndpoint<T>(userObject: any): Observable<T> {
    return this.http.post<T>(this.implementareCompensareUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.implementareCompensareEndpoint(userObject));
      }));
  }

  modificareCompensareInGenerareCircuit<T>(userObject: any): Observable<T> {
    return this.http.post<T>(this.modificareCompensareInGenerareCircuitUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.modificareCompensareInGenerareCircuit(userObject));
      }));
  }

  salveazaModificareBorderouEndpoint<T>(userObject: any): Observable<T> {
    return this.http.post<T>(this.salveazaModificareBorderouUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.salveazaModificareBorderouEndpoint(userObject));
      }));
  }

  aprobareFinalaCompensareEndpoint<T>(userObject: any): Observable<T> {
    return this.http.post<T>(this.aprobareFinalaCompensareUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.aprobareFinalaCompensareEndpoint(userObject));
      }));
  }

  getCompensariListEndpoint<T>(tipCompensare?: string,
    numar_compensare?: string,
    data_inceput?: string,
    data_final?: string,
    valoare_minima?: string,
    valoare_maxima?: string,
    cui_denumire?: string,
    tip?: string,
    stare?: string): Observable<T> {
    let endpointUrl = `${this.getCompensariListUrl}/${tipCompensare}/${numar_compensare}/${data_inceput}/${data_final}/${valoare_minima}/${valoare_maxima}/${cui_denumire}/${tip}/${stare}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCompensariListEndpoint(tipCompensare, numar_compensare, data_inceput, data_final, valoare_minima, valoare_maxima, cui_denumire, tip, stare));
      }));
  }

  getAsociatiListEndpoint<T>(cui_denumire?: string): Observable<T> {
    let endpointUrl = `${this.getAsociatListUrl}/${cui_denumire}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getAsociatiListEndpoint(cui_denumire));
      }));
  }

  getCompensariCircuiteListEndpoint<T>(): Observable<T> {
    let endpointUrl = `${this.getCompensariCircuiteListUrl}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders())
      .pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCompensariCircuiteListEndpoint());
      }))
      .pipe(timeout(60000000));
  }

  getCompensariListPtFacturareEndpoint<T>(companieFactura: string): Observable<T> {
    let endpointUrl = `${this.getCompensariListPtFacturareUrl}/${companieFactura}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCompensariListPtFacturareEndpoint(companieFactura));
      }));
  }

  getFacturiClientListEndpoint<T>(firma: string, idFirma: string, dataInceput: string, dataFinal: string, stare: string): Observable<T> {
    let endpointUrl = `${this.getFacturiClientListUrl}/${firma}&${idFirma}&${dataInceput}&${dataFinal}&${stare}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getFacturiClientListEndpoint(firma, idFirma, dataInceput, dataFinal, stare));
      }));
  }

  getFacturiProformeListEndpoint<T>(firma: string, idFirma: string, dataInceput: string, dataFinal: string): Observable<T> {
    let endpointUrl = `${this.getFacturiProformeListUrl}/${firma}&${idFirma}&${dataInceput}&${dataFinal}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getFacturiProformeListEndpoint(firma, idFirma, dataInceput, dataFinal));
      }));
  }

  getIncasariEndpoint<T>(firma: string, dataInceput: string, dataFinal: string, stare: string): Observable<T> {
    let endpointUrl = `${this.getIncasariEndpointUrl}/${firma}&${dataInceput}&${dataFinal}&${stare}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getIncasariEndpoint(firma, dataInceput, dataFinal, stare));
      }));
  }

  getCircuiteListEndpoint<T>(tipCompensare?: string): Observable<T> {
    let endpointUrl = `${this.getCircuiteListUrl}/${tipCompensare}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCircuiteListEndpoint(tipCompensare));
      }));
  }

  getCircuiteListCuFiltrareEndpoint<T>(tipCompensare?: string, numar_curent?: string, companie?: string): Observable<T> {
    let endpointUrl = `${this.getCircuiteListCuFiltraretUrl}/${tipCompensare}/${numar_curent}/${companie}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders())
      .pipe<T>(
        catchError(error => {
          return this.handleError(error, () => this.getCircuiteListEndpoint(tipCompensare));
        }))
      .pipe(timeout(60000000));
  }

  getDetaliiCompensareEndpoint<T>(id?: string, tipCompensare?: string, idDetaliu?: string): Observable<T> {
    let endpointUrl = `${this.getDetaliiCompensareUrl}/${id}&${tipCompensare}&${idDetaliu}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDetaliiCompensareEndpoint(id, tipCompensare, idDetaliu));
      }));
  }

  getDetaliiCircuitEndpoint<T>(id?: string, tipCompensare?: string, idDetaliu?: string): Observable<T> {
    let endpointUrl = `${this.getDetaliiCircuitUrl}/${id}&${tipCompensare}&${idDetaliu}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDetaliiCircuitEndpoint(id, tipCompensare, idDetaliu));
      }));
  }
 
  downloadDocEndpoint<T>(filename?: string): Observable<T> {
    let endpointUrl = `${this.getDownloadFileUrl}/${filename}`;
    
    return this.http.get<T>(
      endpointUrl,
      this.getRequestDownloadFileHeaders(),
      ).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.downloadDocEndpoint());
      }));
  }

  downloadBorderouDocEndpoint<T>(filename?: string): Observable<T> {
    let endpointUrl = `${this.getDownloadBorderouFileUrl}/${filename}`;
    
    return this.http.get<T>(
      endpointUrl,
      this.getRequestDownloadFileHeaders(),
    ).pipe<T>(
      catchError(error => {
      return this.handleError(error, () => this.downloadBorderouDocEndpoint());
      }));
  }
  
  downloadDocSemnatEndpoint<T>(filename?: string): Observable<T> {
    let endpointUrl = `${this.getDownloadFileSemnatUrl}/${filename}`;
    
    return this.http.get<T>(
      endpointUrl,
      this.getRequestDownloadFileHeaders(),
    ).pipe<T>(
      catchError(error => {
      return this.handleError(error, () => this.downloadDocSemnatEndpoint());
      }));
  }

  downloadBorderouSemnatEndpoint<T>(filename?: string): Observable<T> {
    let endpointUrl = `${this.getDownloadBorderouFileSemnatUrl}/${filename}`;

    return this.http.get<T>(
      endpointUrl,
      this.getRequestDownloadFileHeaders(),
    ).pipe<T>(
      catchError(error => {
      return this.handleError(error, () => this.downloadBorderouSemnatEndpoint());
      }));
  }

  downloadDocSemnatFinalEndpoint<T>(filename?: string): Observable<T> {
    let endpointUrl = `${this.getDownloadFileSemnatFinalUrl}/${filename}`;
    
    return this.http.get<T>(
      endpointUrl,
      this.getRequestDownloadFileHeaders(),
    ).pipe<T>(
      catchError(error => {
      return this.handleError(error, () => this.downloadDocSemnatFinalEndpoint());
      }));
  }

  downloadDocBorderouSemnatFinalEndpoint<T>(filename?: string): Observable<T> {
    let endpointUrl = `${this.getDownloadBorderouFileSemnatFinalUrl}/${filename}`;

    return this.http.get<T>(
      endpointUrl,
      this.getRequestDownloadFileHeaders(),
    ).pipe<T>(
      catchError(error => {
      return this.handleError(error, () => this.downloadDocBorderouSemnatFinalEndpoint());
      }));
  }

  downloadDocProcesVerbalEndpoint<T>(filename?: string): Observable<T> {
    let endpointUrl = `${this.getDownloadProcesVerbalFileUrl}/${filename}`;
    
    return this.http.get<T>(
      endpointUrl,
      this.getRequestDownloadFileHeaders(),
    ).pipe<T>(
      catchError(error => {
      return this.handleError(error, () => this.downloadDocProcesVerbalEndpoint());
      }));
  }

  uploadEndpoint<T>(tipIncarcare: string, file: any): Observable<T> {
    return this.http.post<T>(`${this.uploadFilesUrl}/${tipIncarcare}`, file, this.getRequestHeadersFileUpload())
      .pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.uploadEndpoint(tipIncarcare, file));
      }))
      .pipe(timeout(60000000));
  }

  uploadOrdinEndpoint<T>(idDetaliu: string, file: any): Observable<T> {
    return this.http.post<T>(`${this.uploadOrdinFilesUrl}/${idDetaliu}`, file, this.getRequestHeadersFileUpload())
      .pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.uploadOrdinEndpoint(idDetaliu, file));
      }))
      .pipe(timeout(60000000));
  }

  uploadBorderouFileEndpoint<T>(idDetaliu: string, file: any): Observable<T> {
    return this.http.post<T>(`${this.uploadBorderouFilesUrl}/${idDetaliu}`, file, this.getRequestHeadersFileUpload())
      .pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.uploadBorderouFileEndpoint(idDetaliu, file));
      }))
      .pipe(timeout(60000000));
  }

  uploadFisierDocumentCIEndpoint<T>(idDetaliu: string, file: any): Observable<T> {
    return this.http.post<T>(`${this.uploadDocumentCiUrl}/${idDetaliu}`, file, this.getRequestHeadersFileUpload())
      .pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.uploadFisierDocumentCIEndpoint(idDetaliu, file));
      }))
      .pipe(timeout(60000000));
  }

  downloadDocCIEndpoint<T>(filename?: string): Observable<T> {
    let endpointUrl = `${this.getDownloadFileCIUrl}/${filename}`;

    return this.http.get<T>(
      endpointUrl,
      this.getRequestDownloadFileHeaders(),
    ).pipe<T>(
      catchError(error => {
      return this.handleError(error, () => this.downloadDocCIEndpoint());
      }));
  }

  descarcaModelImportFacturiEndpoint<T>(): Observable<T> {
    let endpointUrl = `${this.getModelImportFacturiUrl}`;

    return this.http.get<T>(
      endpointUrl,
      this.getRequestDownloadFileHeaders(),
    ).pipe<T>(
      catchError(error => {
      return this.handleError(error, () => this.descarcaModelImportFacturiEndpoint());
      }));
  }

  downloadDocumentCompanieEndpoint<T>(idReprezentant: string, idCompanie: string, mod: string): Observable<T> {
    let endpointUrl = `${this._getDownloadDocumentCompanieUrl}/${idReprezentant}/${idCompanie}/${mod}`;

    return this.http.get<T>(
      endpointUrl,
      this.getRequestDownloadFileHeaders(),
    ).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.downloadDocumentCompanieEndpoint(idReprezentant, idCompanie, mod));
      }));
  }

  uploadFisierDocumentCompanieEndpoint<T>(idReprezentant: string, idCompanie: string, file: any, mod: string): Observable<T> {
    return this.http.post<T>(`${this.uploadDocumentCompanieUrl}/${idReprezentant}/${idCompanie}/${mod}`, file, this.getRequestHeadersFileUpload())
      .pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.uploadFisierDocumentCompanieEndpoint(idReprezentant, idCompanie, file, mod));
      }))
      .pipe(timeout(60000000));
  }

  genereazaFacturaServiciuEndpoint<T>(userObject: any): Observable<T> {
    return this.http.post<T>(this.genereazaFacturaServiciuUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.genereazaFacturaServiciuEndpoint(userObject));
      }));
  }

  genereazaFacturaProformaEndpoint<T>(userObject: any): Observable<T> {
    return this.http.post<T>(this.genereazaFacturaProformaUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.genereazaFacturaProformaEndpoint(userObject));
      }));
  }

  genereazaFacturaSiChitantaServiciuEndpoint<T>(userObject: any): Observable<T> {
    return this.http.post<T>(this.genereazaFacturaSiChitantaServiciuUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.genereazaFacturaSiChitantaServiciuEndpoint(userObject));
      }));
  }

  genereazaIncasareEndpoint<T>(userObject: any): Observable<T> {
    return this.http.post<T>(this.genereazaIncasareUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.genereazaIncasareEndpoint(userObject));
      }));
  }

  downloadFacturaEndpoint<T>(filename?: string): Observable<T> {
    let endpointUrl = `${this.getDownloadFacturaFileUrl}/${filename}`;

    return this.http.get<T>(
      endpointUrl,
      this.getRequestDownloadFileHeaders(),
    ).pipe<T>(
      catchError(error => {
      return this.handleError(error, () => this.downloadFacturaEndpoint());
      }));
  }

  downloadFacturaProformaEndpoint<T>(filename?: string): Observable<T> {
    let endpointUrl = `${this.getDownloadFacturaProformaFileUrl}/${filename}`;

    return this.http.get<T>(
      endpointUrl,
      this.getRequestDownloadFileHeaders(),
    ).pipe<T>(
      catchError(error => {
      return this.handleError(error, () => this.downloadFacturaProformaEndpoint());
      }));
  }

  downloadIncasareEndpoint<T>(filename?: string): Observable<T> {
    let endpointUrl = `${this.getDownloadIncasareFileUrl}/${filename}`;

    return this.http.get<T>(
      endpointUrl,
      this.getRequestDownloadFileHeaders(),
    ).pipe<T>(
      catchError(error => {
      return this.handleError(error, () => this.downloadIncasareEndpoint());
      }));
  }

  downloadExtrasEndpoint<T>(filename?: string): Observable<T> {
    let endpointUrl = `${this.getDownloadExtrasFileUrl}/${filename}`;

    return this.http.get<T>(
      endpointUrl,
      this.getRequestDownloadFileHeaders(),
    ).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.downloadExtrasEndpoint());
      }));
  }

  getRaportListaCompaniiEndpoint<T>(dataInceput: string, dataFinal: string): Observable<T> {
    let endpointUrl = `${this.getRaportListaCompaniiUrl}/${dataInceput}&${dataFinal}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getRaportListaCompaniiEndpoint(dataInceput, dataFinal));
      }));
  }

  //////ALEX 

  descarcaFormularEndpoint<T>(): Observable<T> {
    let endpointUrl = `${this.getFormularUrl}`;

    return this.http.get<T>(
      endpointUrl,
      this.getRequestDownloadFileHeaders(),
    ).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.descarcaFormularEndpoint());
      }));
  }

  descarcaImputernicireEndpoint<T>(): Observable<T> {
    let endpointUrl = `${this.getImputernicireUrl}`;

    return this.http.get<T>(
      endpointUrl,
      this.getRequestDownloadFileHeaders(),
    ).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.descarcaImputernicireEndpoint());
      }));
  }


  getRaportCircuiteFinalizateEndpoint<T>(dataInceput: string, dataFinal: string): Observable<T> {
    let endpointUrl = `${this.getRaportCircuiteFinalizateUrl}/${dataInceput}&${dataFinal}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getRaportCircuiteFinalizateEndpoint(dataInceput, dataFinal));
      }));
  }

  getRaportSituatiaCompensarilorFinalizateEndpoint<T>(dataInceput: string, dataFinal: string): Observable<T> {
    let endpointUrl = `${this.getRaportSituatiaCompensarilorFinalizateUrl}/${dataInceput}&${dataFinal}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getRaportSituatiaCompensarilorFinalizateEndpoint(dataInceput, dataFinal));
      }));
  }

  

  getRaportSituatiiNrCompaniiEndpoint<T>(dataInceput: string, dataFinal: string, judet?: string): Observable<T> {
    let endpointUrl = `${this.getRaportSituatiiNrCompaniiUrl}/${dataInceput}&${dataFinal}&${judet}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getRaportSituatiiNrCompaniiEndpoint(dataInceput, dataFinal, judet));
      }));
  }

  getExtrasDeContListEndpoint<T>(firma: string, idFirma: string, dataInceput: string, dataFinal: string): Observable<T> {
    let endpointUrl = `${this.getExtrasDeContListUrl}/${firma}&${idFirma}&${dataInceput}&${dataFinal}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getExtrasDeContListEndpoint(firma, idFirma, dataInceput, dataFinal));
      }));
  }

  getFacturiInExtrasListEndpoint<T>(idCompanie: string, dataExtras: string): Observable<T> {
    let endpointUrl = `${this.getFacturiInExtrasListUrl}/${idCompanie}&${dataExtras}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getFacturiInExtrasListEndpoint(idCompanie, dataExtras));
      }));
  }
  ////////

  getCompensariListPtFacturareProformaEndpoint<T>(companieFactura: string): Observable<T> {
    let endpointUrl = `${this.getCompensariListPtFacturareProformaUrl}/${companieFactura}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCompensariListPtFacturareProformaEndpoint(companieFactura));
      }));
  }

}
