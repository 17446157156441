// =============================
// Email: info@ebenmonney.com
// www.ebenmonney.com/templates
// =============================

export class Factura
{
  public id: string;
  public furnizorId: string;
  public furnizor: string;
  public clientId: string;
  public client: string;
  public serie: string;
  public numar: string;
  public valoare: number;
  public sold: number;
  public stare: string;
  public data: Date;
  public dataScadenta?: Date;
  public validataFurnizor: string;
  public validataClient: string;
  public selectata: boolean;
  public anulata: boolean;
  public dataString: string;
  public dataScadentaString: string;
}

export class FacturaJurnal
{
  public id: string;
  public facturaId: string;
  public descriere: string;
  public data: string;
  public utilizator: string;
}

