import { Data } from "@angular/router";

export class CompensareFacturare
{
  public id: string;
  public companieId: string;
  public companie: string;
  public sumaPeRamura: number;
  public dataFinalizare: string;
  public hash: string;
  public facturaId: string;
  public factura: string;
  public chitantaId: string;
  public chitanta: string;
  public selectata: boolean;
  public compensareDetaliuId: string;
}

export class Incasare {
  public selectata: boolean;
  public id: string;
  public serie: string;
  public numar: number;
  public descriere: string;
  public data: string;
  public suma: number;
  public client: string;
  public tipIncasare: string;
}

export class IncasareVM {
  public selectata: string;
  public id: string;
  public serie: string;
  public numar: string;
  public descriere: string;
  public data: string;
  public suma: string;
  public client: string;
  public tipIncasare: string;
}

export class GenerareFactura {
  public companieId: string;
  public dataFactura: string;
  public compensari: CompensareFacturare[];
  public numarCircuiteFacturate: number;
}
