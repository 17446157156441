import { Component, OnInit, OnDestroy, AfterViewInit, Input, TemplateRef, ViewChild } from '@angular/core';
import { fadeInOut } from '../../services/animations';
import { debounceTime, distinctUntilChanged, switchMap, catchError } from 'rxjs/operators';
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar, MatDialog } from '@angular/material';
import { AccountService } from "../../services/account.service";
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { of } from 'rxjs';
import { Companie } from 'src/app/models/companie.model';
import { DetaliuCircuit } from 'src/app/models/DetaliuCircuit.model';
import { Compensare } from 'src/app/models/compensare.model';
import { CompensareDetaliu } from 'src/app/models/CompensareDetaliu.model';
import * as FileSaver from 'file-saver'; 
import { Circuit } from 'src/app/models/circuit.model';

@Component({
    selector: 'circuiteclient',
  templateUrl: './circuiteclient.component.html',
  styleUrls: ['./circuiteclient.component.scss'],
  animations: [fadeInOut],
})

export class CircuiteClientComponent
{
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  detaliiCircuit: DetaliuCircuit[];
  circuitCompensareForm: FormGroup;
  definireCircuit: string = "N";
  numar_curent: string = "";

  displayedColumns = ['id', 'suma', 'hash', 'componenteAprobate', 'aprobatDeCompaniaCurenta', 'dataExpirare', 'compensareId', 'actions'];
  isDataLoaded: boolean = false;
  loadingIndicator: boolean = true;
  formResetToggle: boolean = true;
  _currentUserId: string;
  data: Circuit[];
  dataSource: MatTableDataSource<Circuit>;

  circuitDetaliat: Circuit;

  afuConfig = {
    multiple: false,
    formatsAllowed: ".pdf",
    maxSize: "1",
    uploadAPI: {
      url: "https://example-file-upload-api",
      headers: {
        "Content-Type": "text/plain;charset=UTF-8"
      }
    },
    theme: "attachPin",
    hideProgressBar: true,
    hideResetBtn: true,
    hideSelectBtn: true,
    replaceTexts: {
      attachPinBtn: 'Selecteaza fisier...'
    }
  };


  constructor(
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private accountService: AccountService
  ) {
    this.dataSource = new MatTableDataSource<Circuit>();
    this.circuitCompensareForm = this.formBuilder.group({

    });
  }

  ngOnInit() {
    this.afiseazaDate();
  }

  afiseazaDate() {
    this.accountService
      .getCircuiteList("particular")
      .subscribe(results => {

        let linii = results[0];

        let liniiReconverted = JSON.parse(JSON.stringify(linii));

        this.data = liniiReconverted;

        this.dataSource.data = this.data;

        this.isDataLoaded = true;

        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        setTimeout(() => { this.loadingIndicator = false; }, 1500);
      },
        error => {
        });
  }

  reset() {

  }

  adaugare() {
    this.definireCircuit = 'Y';

    let companie1 = new DetaliuCircuit();
    companie1.nrCrt = 1;

    let companie2 = new DetaliuCircuit();
    companie2.nrCrt = 2;

    this.detaliiCircuit = [companie1, companie2];
  }

  adaugaComponent() {
    let companieNoua = new DetaliuCircuit();
    companieNoua.nrCrt = this.detaliiCircuit.length + 1;
    this.detaliiCircuit.push(companieNoua);

    this.alertService.showMessage("Info", "S-a adaugat un component, va rugam selectati compania!", MessageSeverity.success);
  }

  stergeComponent() {
    if (this.detaliiCircuit.length == 2) {
      this.alertService.showMessage("Info", "Trebuie sa existe minim doua componente!", MessageSeverity.warn);
    }
    else {
      this.detaliiCircuit.pop();
      this.alertService.showMessage("Info", "S-a eliminat ultimul component adaugat!", MessageSeverity.success);
    }
  }

  saveCircuitCompensare() {
    let dateleSuntCompletate = true;
    for (let detaliu of this.detaliiCircuit) {
      if (detaliu.companieId == null) {
        dateleSuntCompletate = false;
        break;
      }
    }

    if (!dateleSuntCompletate) {
      this.alertService.showMessage("Info", "Trebuie sa selectati toate componentele!", MessageSeverity.warn);
      return;
    }

    this.accountService.definireCircuit(this.detaliiCircuit).subscribe(
      role => this.saveSuccessHelper(this.detaliiCircuit, `Circuitul a fost creata cu succes!`),
      error => this.saveFailedHelper(error));
  }

  private saveSuccessHelper(detalii?: DetaliuCircuit[], mesaj?: string) {
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage("Success", mesaj, MessageSeverity.success);
    this.definireCircuit = 'N';
    this.afiseazaDate();
  }

  private saveFailedHelper(error: any) {
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage("Save Error", "In procesul de salvare s-au produs urmatoarele erori: ", MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  cancelAdaugare() {
    this.definireCircuit = 'N';
    this.afiseazaDate();
  }

  inapoiInMeniuPrincipalDinMeniuDetaliiCompensare() {
    this.definireCircuit = 'N';
    this.afiseazaDate();
  }


  private saveSuccessHelperImplementareCompensare(compensare?: Compensare, mesaj?: string) {
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage("Success", mesaj, MessageSeverity.success);
    this.definireCircuit = 'N';
    this.afiseazaDate();
  }

  private saveFailedHelperImplementareCompensare(error: any, message: string) {
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage("Save Error", message, MessageSeverity.error, error);
  }

  public typeStringLineByLine(hash: string) {
    return hash.replace(",", "<br>");
  }

  private VizualizareDetalii(circuit: Circuit) {
    this.circuitDetaliat = circuit;
    this.definireCircuit = 'D';

    this.accountService
      .getDetaliiCircuit(this.circuitDetaliat.id, "particular")
      .subscribe(results => {

        let linii = results[0];

        let liniiReconverted = JSON.parse(JSON.stringify(linii));

        this.circuitDetaliat = liniiReconverted;
      },
        error => {
        });
  }

  AprobaCircuit(circuit: Circuit) {
    this.accountService.aprobaCircuit(circuit).subscribe(
      role => this.saveSuccessHelper(this.detaliiCircuit, `Circuitul a fost aprobat cu succes!`),
      error => this.saveFailedHelper(error));
  }

  ConfirmaAprobareCircuit(circuit: Circuit) {
    this.alertService.showDialog('Confirmare', 'Confirmati aprobarea?', DialogType.confirm, () => this.AprobaCircuit(circuit));
  }
}
