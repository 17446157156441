
import { Component, OnInit, OnDestroy, AfterViewInit, Input, TemplateRef, ViewChild } from '@angular/core';
import { fadeInOut } from '../../services/animations';
import { debounceTime, distinctUntilChanged, switchMap, catchError } from 'rxjs/operators';
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar, MatDialog } from '@angular/material';
import { AccountService } from "../../services/account.service";
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { of } from 'rxjs';
import { Companie } from 'src/app/models/companie.model';
import { DetaliuCircuit } from 'src/app/models/DetaliuCircuit.model';
import { Compensare } from 'src/app/models/compensare.model';
import { CompensareDetaliu } from 'src/app/models/CompensareDetaliu.model';
import * as FileSaver from 'file-saver'; 
import { FacturaServiciu } from 'src/app/models/FacturaServiciu.model';
import { DateTimeAdapter } from 'ng-pick-datetime';
import { Incasare, IncasareVM } from 'src/app/models/CompensareFacturare.model';

@Component({
  selector: 'incasaribackoffice',
  templateUrl: './incasaribackoffice.component.html',
  styleUrls: ['./incasaribackoffice.component.scss'],
    animations: [fadeInOut]
})
export class IncasariBackofficeComponent {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  
  firmaCautareIncasare: string = "";
  dataInceputIncasare: Date;
  dataFinalIncasare: Date;
  afisareModul: string = "L";
  afisareFacturiDeIncasat: string = "N";
  sumaIncasare: number;

  companieFactura: DetaliuCircuit;
  
  displayedColumns = ['selectie', 'id', 'tipIncasare', 'client', 'data', 'descriere', 'suma', 'descarcaIncasare'];
  displayedColumnsFacturi = ['selectie', 'client', 'serie', 'numar', 'dataFacturare', 'dataScadenta', 'cantitate', 'total', 'sold', 'stareFactura'];

  data: Incasare[];
  dataSource: MatTableDataSource<Incasare>;
  isDataLoaded: boolean = false;
  loadingIndicator: boolean = true;

  isDataLoadedFacturi: boolean = false;
  loadingIndicatorFacturi: boolean = true;
  dataFacturi: FacturaServiciu[];
  dataSourceFacturi: MatTableDataSource<FacturaServiciu>;

  dataIncasare: Date;
  tipIncasare: string = "C";
  //serieIncasare: string = "";
  //numarIncasare: string = "";
  descriereIncasare: string = "";
  numarOpIncasare: number;
  
  constructor(
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    dateTimeAdapter: DateTimeAdapter<any>
  ) {
    dateTimeAdapter.setLocale('ro-RO');
    this.dataSource = new MatTableDataSource<Incasare>();
    this.dataSourceFacturi = new MatTableDataSource<FacturaServiciu>();
  }

  ngOnInit() {
    this.companieFactura = new DetaliuCircuit();
    this.dataFinalIncasare = new Date();
    this.dataInceputIncasare = new Date();
    this.dataIncasare = new Date();
    this.dataInceputIncasare.setDate(this.dataInceputIncasare.getDate() - 10);
    this.afiseazaDate();
  }

  afiseazaDate() {
    let firma = this.firmaCautareIncasare == null || this.firmaCautareIncasare == "" ? "undefined" : this.firmaCautareIncasare;
    let dataInceput = this.dataInceputIncasare == null ? "undefined" : this.formatOnlyDate(this.dataInceputIncasare, ".");
    let dataFinal = this.dataFinalIncasare == null ? "undefined" : this.formatOnlyDate(this.dataFinalIncasare, ".");
    let stare = "undefined";
    
    this.accountService
      .getIncasari(firma, dataInceput, dataFinal, stare)
      .subscribe(results => {

        let linii = results[0];

        let liniiReconverted = JSON.parse(JSON.stringify(linii));

        this.data = liniiReconverted;

        this.dataSource.data = this.data;

        this.isDataLoaded = true;

        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        setTimeout(() => { this.loadingIndicator = false; }, 1500);
      },
        error => {
        });
  }

  formatOnlyDate(value: Date, separator: string): string {
    var date = new Date(value);
    var mm = date.getMonth() + 1; // getMonth() is zero-based
    var dd = date.getDate();

    return [
      (dd > 9 ? '' : '0') + dd,
      (mm > 9 ? '' : '0') + mm,
      date.getFullYear()
    ].join(separator);
  }

  afiseazaMeniuIncasareNoua() {
    this.companieFactura = new DetaliuCircuit();
    this.afisareModul = 'I';
    this.afisareFacturiDeIncasat = 'N';
  }

  inapoiInMeniulPrincipal() {
    this.afisareModul = 'L';
  }

  afiseazaDateFactura() {
    this.sumaSoldurilorSelectate = 0;

    let idFirma = this.companieFactura.companieId;
    if (typeof idFirma === "undefined") {
      this.alertService.showStickyMessage("Save Error", "Compania introdusa nu este valida!", MessageSeverity.error, null);
      return;
    }
    
    this.accountService
      .getFacturiClientList("undefined", idFirma, "undefined", "undefined", "NA")
      .subscribe(results => {

        this.afisareFacturiDeIncasat = 'Y';

        let linii = results[0];

        let liniiReconverted = JSON.parse(JSON.stringify(linii));

        this.dataFacturi = liniiReconverted;

        this.dataSourceFacturi.data = this.dataFacturi;

        this.isDataLoadedFacturi = true;

        this.dataSourceFacturi.paginator = this.paginator;
        this.dataSourceFacturi.sort = this.sort;

        setTimeout(() => { this.loadingIndicatorFacturi = false; }, 1500);
      },
        error => {
        });
  }

  reset() {
    this.companieFactura = new DetaliuCircuit();
    this.afisareFacturiDeIncasat = 'N';
  }

  public totalSoldFacturiDeIncasat(): number {
    let suma = 0;

    for (let factura of this.dataFacturi) {
      suma += factura.sold;
    }

    return suma;
  }

  genereazaIncasare() {
    this.alertService.showDialog('Confirmati', 'Confirmati realizarea incasarii?', DialogType.confirm, () => this.confirmaRealizareaIncasarii());
  }

  confirmaRealizareaIncasarii() {
    let incasare = new IncasareVM();
    incasare.selectata = "";
    incasare.id = "";
    incasare.serie = "";
    if (this.numarOpIncasare != null) {
      incasare.numar = this.numarOpIncasare.toString();
    }
    incasare.descriere = this.descriereIncasare;
    incasare.data = this.formatOnlyDate(this.dataIncasare, ".");
    incasare.suma = this.sumaIncasare.toString();
    incasare.client = this.companieFactura.companieId.toString();
    incasare.tipIncasare = this.tipIncasare;

    console.log(incasare);

    this.accountService.genereazaIncasare(incasare).subscribe(
      role => this.saveSuccessHelper(`Incasarea s-a inregistrat cu succes!`),
      error => this.saveFailedHelper(error));
  }

  private saveSuccessHelper(mesaj?: string) {
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage("Success", mesaj, MessageSeverity.success);
    this.afiseazaDateFactura();
  }

  private saveFailedHelper(error: any) {
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage("Save Error", "In procesul de salvare s-au produs urmatoarele erori: ", MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  sumaSoldurilorSelectate: number;
  private calculeazaFacturiSelectate() {
    this.sumaSoldurilorSelectate = 0;

    for (let factura of this.dataFacturi) {
      if (factura.selectata) {
        this.sumaSoldurilorSelectate += factura.sold;
      }
    }
  }

  sumaIncasarilorSelectate: number;
  private calculeazaIncasariSelectate() {
    this.sumaIncasarilorSelectate = 0;

    for (let incasare of this.data) {
      if (incasare.selectata) {
        this.sumaIncasarilorSelectate += incasare.suma;
      }
    }
  }

  VizualizareIncasare(incasare: Incasare) {
    this.accountService.downloadIncasare(incasare.id.toString()).subscribe(result => {
      let blob = new Blob(result, {
        type: 'application/pdf' // must match the Accept type
      });

      let filename = 'Incasare_' + incasare.id + '.pdf';
      FileSaver.saveAs(blob, filename);
    });
  }
}
