import { Companie } from "./companie.model";

export class ExtrasDeCont {
  public id: string;
  public clientId: string;
  public client: Companie;
  public denumire: string;
  public cif: string;
  public numar: string;
  public selectata: boolean;
  public dataExtras: Date;
  public dataTransmitereMail: Date;
  public dataNumar: Date;
  public rezultatTransmitereMail: string;
  public detaliiExtrasDeCont: ExtrasDeContDetaliu[];
}

export class ExtrasDeContDetaliu {
  public id: string;
  public extrasDeContId: string;
  public serie: string;
  public numar: number;
  public dataFacturare: Date;
  public dataScadenta: Date;
  public valoare: number;
  public sold: number;
  public numarCircuite: string;
}
