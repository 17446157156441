// =============================
// Email: info@ebenmonney.com
// www.ebenmonney.com/templates
// =============================

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from "./components/login/login.component";
import { HomeComponent } from "./components/home/home.component";
import { CustomersComponent } from "./components/customers/customers.component";
import { ProductsComponent } from "./components/products/products.component";
import { OrdersComponent } from "./components/orders/orders.component";
import { SettingsComponent } from "./settings/settings.component";
import { AboutComponent } from "./components/about/about.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { AuthService } from './services/auth.service';
import { AuthGuard } from './services/auth-guard.service';
import { ReprezentariComponent } from './components/reprezentari/reprezentari.component';
import { ReprezentantiComponent } from "./components/reprezentanti/reprezentanti.component";
import { ContulMeuComponent } from "./components/contulmeu/contulmeu.component";
import { CompanieComponent } from "./components/companie/companie.component";
import { CompanieReprezentariComponent } from "./components/companieReprezentari/companieReprezentari.component";
import { FacturiComponent } from "./components/facturi/facturi.component";
import { FacturiBackofficeComponent } from "./components/facturiBackoffice/facturiBackoffice.component";
import { CircuiteComponent } from "./components/circuite/circuite.component";
import { CircuiteClientComponent } from "./components/circuiteclient/circuiteclient.component";
import { CompensariPreferentialeComponent } from "./components/compensaripreferentiale/compensaripreferentiale.component";
import { CompensariClientComponent } from "./components/compensariclient/compensariclient.component";
import { FinanciarClientComponent } from "./components/financiarclient/financiarclient.component";
import { FinanciarBackofficeComponent } from "./components/financiarbackoffice/financiarbackoffice.component";
import { IncasariBackofficeComponent } from "./components/incasaribackoffice/incasaribackoffice.component";
import { ProformaComponent } from './components/proforma/proforma.component';
import { ProformaClientComponent } from './components/proformaClient/proformaClient.component';
import { CalculCircuiteComponent } from './components/calculcircuite/calculcircuite.component';
import { CompanieDefinireComponent } from "./components/companiedefinire/companiedefinire.component";
import { AsociatiComponent } from "./components/asociati/asociati.component";
import { CompensariNefacturateComponent } from "./components/compensarinefacturate/compensarinefacturate.component";
import { TarifeComponent } from "./components/tarife/tarife.component";
import { FinanciarRaportBackofficeComponent } from "./components/financiarRaportBackoffice/financiarRaportBackoffice.component"; //[Alex]
import { GhidUtilizare } from "./components/ghidUtilizare/ghidUtilizare.component"; //[Alex]
import { Imputernicire } from "./components/imputernicire/imputernicire.component"; //[Alex]
import { RaportListaCompaniiComponent } from './components/raportlistacompanii/raportlistacompanii.component';
import { InformareGdprComponent } from "./components/informareGdpr/informareGdpr.component"; //[Alex]
import { RaportCircuiteFinalizateComponent } from './components/raportCircuiteFinalizate/raportCircuiteFinalizate.component'; //[Alex]
import { RaportSituatiaCompensarilorFinalizateComponent } from './components/raportSituatiaCompensarilorFinalizate/raportSituatiaCompensarilorFinalizate.component'; //[Alex]
import { RaportSituatiiNrCompaniiComponent } from './components/raportSituatiiNrCompanii/raportSituatiiNrCompanii.component'; //[Alex]
import { ExtrasDeContComponent } from './components/extrasdecont/extrasdecont.component';
import { FinanciarRaportBackofficeNewComponent } from './components/financiarRaportBackofficeNew/financiarRaportBackofficeNew.component';


const routes: Routes = [
  { path: "", component: HomeComponent, canActivate: [AuthGuard], data: { title: "Home" } },
  { path: "login", component: LoginComponent, data: { title: "Login" } },
  { path: "customers", component: CustomersComponent, canActivate: [AuthGuard], data: { title: "Customers" } },
  { path: "products", component: ProductsComponent, canActivate: [AuthGuard], data: { title: "Products" } },
  { path: "orders", component: OrdersComponent, canActivate: [AuthGuard], data: { title: "Orders" } },
  { path: "settings", component: SettingsComponent, canActivate: [AuthGuard], data: { title: "Settings" } },
  { path: "about", component: AboutComponent, data: { title: "About Us" } },
  { path: "home", redirectTo: "/", pathMatch: "full" },
  { path: "reprezentari", component: ReprezentariComponent, canActivate: [AuthGuard], data: { title: "Reprezentari" } },
  { path: "contulmeu", component: ContulMeuComponent, canActivate: [AuthGuard], data: { title: "Contul meu" } },
  { path: "reprezentanti", component: ReprezentantiComponent, canActivate: [AuthGuard], data: { title: "Reprezentanti" } },
  { path: "companie", component: CompanieComponent, canActivate: [AuthGuard], data: { title: "Detalii companie" } },
  { path: "companiereprezentari", component: CompanieReprezentariComponent, canActivate: [AuthGuard], data: { title: "Detalii reprezentari" } },
  { path: "facturi", component: FacturiComponent, canActivate: [AuthGuard], data: { title: "Lista facturi" } },
  { path: "facturibackoffice", component: FacturiBackofficeComponent, canActivate: [AuthGuard], data: { title: "Lista facturi" } },
  { path: "circuite", component: CircuiteComponent, canActivate: [AuthGuard], data: { title: "Lista circuite" } },
  { path: "circuiteclient", component: CircuiteClientComponent, canActivate: [AuthGuard], data: { title: "Posibilitati compensare" } },
  { path: "compensaripreferentiale", component: CompensariPreferentialeComponent, canActivate: [AuthGuard], data: { title: "Compensari" } },
  { path: "compensariclient", component: CompensariClientComponent, canActivate: [AuthGuard], data: { title: "Compensari client" } },
  { path: "financiarclient", component: FinanciarClientComponent, canActivate: [AuthGuard], data: { title: "Financiar client" } },
  { path: "financiarbackoffice", component: FinanciarBackofficeComponent, canActivate: [AuthGuard], data: { title: "Financiar backoffice" } },
  { path: "incasaribackoffice", component: IncasariBackofficeComponent, canActivate: [AuthGuard], data: { title: "Incasari backoffice" } },
  { path: "facturiproforme", component: ProformaComponent, canActivate: [AuthGuard], data: { title: "Facturi proforme" } },
  { path: "facturiproformeclient", component: ProformaClientComponent, canActivate: [AuthGuard], data: { title: "Facturi proforme" } },
  { path: "calculcircuite", component: CalculCircuiteComponent, canActivate: [AuthGuard], data: { title: "Calcul circuite" } },
  { path: "companiedefinire", component: CompanieDefinireComponent, canActivate: [AuthGuard], data: { title: "Definire companie" } },
  { path: "asociati", component: AsociatiComponent, canActivate: [AuthGuard], data: { title: "Asociati" } },
  { path: "compensarinefacturate", component: CompensariNefacturateComponent, canActivate: [AuthGuard], data: { title: "Compensari nefacturate" } },
  { path: "tarife", component: TarifeComponent, canActivate: [AuthGuard], data: { title: "Tarife" } },
  { path: "financiarraportbackoffice", component: FinanciarRaportBackofficeComponent, canActivate: [AuthGuard], data: { title: "Financiar Raport backoffice" } }, //[Alex]
  { path: "ghidutilizare", component: GhidUtilizare, canActivate: [AuthGuard], data: { title: "Ghid Utilizare" } }, //[Alex]
  { path: "raportlistacompanii", component: RaportListaCompaniiComponent, canActivate: [AuthGuard], data: { title: "Raport lista companii" } },
  { path: "imputernicire", component: Imputernicire, canActivate: [AuthGuard], data: { title: "Formular Imputernicire" } }, //[Alex]
  { path: "informaregdpr", component: InformareGdprComponent, canActivate: [AuthGuard], data: { title: "Informare GDPR" } }, //[Alex]
  { path: "raportcircuitefinalizate", component: RaportCircuiteFinalizateComponent, canActivate: [AuthGuard], data: { title: "Raport circuite finalizate" } }, //[Alex]
  { path: "raportsituatiacompensarilorfinalizate", component: RaportSituatiaCompensarilorFinalizateComponent, canActivate: [AuthGuard], data: { title: "Raport circuite finalizate" } }, //[Alex]
  { path: "raportsituatiinrcompanii", component: RaportSituatiiNrCompaniiComponent, canActivate: [AuthGuard], data: { title: "Raport nr companii" } }, //[Alex]
  { path: "extrasdecont", component: ExtrasDeContComponent, canActivate: [AuthGuard], data: { title: "Extras de cont" } }, //[Alex]
  { path: "financiarraportbackofficenew", component: FinanciarRaportBackofficeNewComponent, canActivate: [AuthGuard], data: { title: "Financiar Raport backoffice nou" } },
  
    { path: "**", component: NotFoundComponent, data: { title: "Page Not Found" } },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthService, AuthGuard]
})
export class AppRoutingModule { }
