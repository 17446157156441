// =============================
// Email: info@ebenmonney.com
// www.ebenmonney.com/templates
// =============================

import { Component, ViewChild, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { User } from '../models/user.model';
import { Role } from '../models/role.model';

import { UserEditorComponent } from './user-editor.component';

@Component({
    selector: 'app-edit-user-dialog',
    templateUrl: 'edit-user-dialog.component.html',
    styleUrls: ['edit-user-dialog.component.scss']
})
export class EditUserDialogComponent
{
    @ViewChild(UserEditorComponent)
    editUser: UserEditorComponent;

    get userName() : any
    {
        return this.data.user ? { name: this.data.user.userName } : null;
    }

    constructor(
        public dialogRef: MatDialogRef<EditUserDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { user: User, roles: Role[] })
    {
    }

    ngAfterViewInit()
    {
        this.editUser.userSaved$.subscribe(user => this.dialogRef.close(user))
    }

    cancel(): void
    {
        this.dialogRef.close(null);
    }
}
