// =============================
// Email: info@ebenmonney.com
// www.ebenmonney.com/templates
// =============================

import { Component } from '@angular/core';
import { fadeInOut } from '../../services/animations';

@Component({
    selector: 'tarife',
    templateUrl: './tarife.component.html',
    styleUrls: ['./tarife.component.scss'],
    animations: [fadeInOut]
})
export class TarifeComponent { }
