
import { Component, OnInit, OnDestroy, AfterViewInit, Input, TemplateRef, ViewChild } from '@angular/core';
import { fadeInOut } from '../../services/animations';
import { debounceTime, distinctUntilChanged, switchMap, catchError } from 'rxjs/operators';
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar, MatDialog } from '@angular/material';
import { AccountService } from "../../services/account.service";
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { of } from 'rxjs';
import { Companie } from 'src/app/models/companie.model';
import { DetaliuCircuit } from 'src/app/models/DetaliuCircuit.model';
import { Compensare } from 'src/app/models/compensare.model';
import { CompensareDetaliu } from 'src/app/models/CompensareDetaliu.model';
import * as FileSaver from 'file-saver'; 
import { CompensareFacturare, GenerareFactura } from 'src/app/models/CompensareFacturare.model';
import { Factura } from 'src/app/models/factura.model';
import { FacturaServiciu } from 'src/app/models/FacturaServiciu.model';
import { DateTimeAdapter } from 'ng-pick-datetime';

@Component({
  selector: 'financiarRaportBackofficeNew',
  templateUrl: './financiarRaportBackofficeNew.component.html',
  styleUrls: ['./financiarRaportBackofficeNew.component.scss'],
  animations: [fadeInOut]
})
export class FinanciarRaportBackofficeNewComponent {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  companieFactura: DetaliuCircuit;
  firmaCautare: string = "";
  cautareStareFactura: string = "";
  afiseazaCircuitePentruGenerareFacturiServiciu: string = "N";
  dataInceput: Date;
  dataFinal: Date;
  dataFactura: Date;
  generareFactura: string = "N";
  displayedColumnsFacturi = ['serie', 'numar', 'dataFacturare', 'client', 'cif', 'cantitate', 'pretUnitar', 'total', 'cotaTva', 'valoareTva', 'valoareTvaInclus', 'denumireArt', 'numeIntocmit', 'localitate', 'judet','adresa'];
  displayedColumns = ['selectie', 'id', 'companie', 'hash', 'suma', 'dataFinalizare', 'actions'];

  isDataLoaded: boolean = false;
  loadingIndicator: boolean = true;
  formResetToggle: boolean = true;
  data: CompensareFacturare[];
  dataSource: MatTableDataSource<CompensareFacturare>;

  isDataLoadedFacturi: boolean = false;
  loadingIndicatorFacturi: boolean = true;
  dataFacturi: FacturaServiciu[];
  dataSourceFacturi: MatTableDataSource<FacturaServiciu>;

  constructor(
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    dateTimeAdapter: DateTimeAdapter<any>
  ) {
    dateTimeAdapter.setLocale('ro-RO');
    this.dataSource = new MatTableDataSource<CompensareFacturare>();
    this.dataSourceFacturi = new MatTableDataSource<FacturaServiciu>();
  }

  ngOnInit() {
    this.dataFactura = new Date();
    this.companieFactura = new DetaliuCircuit();

    this.dataFinal = new Date();
    this.dataInceput = new Date();
    this.dataInceput.setDate(this.dataInceput.getDate() - 10);

    this.afiseazaDateFactura();
  }

  afiseazaMeniuGenerareFactura() {
    this.dataFactura = new Date();
    this.companieFactura = new DetaliuCircuit();

    this.generareFactura = 'Y';
    this.afiseazaDate();
  }
  
  afiseazaDateFactura() {

    let firma = this.firmaCautare == null || this.firmaCautare == "" ? "undefined" : this.firmaCautare;
    let dataInceput = this.dataInceput == null ? "undefined" : this.formatOnlyDate(this.dataInceput, ".");
    let dataFinal = this.dataFinal == null ? "undefined" : this.formatOnlyDate(this.dataFinal, ".");
    let stare = this.cautareStareFactura == null || this.cautareStareFactura == "" ? "undefined" : this.cautareStareFactura;

    this.accountService
      .getFacturiClientList(firma, "0", dataInceput, dataFinal, stare)
      .subscribe(results => {

        let linii = results[0];

        let liniiReconverted = JSON.parse(JSON.stringify(linii));

        this.dataFacturi = liniiReconverted;

        this.dataSourceFacturi.data = this.dataFacturi;
        this.isDataLoadedFacturi = true;

        this.dataSourceFacturi.paginator = this.paginator;
        this.dataSourceFacturi.sort = this.sort;

        setTimeout(() => { this.loadingIndicatorFacturi = false; }, 1500);
      },
        error => {
        });
  }

  formatOnlyDate(value: Date, separator: string): string {
    var date = new Date(value);
    var mm = date.getMonth() + 1; // getMonth() is zero-based
    var dd = date.getDate();

    return [
      (dd > 9 ? '' : '0') + dd,
      (mm > 9 ? '' : '0') + mm,
      date.getFullYear()
    ].join(separator);
  }

  afiseazaDate() {

    this.afiseazaCircuitePentruGenerareFacturiServiciu = "N";

    if (typeof this.companieFactura.companieId === "undefined") {
      this.alertService.showStickyMessage("Save Error", "Trebuie sa selectati compania pentru care se va genera factura!", MessageSeverity.error, null);
      return;
    }

    if (this.companieFactura.companieId == "") {
      this.alertService.showStickyMessage("Save Error", "Trebuie sa selectati compania pentru care se va genera factura!", MessageSeverity.error, null);
      return;
    }

    let companieFactura = this.companieFactura == null ? "undefined" : this.companieFactura.companieId;

    this.accountService
      .getCompensariListPtFacturare(companieFactura)
      .subscribe(results => {

        let linii = results[0];

        let liniiReconverted = JSON.parse(JSON.stringify(linii));

        this.data = liniiReconverted;

        this.dataSource.data = this.data;

        this.isDataLoaded = true;

        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        setTimeout(() => { this.loadingIndicator = false; }, 1500);

        this.afiseazaCircuitePentruGenerareFacturiServiciu = "Y";
      },
        error => {
          this.afiseazaCircuitePentruGenerareFacturiServiciu = "N";
        });
  }

  reset() {
    this.afiseazaCircuitePentruGenerareFacturiServiciu = "N";
    this.companieFactura = new DetaliuCircuit();
  }

  solicitaGenerareFactura() {
    if (typeof this.companieFactura.companieId === "undefined") {
      this.alertService.showStickyMessage("Save Error", "Trebuie sa selectati compania pentru care se va genera factura!", MessageSeverity.error, null);
      return;
    }

    if (this.companieFactura.companieId == "") {
      this.alertService.showStickyMessage("Save Error", "Trebuie sa selectati compania pentru care se va genera factura!", MessageSeverity.error, null);
      return;
    }

    let compensariSelectate: CompensareFacturare[] = [];

    for (let compensare of this.data) {
      if (compensare.selectata) {
        compensariSelectate.push(compensare);
      }
    }

    if (compensariSelectate.length == 0) {
      this.alertService.showStickyMessage("Save Error", "Trebuie sa selectati cel putin un ciruit de compensare!", MessageSeverity.error, null);
      return;
    }

    this.alertService.showDialog('Confirmati', 'Confirmati generarea facturii?', DialogType.confirm, () => this.genereazaFactura());
  }

  solicitaGenerareFacturaSiChitanta() {
    if (typeof this.companieFactura.companieId === "undefined") {
      this.alertService.showStickyMessage("Save Error", "Trebuie sa selectati compania pentru care se va genera factura!", MessageSeverity.error, null);
      return;
    }

    if (this.companieFactura.companieId == "") {
      this.alertService.showStickyMessage("Save Error", "Trebuie sa selectati compania pentru care se va genera factura!", MessageSeverity.error, null);
      return;
    }

    let compensariSelectate: CompensareFacturare[] = [];

    for (let compensare of this.data) {
      if (compensare.selectata) {
        compensariSelectate.push(compensare);
      }
    }

    if (compensariSelectate.length == 0) {
      this.alertService.showStickyMessage("Save Error", "Trebuie sa selectati cel putin un ciruit de compensare!", MessageSeverity.error, null);
      return;
    }

    this.alertService.showDialog('Confirmati', 'Confirmati generarea facturii si a chitantei?', DialogType.confirm, () => this.genereazaFacturaSiChitanta());
  }

  genereazaFactura() {
    let compensariSelectate: CompensareFacturare[] = [];

    for (let compensare of this.data) {
      if (compensare.selectata) {
        compensariSelectate.push(compensare);
      }
    }

    let generareFactura = new GenerareFactura();
    generareFactura.companieId = this.companieFactura.companieId;
    generareFactura.dataFactura = this.dataFactura == null ? "undefined" : this.formatOnlyDate(this.dataFactura, ".");
    generareFactura.compensari = compensariSelectate;

    this.accountService.genereazaFacturaServiciu(generareFactura).subscribe(
      role => this.saveSuccessHelper(`Generarea facturii s-a realizat cu succes!`),
      error => this.saveFailedHelper(error));
  }

  genereazaFacturaSiChitanta() {
    let compensariSelectate: CompensareFacturare[] = [];

    for (let compensare of this.data) {
      if (compensare.selectata) {
        compensariSelectate.push(compensare);
      }
    }

    let generareFactura = new GenerareFactura();
    generareFactura.companieId = this.companieFactura.companieId;
    generareFactura.dataFactura = this.dataFactura == null ? "undefined" : this.formatOnlyDate(this.dataFactura, ".");
    generareFactura.compensari = compensariSelectate;

    this.accountService.genereazaFacturaSiChitantaServiciu(generareFactura).subscribe(
      role => this.saveSuccessHelper(`Generarea facturii si a chitantei s-a realizat cu succes!`),
      error => this.saveFailedHelper(error));
  }

  private saveSuccessHelper(mesaj?: string) {
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage("Success", mesaj, MessageSeverity.success);
    this.afiseazaDate();
  }

  private saveFailedHelper(error: any) {
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage("Save Error", "In procesul de salvare s-au produs urmatoarele erori: ", MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  VizualizareFactura(factura: FacturaServiciu) {
    this.accountService.downloadFactura(factura.id.toString()).subscribe(result => {
      let blob = new Blob(result, {
        type: 'application/pdf' // must match the Accept type
      });

      let filename = 'Factura_' + factura.numar + '.pdf';
      FileSaver.saveAs(blob, filename);
    });
  }

  CereAnulareFactura(factura: FacturaServiciu) {
    this.alertService.showDialog('Confirmati', 'Confirmati anularea facturii?', DialogType.confirm, () => this.AnuleazaFactura(factura));
  }

  AnuleazaFactura(factura: FacturaServiciu) {
    this.accountService.anuleazaFacturaServiciu(factura.id).subscribe(
      role => this.saveSuccessHelperAnulare(`Factura s-a anulat cu succes!`),
      error => this.saveFailedHelper(error));
  }

  private saveSuccessHelperAnulare(mesaj?: string) {
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage("Success", mesaj, MessageSeverity.success);
    this.afiseazaDateFactura();
  }  

  inapoiInMeniulPrincipal() {
    this.generareFactura = 'N';
    this.afiseazaDate();
  }
}
