// =============================
// Email: info@ebenmonney.com
// www.ebenmonney.com/templates
// =============================

import { Component } from '@angular/core';
import { fadeInOut } from '../../services/animations';
import { ConfigurationService } from '../../services/configuration.service';
import { AccountService } from "../../services/account.service";
import * as FileSaver from 'file-saver';

@Component({
    selector: 'home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    animations: [fadeInOut]
})
export class HomeComponent
{
  constructor(
    public configurations: ConfigurationService,
    private accountService: AccountService
  )
    {
     
  }

  descarcaFormular() {
    this.accountService.descarcaFormular().subscribe(result => {
      let blob = new Blob(result, {
        type: 'application/doc' // must match the Accept type
      });

      let filename = 'FormularInscriere.doc';
      FileSaver.saveAs(blob, filename);
    });
  }
}
