
import { Component, OnInit, OnDestroy, AfterViewInit, Input, TemplateRef, ViewChild } from '@angular/core';
import { fadeInOut } from '../../services/animations';
import { debounceTime, distinctUntilChanged, switchMap, catchError } from 'rxjs/operators';
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar, MatDialog } from '@angular/material';
import { AccountService } from "../../services/account.service";
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { of } from 'rxjs';
import { Companie } from 'src/app/models/companie.model';
import { DetaliuCircuit } from 'src/app/models/DetaliuCircuit.model';
import { Compensare } from 'src/app/models/compensare.model';
import { CompensareDetaliu } from 'src/app/models/CompensareDetaliu.model';
import * as FileSaver from 'file-saver'; 
import { DateTimeAdapter } from 'ng-pick-datetime';

@Component({
    selector: 'compensaripreferentiale',
    templateUrl: './compensaripreferentiale.component.html',
    styleUrls: ['./compensaripreferentiale.component.scss'],
    animations: [fadeInOut]
})
export class CompensariPreferentialeComponent {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  detaliiCircuit: DetaliuCircuit[];
  circuitCompensareForm: FormGroup;
  definireCompensare: string = "N";

  displayedColumns = ['id', 'suma', 'hash', 'stare', 'tipCompensare', 'dataCreare', 'dataFinalizare', 'actions'];
  isDataLoaded: boolean = false;
  loadingIndicator: boolean = true;
  formResetToggle: boolean = true;
  _currentUserId: string;
  data: Compensare[];
  dataSource: MatTableDataSource<Compensare>;

  numar_compensare: string = '';
  data_inceput: Date = new Date();
  data_final: Date = new Date();
  valoare_minima: string = '';
  valoare_maxima: string = '';
  cui_denumire: string = '';
  tip: string = '';
  stare: string = '';

  compensareDetaliata: Compensare;

  afuConfig = {
    multiple: false,
    formatsAllowed: ".pdf",
    maxSize: "1",
    uploadAPI: {
      url: "https://example-file-upload-api",
      headers: {
        "Content-Type": "text/plain;charset=UTF-8"
      }
    },
    theme: "attachPin",
    hideProgressBar: true,
    hideResetBtn: true,
    hideSelectBtn: true,
    replaceTexts: {
      attachPinBtn: 'Selecteaza fisier...'
    }
  };


  constructor(
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    dateTimeAdapter: DateTimeAdapter<any>
  ) {
      dateTimeAdapter.setLocale('ro-RO');
      this.dataSource = new MatTableDataSource<Compensare>();
      this.circuitCompensareForm = this.formBuilder.group({});
  }

  ngOnInit() {
    this.afiseazaDate();
  }

  afiseazaDate() {

    let numar_compensare = this.numar_compensare == null || this.numar_compensare == "" ? "undefined" : this.numar_compensare;

    this.formatOnlyDate(new Date(), ".")

    let data_inceput = "undefined";

    if (this.data_inceput != null) {
      data_inceput = this.formatOnlyDate(this.data_inceput, ".");
    }

    let data_final = "undefined";

    if (this.data_final != null) {
      data_final = this.formatOnlyDate(this.data_final, ".");
    }

    let valoare_minima = this.valoare_minima == null || this.valoare_minima == "" ? "undefined" : this.valoare_minima;
    let valoare_maxima = this.valoare_maxima == null || this.valoare_maxima == "" ? "undefined" : this.valoare_maxima;
    let cui_denumire = this.cui_denumire == null || this.cui_denumire == "" ? "undefined" : this.cui_denumire;
    let tip = this.tip == null || this.tip == "" ? "undefined" : this.tip;
    let stare = this.stare == null || this.stare == "" ? "undefined" : this.stare;

    this.accountService
      .getCompensariList("all", numar_compensare, data_inceput, data_final, valoare_minima, valoare_maxima, cui_denumire, tip, stare)
      .subscribe(results => {

        let linii = results[0];

        let liniiReconverted = JSON.parse(JSON.stringify(linii));

        this.data = liniiReconverted;

        this.dataSource.data = this.data;

        this.isDataLoaded = true;

        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        setTimeout(() => { this.loadingIndicator = false; }, 1500);
      },
        error => {
        });
  }

  reset() {

  }

  adaugare() {
    this.definireCompensare = 'Y';

    let companie1 = new DetaliuCircuit();
    companie1.nrCrt = 1;

    let companie2 = new DetaliuCircuit();
    companie2.nrCrt = 2;

    this.detaliiCircuit = [companie1, companie2];
  }

  adaugaComponent() {
    let companieNoua = new DetaliuCircuit();
    companieNoua.nrCrt = this.detaliiCircuit.length + 1;
    this.detaliiCircuit.push(companieNoua);

    this.alertService.showMessage("Info", "S-a adaugat un component, va rugam selectati compania!", MessageSeverity.success);
  }

  stergeComponent() {
    if (this.detaliiCircuit.length == 2) {
      this.alertService.showMessage("Info", "Trebuie sa existe minim doua componente!", MessageSeverity.warn);
    }
    else {
      this.detaliiCircuit.pop();
      this.alertService.showMessage("Info", "S-a eliminat ultimul component adaugat!", MessageSeverity.success);
    }
  }
  
  saveCircuitCompensare() {
    let dateleSuntCompletate = true;
    for (let detaliu of this.detaliiCircuit) {
      if (detaliu.companieId == null) {
        dateleSuntCompletate = false;
        break;
      }
    }

    if (!dateleSuntCompletate) {
      this.alertService.showMessage("Info", "Trebuie sa selectati toate componentele!", MessageSeverity.warn);
      return;
    }

    this.accountService.definireCircuitCompensare(this.detaliiCircuit).subscribe(
      role => this.saveSuccessHelperDefinireCompensare(role, `Compensarea a fost creata cu succes!`),
      error => this.saveFailedHelper(error));
  }

  private saveSuccessHelperDefinireCompensare(compensareId: string, mesaj?: string) {
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage("Success", mesaj, MessageSeverity.success);
    this.VizualizareDetaliiImplementareDupaId(compensareId);
  }

  private saveFailedHelper(error: any) {
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage("Save Error", "In procesul de salvare s-au produs urmatoarele erori: ", MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  cancelAdaugare() {
    this.definireCompensare = 'N';
    this.afiseazaDate();
  }

  VizualizareDetalii(compensare?: Compensare) {
    this.compensareDetaliata = compensare;
    this.definireCompensare = 'D';
    
    this.accountService
      .getDetaliiCompensare(this.compensareDetaliata.id, "all")
      .subscribe(results => {

        let linii = results[0];

        let liniiReconverted = JSON.parse(JSON.stringify(linii));

        this.compensareDetaliata = liniiReconverted;
      },
        error => {
        });
  }

  VizualizareDetaliiImplementareDupaId(compensareId: string) {
    this.accountService
      .getDetaliiCompensare(compensareId, "all")
      .subscribe(results => {

        let linii = results[0];

        let liniiReconverted = JSON.parse(JSON.stringify(linii));

        this.compensareDetaliata = liniiReconverted;

        this.definireCompensare = 'D';
      },
        error => {
        });
  }

  VizualizareDetaliiImplementare(compensare?: Compensare) {
    this.compensareDetaliata = compensare;
    this.definireCompensare = 'I';

    this.accountService
      .getDetaliiCompensare(this.compensareDetaliata.id, "all")
      .subscribe(results => {

        let linii = results[0];

        let liniiReconverted = JSON.parse(JSON.stringify(linii));

        this.compensareDetaliata = liniiReconverted;
      },
        error => {
        });
  }

  confirmatiModificareInGenerareCircuit(compensare?: Compensare) {
    this.alertService.showDialog('Confirmare', 'Confirmati modificarea starii in "Generare circuit"?', DialogType.confirm, () => this.modificareInGenerareCircuit(compensare));
  }

  modificareInGenerareCircuit(compensare?: Compensare) {
    this.accountService.modificareCompensareInGenerareCircuit(compensare).subscribe(
      role => this.saveSuccessHelper(compensare, `Starea compensarii a fost modificata in "Generare circuit"!`),
      error => this.saveFailedHelperImplementareCompensare(error, "La modificarea starii compensarii in Generare circuit s-au identificat urmatoarele erori: "));
  }

  inapoiInMeniuPrincipalDinMeniuDetaliiCompensare() {
    this.definireCompensare = 'N';
    this.afiseazaDate();
  }

  ImplementeazaCompensare(compensare: Compensare) {

    compensare.sumaPeRamura = this.totalSumaCompensata(compensare);

    this.accountService.implementareCompensare(compensare).subscribe(
      role => this.saveSuccessHelperImplementareCompensare(compensare, `Compensarea a fost propusa spre implementare!`),
      error => this.saveFailedHelperImplementareCompensare(error, "In procesul de propunere compensare s-au produs urmatoarele erori: "));
  }

  private saveSuccessHelperImplementareCompensare(compensare?: Compensare, mesaj?: string) {
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage("Success", mesaj, MessageSeverity.success);
    
    this.VizualizareDetaliiImplementare(compensare);
  }

  private saveSuccessHelper(compensare?: Compensare, mesaj?: string) {
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage("Success", mesaj, MessageSeverity.success);
    this.afiseazaDate();
  }

  private saveFailedHelperImplementareCompensare(error: any, message: string) {
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage("Save Error", message, MessageSeverity.error, error);
  }

  public cereAprobareImplementareCompensare(compensare: Compensare) {

    let suma = this.totalSumaCompensata(compensare);

    let nr = 1;

    for (let detaliu of compensare.detalii) {

      for (let compensareDetaliuFactura of detaliu.compensareDetaliuFacturi) {

        if (compensareDetaliuFactura.sumaCompensata < 0) {
          let mesaj = "In cadrul componentei " + nr + ", <br /> pentru factura " + compensareDetaliuFactura.nrCrt + " s-a introdus eronat o valoare negativa!";

          this.alertService.stopLoadingMessage();
          this.alertService.showStickyMessage("Eroare implementare compensare", mesaj, MessageSeverity.warn);

          return;
        }

        if (compensareDetaliuFactura.sumaCompensata > compensareDetaliuFactura.soldFactura) {
          let mesaj = "In cadrul componentei " + nr + ", <br /> pentru factura " + compensareDetaliuFactura.nrCrt + " valoarea sumei propuse pentru compensare depaseste valoarea soldului!";

          this.alertService.stopLoadingMessage();
          this.alertService.showStickyMessage("Eroare implementare compensare", mesaj, MessageSeverity.warn);

          return;
        }

      }

      nr += 1;
    }

    nr = 1;
    for (let detaliu of compensare.detalii) {
      let sumaCompensataDetaliu = this.totalSumaCompensataPeDetaliu(detaliu);

      if (sumaCompensataDetaliu.toFixed(2) != suma.toFixed(2)) {

        let mesaj = "Sume pentru componenta " + nr + ", <br />" + sumaCompensataDetaliu.toFixed(2) + " RON, este diferita de suma compensarii, " + suma.toFixed(2) + " RON !";

        this.alertService.stopLoadingMessage();
        this.alertService.showStickyMessage("Eroare implementare compensare", mesaj, MessageSeverity.warn);

        return;
      }

      nr += 1;
    }

    this.alertService.showDialog('Confirmare', 'Confirmati implementarea compensarii?', DialogType.confirm, () => this.ImplementeazaCompensare(compensare));
  }

  public totalSumaCompensataPeDetaliu(compensareDetaliu: CompensareDetaliu ): number {
    let suma = 0;

    for (let compensareDetaliuFactura of compensareDetaliu.compensareDetaliuFacturi) {
      suma += compensareDetaliuFactura.sumaCompensata;
    }
    
    return suma;
  }

  public totalSumaCompensata(compensare: Compensare): number {
    let suma = Number.MAX_VALUE;

    for (let detaliu of compensare.detalii) {
      let sumaCompensataDetaliu = this.totalSumaCompensataPeDetaliu(detaliu);

      if (sumaCompensataDetaliu < suma) {
        suma = sumaCompensataDetaliu;
      }
    }

    return suma;
  }

  public typeStringLineByLine(hash: string) {
    return hash.replace(",", "<br>");
  }

  descarcaOrdinPentruSemnat(detaliu: CompensareDetaliu) {
    this.accountService.downloadDoc(detaliu.id.toString()).subscribe(result => {
      let blob = new Blob(result, {
        type: 'application/pdf' // must match the Accept type
      });

      let filename = 'Ordin_de_compensare_' + detaliu.id + '.pdf';
      FileSaver.saveAs(blob, filename);
    });
  }

  descarcaBorderouPentruSemnat(detaliu: CompensareDetaliu) {
    this.accountService.downloadBorderouDoc(detaliu.id.toString()).subscribe(result => {
      let blob = new Blob(result, {
        type: 'application/pdf' // must match the Accept type
      });

      let filename = 'Borderou_de_compensare_' + detaliu.id + '.pdf';
      FileSaver.saveAs(blob, filename);
    });
  }

  public procesVerbalCompensare(compensare: Compensare) {
    this.accountService.downloadDocProcesVerbal(compensare.id.toString()).subscribe(result => {
      let blob = new Blob(result, {
        type: 'application/pdf' // must match the Accept type
      });

      let filename = 'Proces_verbal_compensare_' + compensare.id + '.pdf';
      FileSaver.saveAs(blob, filename);
    });
  }
  
  handleFileInput(files: FileList, detaliu: CompensareDetaliu) {
    detaliu.ordinSemnat = files.item(0);
  }

  handleFileInputBorderou(files: FileList, detaliu: CompensareDetaliu) {
    detaliu.borderouSemnat = files.item(0);
  }

  incarcaOrdinSemnat(detaliu: CompensareDetaliu) {
    if (detaliu.ordinSemnat == null) {
      this.alertService.stopLoadingMessage();
      this.alertService.showStickyMessage("Eroare incarcare fisier", "Fisierul nu este selectat!", MessageSeverity.error);
      return;
    }

    const formData = new FormData();
    formData.append('file', detaliu.ordinSemnat, detaliu.ordinSemnat.name);

    this.accountService.uploadOrdinFile(detaliu.id.toString(), formData).subscribe((result) => {
      this.alertService.stopLoadingMessage();
      this.alertService.showMessage("Incarcare ordin", "Fisier incarcat cu succes!", MessageSeverity.success);
      //detaliu.stare = "Ordin semnat si incarcat";

      this.VizualizareDetaliiImplementare(this.compensareDetaliata);
    },
      error => {
        this.alertService.stopLoadingMessage();
        this.alertService.showStickyMessage("Eroare incarcare ordin", "In procesul de incarcare s-au produs urmatoarele erori: ", MessageSeverity.error, error);
        this.alertService.showStickyMessage(error, null, MessageSeverity.error);
        console.log(error);
      });
  }

  incarcaBorderouSemnat(detaliu: CompensareDetaliu) {
    if (detaliu.borderouSemnat == null) {
      this.alertService.stopLoadingMessage();
      this.alertService.showStickyMessage("Eroare incarcare fisier", "Fisierul nu este selectat!", MessageSeverity.error);
      return;
    }

    const formData = new FormData();
    formData.append('file', detaliu.borderouSemnat, detaliu.borderouSemnat.name);

    this.accountService.uploadBorderouFile(detaliu.id.toString(), formData).subscribe((result) => {
      this.alertService.stopLoadingMessage();
      this.alertService.showMessage("Incarcare borderou", "Fisier incarcat cu succes!", MessageSeverity.success);
      //detaliu.stare = "Ordin semnat si incarcat";

      this.VizualizareDetaliiImplementare(this.compensareDetaliata);
    },
      error => {
        this.alertService.stopLoadingMessage();
        this.alertService.showStickyMessage("Eroare incarcare borderou", "In procesul de incarcare s-au produs urmatoarele erori: ", MessageSeverity.error, error);
        this.alertService.showStickyMessage(error, null, MessageSeverity.error);
        console.log(error);
      });
  }

  descarcaOrdinSemnat(detaliu: CompensareDetaliu) {
    this.accountService.downloadDocSemnat(detaliu.id.toString()).subscribe(result => {
      let blob = new Blob(result, {
        type: 'application/pdf' // must match the Accept type
      });

      let filename = 'Ordin_de_compensare_semnat_' + detaliu.id + '.pdf';
      FileSaver.saveAs(blob, filename);
    },
      error => {
        this.alertService.stopLoadingMessage();
        this.alertService.showStickyMessage("Eroare descarcare ordin semnat!", "Fisierul nu a fost regasit.", MessageSeverity.error, error);
        console.log(error);
      });
  }

  descarcaBorderouSemnat(detaliu: CompensareDetaliu) {
    this.accountService.downloadBorderouSemnat(detaliu.id.toString()).subscribe(result => {
      let blob = new Blob(result, {
        type: 'application/pdf' // must match the Accept type
      });

      let filename = 'Borderou_semnat_' + detaliu.id + '.pdf';
      FileSaver.saveAs(blob, filename);
    },
      error => {
        this.alertService.stopLoadingMessage();
        this.alertService.showStickyMessage("Eroare descarcare ordin semnat!", "Fisierul nu a fost regasit.", MessageSeverity.error, error);
        console.log(error);
      });
  }

  descarcaOrdinSemnatFinal(detaliu: CompensareDetaliu) {
    this.accountService.downloadDocSemnatFinal(detaliu.id.toString()).subscribe(result => {
      let blob = new Blob(result, {
        type: 'application/pdf' // must match the Accept type
      });

      let filename = 'Ordin_de_compensare_semnat_final_' + detaliu.id + '.pdf';
      FileSaver.saveAs(blob, filename);
    },
      error => {
        this.alertService.stopLoadingMessage();
        this.alertService.showStickyMessage("Eroare descarcare ordin semnat!", "Fisierul nu a fost regasit.", MessageSeverity.error, error);
        console.log(error);
      });
  }

  descarcaBorderouSemnatFinal(detaliu: CompensareDetaliu) {
    this.accountService.downloadDocBorderouSemnatFinal(detaliu.id.toString()).subscribe(result => {
      let blob = new Blob(result, {
        type: 'application/pdf' // must match the Accept type
      });

      let filename = 'Borderou_semnat_final_' + detaliu.id + '.pdf';
      FileSaver.saveAs(blob, filename);
    },
      error => {
        this.alertService.stopLoadingMessage();
        this.alertService.showStickyMessage("Eroare descarcare borderou semnat!", "Fisierul nu a fost regasit.", MessageSeverity.error, error);
        console.log(error);
      });
  }

  public cereAprobareCompensare(compensare: Compensare) {
    this.alertService.showDialog('Confirmare', 'Confirmati aprobarea finala a compensarii?', DialogType.confirm, () => this.aprobareFinalaCompensare(compensare));
  }

  public aprobareFinalaCompensare(compensare: Compensare) {
    this.accountService.aprobareFinalaCompensare(compensare).subscribe(
      role => {
        this.alertService.stopLoadingMessage();
        this.alertService.showMessage("Aprobare compensare", "Compensarea a primit acordul final!", MessageSeverity.success);
        
        this.VizualizareDetaliiImplementare(this.compensareDetaliata);
      },
      error => {
        this.alertService.stopLoadingMessage();
        this.alertService.showStickyMessage("Eroare aprobare compensare!", "Compensarea nu a putut primi acordul final.", MessageSeverity.error, error);
        console.log(error);
      });
  }
  
  public modificaBorderou(detaliu: CompensareDetaliu) {

    this.definireCompensare = 'ModificareBorderou';

    this.accountService
      .getDetaliiCompensare(this.compensareDetaliata.id, "modificareBorderou", detaliu.id.toString())
      .subscribe(results => {
        
        let linii = results[0];

        let liniiReconverted = JSON.parse(JSON.stringify(linii));

        this.compensareDetaliata = liniiReconverted;
      },
      error => {
        
        });
  }

  public inapoiInVizualizareCompensareDinModificareBorderou() {
    this.definireCompensare = 'I';

    this.accountService
      .getDetaliiCompensare(this.compensareDetaliata.id, "all")
      .subscribe(results => {

        let linii = results[0];

        let liniiReconverted = JSON.parse(JSON.stringify(linii));

        this.compensareDetaliata = liniiReconverted;
      },
        error => {
        });
  }

  public cereAprobareModificareBorderou(compensare: Compensare) {
    let suma = compensare.sumaPeRamura;

    for (let detaliu of compensare.detalii) {

      for (let compensareDetaliuFactura of detaliu.compensareDetaliuFacturi) {

        if (compensareDetaliuFactura.sumaCompensata < 0) {
          let mesaj = "Pentru factura " + compensareDetaliuFactura.nrCrt + " s-a introdus eronat o valoare negativa!";

          this.alertService.stopLoadingMessage();
          this.alertService.showStickyMessage("Eroare modificare borderou", mesaj, MessageSeverity.warn);

          return;
        }

        if (compensareDetaliuFactura.sumaCompensata > compensareDetaliuFactura.valoarePosibila) {
          let mesaj = "Pentru factura " + compensareDetaliuFactura.nrCrt + " valoarea sumei propuse pentru compensare depaseste valoarea posibila de compensat!";

          this.alertService.stopLoadingMessage();
          this.alertService.showStickyMessage("Eroare modificare borderou", mesaj, MessageSeverity.warn);

          return;
        }
      }
    }

    for (let detaliu of compensare.detalii) {
      let sumaCompensataDetaliu = this.totalSumaCompensataPeDetaliu(detaliu);

      if (sumaCompensataDetaliu.toFixed(2) != suma.toFixed(2)) {

        let mesaj = "Totalul sumei propuse pentru compensare in facturi, " + sumaCompensataDetaliu.toFixed(2) + " RON, este diferita de suma compensarii, " + suma.toFixed(2) + " RON !";

        this.alertService.stopLoadingMessage();
        this.alertService.showStickyMessage("Eroare modificare borderou", mesaj, MessageSeverity.warn);

        return;
      }
    }

    this.alertService.showDialog('Confirmare', 'Confirmati modificarea borderoului?', DialogType.confirm, () => this.salveazaModificareBorderou(compensare));
  }

  public salveazaModificareBorderou(compensare: Compensare) {
    this.accountService.salveazaModificareBorderou(compensare).subscribe(
      role => this.saveSuccessHelperModificareBorderou(compensare, `Borderoul a fost modificat!`),
      error => this.saveFailedHelperImplementareCompensare(error, "S-au intampinat urmatoarele erori la salvarea borderoului: "));    
  }

  private saveSuccessHelperModificareBorderou(compensare?: Compensare, mesaj?: string) {
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage("Success", mesaj, MessageSeverity.success);

    this.VizualizareDetaliiImplementare(compensare);
  }

  formatOnlyDate(value: Date, separator: string): string {
    var date = new Date(value);
    var mm = date.getMonth() + 1; // getMonth() is zero-based
    var dd = date.getDate();

    return [
      (dd > 9 ? '' : '0') + dd,
      (mm > 9 ? '' : '0') + mm,
      date.getFullYear()
    ].join(separator);
  }
}
