// =============================
// Email: info@ebenmonney.com
// www.ebenmonney.com/templates
// =============================

import { Component, OnInit, OnDestroy, AfterViewInit, Input, TemplateRef, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar, MatDialog } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';

import { AuthService } from '../../services/auth.service';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { AppTranslationService } from "../../services/app-translation.service";
import { LocalStoreManager } from '../../services/local-store-manager.service';
import { Utilities } from "../../services/utilities";
import { fadeInOut } from '../../services/animations';
import { AddTaskDialogComponent } from '../controls/add-task-dialog.component';
import { ContulMeu } from 'src/app/models/contulMeu.model';
import { AccountService } from 'src/app/services/account.service';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'reprezentanti',
  templateUrl: './reprezentanti.component.html',
  styleUrls: ['./reprezentanti.component.scss'],
  animations: [fadeInOut]
})

export class ReprezentantiComponent implements OnInit, AfterViewInit {
  displayedColumns = ['nume', 'companie', /*'localitate', 'adresa', */'stare', 'actions'];
  dataSource: MatTableDataSource<ContulMeu>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  contulMeu: ContulMeu = new ContulMeu();
  isDataLoaded: boolean = false;
  loadingIndicator: boolean = true;
  formResetToggle: boolean = true;
  _currentUserId: string;
  nume: string;
  companie: string;
  stare: string;
  data: ContulMeu[];

  vizualizare: string = "Generala";
  reprezentareForm: FormGroup;

  get currentUserId() {
    if (this.authService.currentUser) {
      this._currentUserId = this.authService.currentUser.id;
    }

    return this._currentUserId;
  }
  
  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private accountService: AccountService,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
  ) {
    this.dataSource = new MatTableDataSource<ContulMeu>();
  }

  ngOnInit() {
    this.vizualizare = 'Generala';
    this.loadingIndicator = true;

    this.fetch((data) => {
      this.dataSource.data = data;
      this.isDataLoaded = true;

      setTimeout(() => { this.loadingIndicator = false; }, 1500);
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  
  showErrorAlert(caption: string, message: string) {
    this.alertService.showMessage(caption, message, MessageSeverity.error);
  }

  reset() {
    this.nume = "";
    this.companie = "";
    this.stare = "";
  }

  fetch(cb) {
    this.afiseazaDate();
  }

  afiseazaDate() {

    this.loadingIndicator = true;

    let nume = this.nume == null || this.nume == "" ? "undefined" : this.nume;
    let companie = this.companie == null || this.companie == "" ? "undefined" : this.companie;
    let stare = this.stare == null || this.stare == "" ? "undefined" : this.stare;

    this.accountService
      .getContulMeuList(nume, companie, stare)
      .subscribe(results => {
        
        let linii = results[0];

        let liniiReconverted = JSON.parse(JSON.stringify(linii));

        this.data = liniiReconverted;

        this.dataSource.data = this.data;

        this.isDataLoaded = true;

        setTimeout(() => { this.loadingIndicator = false; }, 1500);

      },
        error => {
        });
  }

  cereAprobareReprezentant(contulMeu: ContulMeu) {
    this.alertService.showDialog('Confirmati', 'Confirmati aprobarea reprezentantului ' + contulMeu.nume + ' ' + contulMeu.prenume + ' ?', DialogType.confirm, () => this.aprobareReprezentant(contulMeu));
  }

  vizualizareDetaliiCont(contulMeu: ContulMeu) {
    this.contulMeu = contulMeu;
    this.vizualizare = 'Detalii';
    this.buildForm();
  }

  Cancel() {
    this.vizualizare = 'Generala';

    this.fetch((data) => {
      this.dataSource.data = data;
      this.isDataLoaded = true;

      setTimeout(() => { this.loadingIndicator = false; }, 1500);
    });

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  aprobareReprezentant(contulMeu: ContulMeu) {
    this.alertService.startLoadingMessage('Confirmare reprezentare...');
    this.accountService.aprobareContulMeu(contulMeu).subscribe(
        role => this.saveSuccessHelper(this.contulMeu),
        error => this.saveFailedHelper(error));
  }

  private saveSuccessHelper(contulMeu?: ContulMeu) {
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage("Success", `Contul a fost aprobat!`, MessageSeverity.success);
    this.afiseazaDate();
    this.Cancel();
  }

  private saveFailedHelper(error: any) {
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage("Eroare", "Eroare la aprobarea contului: ", MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  ///
  private buildForm() {
    this.reprezentareForm = this.formBuilder.group({
      tbNume: ['', Validators.required],
      tbPrenume: ['', Validators.required],
      tbCNP: ['', Validators.required],
      tbDocument: ['', Validators.required],
      tbLocalitate: ['', Validators.required],
      tbAdresa: ['', Validators.required],
      tbTelefon: ['', Validators.required],
      tbStare: new FormControl({ value: '' })
    });
  }

  get tbNume() {
    return this.reprezentareForm.get('tbNume');
  }

  get tbPrenume() {
    return this.reprezentareForm.get('tbPrenume');
  }

  get tbCNP() {
    return this.reprezentareForm.get('tbCNP');
  }

  get tbDocument() {
    return this.reprezentareForm.get('tbDocument');
  }

  get tbLocalitate() {
    return this.reprezentareForm.get('tbLocalitate');
  }

  get tbAdresa() {
    return this.reprezentareForm.get('tbAdresa');
  }

  get tbTelefon() {
    return this.reprezentareForm.get('tbTelefon');
  }

  get tbStare() {
    return this.reprezentareForm.get('tbStare');
  }

  descarcaFisierCI(fisier: string) {
    this.accountService.downloadDocCI(fisier).subscribe(result => {
      let blob = new Blob(result, {
        type: 'application/pdf' // must match the Accept type
      });

      let filename = 'CI_' + this.contulMeu.id + '.pdf';
      FileSaver.saveAs(blob, filename);
    });
  }
}

