// =============================
// Email: info@ebenmonney.com
// www.ebenmonney.com/templates
// =============================

import { Injectable, Output, EventEmitter } from '@angular/core';
import { Router, NavigationExtras, Data } from "@angular/router";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, forkJoin } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { AccountEndpoint } from './account-endpoint.service';
import { AuthService } from './auth.service';
import { User } from '../models/user.model';
import { Role } from '../models/role.model';
import { Permission, PermissionNames, PermissionValues } from '../models/permission.model';
import { UserEdit } from '../models/user-edit.model';
import { ContulMeu } from '../models/contulMeu.model';
import { Companie } from '../models/companie.model';
import { Factura, FacturaJurnal } from '../models/factura.model';

import { of } from 'rxjs';
import { DetaliuCircuit } from '../models/DetaliuCircuit.model';
import { Compensare } from '../models/Compensare.model';
import { environment } from 'src/environments/environment';
import { Utilities } from './utilities';
import { FacturaFisier, IncarcareFactura } from '../models/FacturaFisier.model';
import { Circuit } from '../models/circuit.model';
import { CompensareFacturare, Incasare, GenerareFactura, IncasareVM } from '../models/CompensareFacturare.model';
import { FacturaServiciu } from '../models/FacturaServiciu.model';
import { Asociat } from '../models/Asociat.model';
import { RandRaportListaCompanii } from '../models/RandRaportListaCompanii';
import { RandRaportCircuiteFinalizate } from '../models/RandRaportCircuiteFinalizate';
import { RandRaportSituatiaCompensarilorFinalizate } from '../models/RandRaportSituatiaCompensarilorFinalizate';
import { RandRaportSituatiiNrCompanii } from '../models/RandRaportSituatiiNrCompanii';
import { ExtrasDeCont } from '../models/ExtrasDeCont';



export type RolesChangedOperation = "add" | "delete" | "modify";
export type RolesChangedEventArg = { roles: Role[] | string[], operation: RolesChangedOperation };

@Injectable()
export class AccountService {
  public static readonly roleAddedOperation: RolesChangedOperation = "add";
  public static readonly roleDeletedOperation: RolesChangedOperation = "delete";
  public static readonly roleModifiedOperation: RolesChangedOperation = "modify";

  private _rolesChanged = new Subject<RolesChangedEventArg>();

  constructor(
    private router: Router,
    private http: HttpClient,
    private authService: AuthService,
    private accountEndpoint: AccountEndpoint) { }

  isOpen = false;
  @Output() change: EventEmitter<boolean> = new EventEmitter();

  toggle() {
    this.isOpen = !this.isOpen;
    this.change.emit(this.isOpen);
    console.log("YUNNS121212121");
  }

  getUser(userId?: string) {
    return this.accountEndpoint.getUserEndpoint<User>(userId);
  }

  getUserAndRoles(userId?: string) {

    return forkJoin(
      this.accountEndpoint.getUserEndpoint<User>(userId),
      this.accountEndpoint.getRolesEndpoint<Role[]>());
  }

  getUsers(page?: number, pageSize?: number) {

    return this.accountEndpoint.getUsersEndpoint<User[]>(page, pageSize);
  }

  getUsersAndRoles(page?: number, pageSize?: number) {

    return forkJoin(
      this.accountEndpoint.getUsersEndpoint<User[]>(page, pageSize),
      this.accountEndpoint.getRolesEndpoint<Role[]>());
  }

  updateUser(user: UserEdit) {
    if (user.id) {
      return this.accountEndpoint.getUpdateUserEndpoint(user, user.id);
    }
    else {
      return this.accountEndpoint.getUserByUserNameEndpoint<User>(user.userName).pipe<User>(
        mergeMap(foundUser => {
          user.id = foundUser.id;
          return this.accountEndpoint.getUpdateUserEndpoint(user, user.id)
        }));
    }
  }

  newUser(user: UserEdit) {
    return this.accountEndpoint.getNewUserEndpoint<User>(user);
  }

  newUserFromOutside(user: UserEdit) {
    return this.accountEndpoint.getNewUserFromOutsideEndpoint<User>(user);
  }

  sendMailForPasswordRecovery(user: UserEdit) {
    return this.accountEndpoint.sendMailForPasswordRecoveryEndpoint<User>(user);
  }

  getUserPreferences() {
    return this.accountEndpoint.getUserPreferencesEndpoint<string>();
  }

  updateUserPreferences(configuration: string) {
    return this.accountEndpoint.getUpdateUserPreferencesEndpoint(configuration);
  }

  deleteUser(userOrUserId: string | User): Observable<User> {
    if (typeof userOrUserId === 'string' || userOrUserId instanceof String) {
      return this.accountEndpoint.getDeleteUserEndpoint<User>(<string>userOrUserId).pipe<User>(
        tap(data => this.onRolesUserCountChanged(data.roles)));
    }
    else {
      if (userOrUserId.id) {
        return this.deleteUser(userOrUserId.id);
      }
      else {
        return this.accountEndpoint.getUserByUserNameEndpoint<User>(userOrUserId.userName).pipe<User>(
          mergeMap(user => this.deleteUser(user.id)));
      }
    }
  }

  unblockUser(userId: string) {
    return this.accountEndpoint.getUnblockUserEndpoint(userId);
  }

  userHasPermission(permissionValue: PermissionValues): boolean {
    return this.permissions.some(p => p == permissionValue);
  }

  refreshLoggedInUser() {
    return this.authService.refreshLogin();
  }

  getRoles(page?: number, pageSize?: number) {

    return this.accountEndpoint.getRolesEndpoint<Role[]>(page, pageSize);
  }

  getRolesAndPermissions(page?: number, pageSize?: number) {

    return forkJoin(
      this.accountEndpoint.getRolesEndpoint<Role[]>(page, pageSize),
      this.accountEndpoint.getPermissionsEndpoint<Permission[]>());
  }

  updateRole(role: Role) {
    if (role.id) {
      return this.accountEndpoint.getUpdateRoleEndpoint(role, role.id).pipe(
        tap(data => this.onRolesChanged([role], AccountService.roleModifiedOperation)));
    }
    else {
      return this.accountEndpoint.getRoleByRoleNameEndpoint<Role>(role.name).pipe(
        mergeMap(foundRole => {
          role.id = foundRole.id;
          return this.accountEndpoint.getUpdateRoleEndpoint(role, role.id)
        }),
        tap(data => this.onRolesChanged([role], AccountService.roleModifiedOperation)));
    }
  }

  newRole(role: Role) {
    return this.accountEndpoint.getNewRoleEndpoint<Role>(role).pipe<Role>(
      tap(data => this.onRolesChanged([role], AccountService.roleAddedOperation)));
  }

  deleteRole(roleOrRoleId: string | Role): Observable<Role> {

    if (typeof roleOrRoleId === 'string' || roleOrRoleId instanceof String) {
      return this.accountEndpoint.getDeleteRoleEndpoint<Role>(<string>roleOrRoleId).pipe<Role>(
        tap(data => this.onRolesChanged([data], AccountService.roleDeletedOperation)));
    }
    else {

      if (roleOrRoleId.id) {
        return this.deleteRole(roleOrRoleId.id);
      }
      else {
        return this.accountEndpoint.getRoleByRoleNameEndpoint<Role>(roleOrRoleId.name).pipe<Role>(
          mergeMap(role => this.deleteRole(role.id)));
      }
    }
  }

  getPermissions() {

    return this.accountEndpoint.getPermissionsEndpoint<Permission[]>();
  }

  private onRolesChanged(roles: Role[] | string[], op: RolesChangedOperation) {
    this._rolesChanged.next({ roles: roles, operation: op });
  }

  onRolesUserCountChanged(roles: Role[] | string[]) {
    return this.onRolesChanged(roles, AccountService.roleModifiedOperation);
  }

  getRolesChangedEvent(): Observable<RolesChangedEventArg> {
    return this._rolesChanged.asObservable();
  }

  get permissions(): PermissionValues[] {
    return this.authService.userPermissions;
  }

  get currentUser() {
    return this.authService.currentUser;
  }

  newContulMeu(contulMeu: ContulMeu) {
    return this.accountEndpoint.getNewContulMeuEndpoint<ContulMeu>(contulMeu);
  }

  getUserContulMeu() {
    return this.accountEndpoint.getUserContulMeuEndpoint<ContulMeu>();
  }

  getContulMeuList(nume?: string, companie?: string, stare?: string) {
    return forkJoin(
      this.accountEndpoint.getContulMeuListEndpoint<ContulMeu[]>(nume, companie, stare)
    );
  }

  aprobareContulMeu(contulMeu: ContulMeu) {
    return this.accountEndpoint.getAprobareContulMeuEndpoint<ContulMeu>(contulMeu);
  }

  newCompanie(companie: Companie) {
    return this.accountEndpoint.getNewCompanieEndpoint<Companie>(companie);
  }

  salveazaCompanie(companie: Companie) {
    return this.accountEndpoint.getSaveCompanieEndpoint<Companie>(companie);
  }

  getCompaniiReprezentateListForAutocomplete(denumire?: string) {
    return forkJoin(
      this.accountEndpoint.getCompaniiReprezentateAutocompleteListEndpoint<Companie[]>(denumire, denumire, "Y")
    );
  }

  getCompaniiReprezentateList(denumire?: string, judet?: string, reprezentant?: string, stare?: string, all?: string) {
    return forkJoin(
      this.accountEndpoint.getCompaniiReprezentateListEndpoint<Companie[]>(denumire, judet, reprezentant, stare, all)
    );
  }

  getCompaniiList(denumire?: string, judet?: string) {
    return forkJoin(
      this.accountEndpoint.getCompaniiListEndpoint<Companie[]>(denumire, judet)
    );
  }

  aprobareReprezentareCompanie(companie: Companie) {
    return this.accountEndpoint.aprobareReprezentareCompanieEndpoint<Companie>(companie);
  }

  anuleazaReprezentareCompanie(companie: Companie) {
    return this.accountEndpoint.anuleazaReprezentareCompanieEndpoint<Companie>(companie);
  }

  selectieCompanie(companie: Companie) {
    return this.accountEndpoint.selectieCompanieEndpoint<Companie>(companie);
  }

  getCompanieSelectata() {
    return this.accountEndpoint.getCompanieSelectataEndpoint<Companie>();
  }

  definireFactura(factura: Factura) {
    return this.accountEndpoint.getDefinireFacturaEndpoint<Factura>(factura);
  }

  definireExtras(idCompanie: string, data: string, numar: string, dataNumar: string) {
    return this.accountEndpoint.getDefinireExtrasEndpoint<ExtrasDeCont>(idCompanie, data, numar, dataNumar);
  }

  sendMailExtras(listaExtrase: ExtrasDeCont[]) {
    return this.accountEndpoint.sendMailExtrasEndpoint<ExtrasDeCont>(listaExtrase);
  }

  anuleazaFactura(factura: Factura) {
    return this.accountEndpoint.getDefinireFacturaEndpoint<Factura>(factura);
  }

  anuleazaFacturaServiciu(id: string) {
    return this.accountEndpoint.anuleazaFacturaServiciuEndpoint<string>(id);
  }

  getFacturiList(companieReprezentata?: string, partener?: string, furnizor?: string, client?: string, serie?: string, numar?: string,
    stare?: string, data_facturare_inceput?: string, data_facturare_final?: string, valoare_minima?: string, valoare_maxima?: string, sold_minim?: string, sold_maxim?: string) {
    return forkJoin(
      this.accountEndpoint.getFacturiListEndpoint<Factura[]>(companieReprezentata, partener, furnizor, client, serie, numar, stare, data_facturare_inceput, data_facturare_final, valoare_minima, valoare_maxima, sold_minim, sold_maxim)
    );
  }

  getJurnalFactura(idFactura?: string) {
    return forkJoin(
      this.accountEndpoint.getJurnalFacturaEndpoint<FacturaJurnal[]>(idFactura)
    );
  }

  getIncarcareFactura(data_inceput?: string, data_final?: string, utilizator_incarcare?: string, denumire_fisier_incarcat?: string) {
    return forkJoin(
      this.accountEndpoint.getIncarcareFacturaEndpoint<IncarcareFactura[]>(data_inceput, data_final, utilizator_incarcare, denumire_fisier_incarcat)
    );
  }
  
  getDetaliiIncarcareFactura(id: string) {
    return forkJoin(
      this.accountEndpoint.getDetaliiIncarcareFacturaEndpoint<any[]>(id)
    );
  }

  valideazaFacturi(facturi: Factura[]) {
    return this.accountEndpoint.valideazaFacturiEndpoint<Factura[]>(facturi);
  }

  stergeFacturi(facturi: Factura[]) {
    return this.accountEndpoint.stergeFacturiEndpoint<Factura[]>(facturi);
  }
  
  confirmUser(userId: string) {
    return this.accountEndpoint.confirmUserEndpoint<User>(userId);
  }

  getCompaniiListForAutocomplete(denumire?: string) {
    return forkJoin(
      this.accountEndpoint.getCompaniiListForAutocompleteEndpoint<Companie[]>(denumire, "undefined", "Y")
    );
  }

  definireCircuit(detaliiCircuit: DetaliuCircuit[]) {
    return this.accountEndpoint.definireCircuitEndpoint<DetaliuCircuit[]>(detaliiCircuit);
  }

  aprobaCircuit(circuit: Circuit) {
    return this.accountEndpoint.aprobaCircuitEndpoint<Circuit>(circuit);
  }

  definireCircuitCompensare(detaliiCircuit: DetaliuCircuit[]) {
    return this.accountEndpoint.definireCircuitCompensareEndpoint<string>(detaliiCircuit);
  }

  definireAsociat(detaliiCircuit: DetaliuCircuit[]) {
    return this.accountEndpoint.definireAsociatEndpoint<string>(detaliiCircuit);
  }

  stergeAsociat(asociat: Asociat) {
    return this.accountEndpoint.stergeAsociatEndpoint<string>(asociat);
  }


  getCompensariList(tipCompensare?: string,
    numar_compensare?: string,
    data_inceput?: string,
    data_final?: string,
    valoare_minima?: string,
    valoare_maxima?: string,
    cui_denumire?: string,
    tip?: string,
    stare?: string)
  {
    return forkJoin(
      this.accountEndpoint.getCompensariListEndpoint<Compensare[]>(tipCompensare, numar_compensare, data_inceput, data_final, valoare_minima, valoare_maxima, cui_denumire, tip, stare)
    );
  }

  getAsociatiList(
    cui_denumire?: string
  ) {
    return forkJoin(
      this.accountEndpoint.getAsociatiListEndpoint<Asociat[]>(cui_denumire)
    );
  }

  getCompensariCircuiteList() {
    return forkJoin(
      this.accountEndpoint.getCompensariCircuiteListEndpoint<Compensare[]>()
    );
  }

  getCompensariListPtFacturare(companieFactura: string) {
    return forkJoin(
      this.accountEndpoint.getCompensariListPtFacturareEndpoint<CompensareFacturare[]>(companieFactura)
    );
  }

  getFacturiClientList(firma: string, idFirma: string, dataInceput: string, dataFinal: string, stare: string) {
    return forkJoin(
      this.accountEndpoint.getFacturiClientListEndpoint<FacturaServiciu[]>(firma, idFirma, dataInceput, dataFinal, stare)
    );
  }

  getFacturiProformeList(firma: string, idFirma: string, dataInceput: string, dataFinal: string) {
    return forkJoin(
      this.accountEndpoint.getFacturiProformeListEndpoint<FacturaServiciu[]>(firma, idFirma, dataInceput, dataFinal)
    );
  }
  
  getIncasari(firma: string, dataInceput: string, dataFinal: string, stare: string) {
    return forkJoin(
      this.accountEndpoint.getIncasariEndpoint<Incasare[]>(firma, dataInceput, dataFinal, stare)
    );
  }

  getCircuiteList(tipCompensare?: string) {
    return forkJoin(
      this.accountEndpoint.getCircuiteListEndpoint<Circuit[]>(tipCompensare)
    );
  }

  getCircuiteListCuFiltrare(tipCompensare?: string, numar_curent?: string, companie?: string) {
    return forkJoin(
      this.accountEndpoint.getCircuiteListCuFiltrareEndpoint<Circuit[]>(tipCompensare, numar_curent, companie)
    );
  }

  getDetaliiCompensare(id: string, tipCompensare: string, idDetaliu?: string) {
    return forkJoin(
      this.accountEndpoint.getDetaliiCompensareEndpoint<Compensare>(id, tipCompensare, idDetaliu)
    );
  }

  getDetaliiCircuit(id: string, tipCompensare: string, idDetaliu?: string) {
    return forkJoin(
      this.accountEndpoint.getDetaliiCircuitEndpoint<Circuit>(id, tipCompensare, idDetaliu)
    );
  }

  implementareCompensare(compensare: Compensare) {
    return this.accountEndpoint.implementareCompensareEndpoint<Compensare>(compensare);
  }

  modificareCompensareInGenerareCircuit(compensare: Compensare) {
    return this.accountEndpoint.modificareCompensareInGenerareCircuit<Compensare>(compensare);
  }

  salveazaModificareBorderou(compensare: Compensare) {
    return this.accountEndpoint.salveazaModificareBorderouEndpoint<Compensare>(compensare);
  }

  aprobareFinalaCompensare(compensare: Compensare) {
    return this.accountEndpoint.aprobareFinalaCompensareEndpoint<Compensare>(compensare);
  }
  
  downloadDoc(document: string): Observable<any> {
    return forkJoin(
      this.accountEndpoint.downloadDocEndpoint<Compensare>(document)
    );
  }

  downloadBorderouDoc(document: string): Observable<any> {
    return forkJoin(
      this.accountEndpoint.downloadBorderouDocEndpoint<Compensare>(document)
    );
  }

  downloadDocSemnat(document: string): Observable<any> {
    return forkJoin(
      this.accountEndpoint.downloadDocSemnatEndpoint<Compensare>(document)
    );
  }

  downloadBorderouSemnat(document: string): Observable<any> {
    return forkJoin(
      this.accountEndpoint.downloadBorderouSemnatEndpoint<Compensare>(document)
    );
  }

  downloadDocSemnatFinal(document: string): Observable<any> {
    return forkJoin(
      this.accountEndpoint.downloadDocSemnatFinalEndpoint<Compensare>(document)
    );
  }

  downloadDocBorderouSemnatFinal(document: string): Observable<any> {
    return forkJoin(
      this.accountEndpoint.downloadDocBorderouSemnatFinalEndpoint<Compensare>(document)
    );
  }

  downloadDocProcesVerbal(document: string): Observable<any> {
    return forkJoin(
      this.accountEndpoint.downloadDocProcesVerbalEndpoint<Compensare>(document)
    );
  }

  uploadFile(tipIncarcare: string, file: any) {
    return forkJoin(
      this.accountEndpoint.uploadEndpoint<FacturaFisier>(tipIncarcare, file)
    );
  }

  uploadOrdinFile(idDetaliu: string, file: any) {
    return forkJoin(
      this.accountEndpoint.uploadOrdinEndpoint<any>(idDetaliu, file)
    );
  }

  uploadBorderouFile(idDetaliu: string, file: any) {
    return forkJoin(
      this.accountEndpoint.uploadBorderouFileEndpoint<any>(idDetaliu, file)
    );
  }

  uploadFisierDocumentCI(idDetaliu: string, file: any) {
    return forkJoin(
      this.accountEndpoint.uploadFisierDocumentCIEndpoint<any>(idDetaliu, file)
    );
  }

  downloadDocCI(document: string): Observable<any> {
    return forkJoin(
      this.accountEndpoint.downloadDocCIEndpoint<Compensare>(document)
    );
  }

  descarcaModelImportFacturi(): Observable<any> {
    return forkJoin(
      this.accountEndpoint.descarcaModelImportFacturiEndpoint<Compensare>()
    );
  }

  ///Alex
  descarcaImputernicire(): Observable<any> {
    return forkJoin(
      this.accountEndpoint.descarcaImputernicireEndpoint<Compensare>()
    );
  }

  descarcaFormular(): Observable<any> {
    return forkJoin(
      this.accountEndpoint.descarcaFormularEndpoint<Compensare>()
    );
  }
  //

  uploadFisierDocumentCompanie(idReprezentant: string, idCompanie: string, file: any, mod:string) {
    return forkJoin(
      this.accountEndpoint.uploadFisierDocumentCompanieEndpoint<Companie>(idReprezentant, idCompanie, file, mod)
    );
  }

  downloadDocumentCompanie(idReprezentant: string, idCompanie: string, mod: string): Observable<any> {
    return forkJoin(
      this.accountEndpoint.downloadDocumentCompanieEndpoint<Compensare>(idReprezentant, idCompanie, mod)
    );
  }

  genereazaFacturaServiciu(compensari: GenerareFactura) {
    return this.accountEndpoint.genereazaFacturaServiciuEndpoint<GenerareFactura>(compensari);
  }

  genereazaFacturaProforma(compensari: GenerareFactura) {
    return this.accountEndpoint.genereazaFacturaProformaEndpoint<GenerareFactura>(compensari);
  }

  genereazaFacturaSiChitantaServiciu(compensari: GenerareFactura) {
    return this.accountEndpoint.genereazaFacturaSiChitantaServiciuEndpoint<GenerareFactura>(compensari);
  }

  downloadFactura(document: string): Observable<any> {
    return forkJoin(
      this.accountEndpoint.downloadFacturaEndpoint<CompensareFacturare>(document)
    );
  }

  genereazaIncasare(incasare: IncasareVM) {
    return this.accountEndpoint.genereazaIncasareEndpoint<IncasareVM>(incasare);
  }

  downloadIncasare(document: string): Observable<any> {
    return forkJoin(
      this.accountEndpoint.downloadIncasareEndpoint<Incasare>(document)
    );
  }
  downloadExtras(document: string): Observable<any> {
    return forkJoin(
      this.accountEndpoint.downloadExtrasEndpoint<ExtrasDeCont>(document)
    );
  }

  downloadFacturaProforma(document: string): Observable<any> {
    return forkJoin(
      this.accountEndpoint.downloadFacturaProformaEndpoint<Incasare>(document)
    );
  }

  getRaportListaCompanii(dataInceput: string, dataFinal: string) {
    return forkJoin(
      this.accountEndpoint.getRaportListaCompaniiEndpoint<RandRaportListaCompanii[]>(dataInceput, dataFinal)
    );
  }

  //[Alex]
  getRaportCircuiteFinalizate(dataInceput: string, dataFinal: string) {
    return forkJoin(
      this.accountEndpoint.getRaportCircuiteFinalizateEndpoint<RandRaportCircuiteFinalizate[]>(dataInceput, dataFinal)
    );
  }

  //[Alex]
  getRaportSituatiaCompensarilorFinalizate(dataInceput: string, dataFinal: string) {
    return forkJoin(
      this.accountEndpoint.getRaportSituatiaCompensarilorFinalizateEndpoint<RandRaportSituatiaCompensarilorFinalizate[]>(dataInceput, dataFinal)
    );
  }

  //[Alex]
  getRaportSituatiiNrCompanii(dataInceput: string, dataFinal: string, judet?: string) {
    return forkJoin(
      this.accountEndpoint.getRaportSituatiiNrCompaniiEndpoint<RandRaportSituatiiNrCompanii[]>(dataInceput, dataFinal, judet)
    );
  }

  getExtrasDeContList(firma: string, idFirma: string, dataInceput: string, dataFinal: string) {
    return forkJoin(
      this.accountEndpoint.getExtrasDeContListEndpoint<ExtrasDeCont[]>(firma, idFirma, dataInceput, dataFinal )
    );
  }

  getFacturiInExtrasList(idCompanie: string, dataExtras: string) {
    return forkJoin(
      this.accountEndpoint.getFacturiInExtrasListEndpoint<FacturaServiciu[]>(idCompanie, dataExtras)
    );
  }

  getCompensariListPtFacturareProforma(companieFactura: string) {
    return forkJoin(
      this.accountEndpoint.getCompensariListPtFacturareProformaEndpoint<CompensareFacturare[]>(companieFactura)
    );
  }

}
