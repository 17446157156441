// =============================
// Email: info@ebenmonney.com
// www.ebenmonney.com/templates
// =============================

import { Component, ViewChild } from "@angular/core";
import { LoginControlComponent } from './login-control.component';
import { UserRegisterComponent } from '../../admin/user-register.component';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from "../../services/account.service";
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { User } from '../../models/user.model';
import { UserPasswordComponent } from "src/app/admin/user-password.component";

@Component({
    selector: "app-login",
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent
{
  ascundeLogin: boolean = false;
  ascundeInregistrare: boolean = true;
  ascundeRecuperare: boolean = true;

  @ViewChild(LoginControlComponent)
  loginControl: LoginControlComponent;

  @ViewChild(UserRegisterComponent)
  userRegisterComponent: UserRegisterComponent;

  @ViewChild(UserPasswordComponent)
  passwordComponent: UserPasswordComponent;

  confirm: string;
  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private accountService: AccountService,
    private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      this.confirm = params['confirm'];
    });
  }

  ngOnInit() {
    if (this.confirm != null) {
      this.accountService.confirmUser(this.confirm).subscribe(
        user => this.saveCompleted(user),
        error => this.saveFailed(error));
    };
  }

  private saveCompleted(user?: User) {
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage("Success", `Contul utilizatorului  ` + user.userName + ` a fost confirmat cu succes!`, MessageSeverity.success);
  }

  private saveFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage("Save Error", "One or more errors occured whilst saving your changes:", MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  showRegisterDialog() {
    this.ascundeLogin = true;
    this.ascundeInregistrare = false;
    this.ascundeRecuperare = true;
  }
  
  onCancelRegister(): void {
    this.ascundeLogin = false;
    this.ascundeInregistrare = true;
    this.ascundeRecuperare = true;
  }

  inregistrareUtilizator() {
    this.userRegisterComponent.save();
    this.ascundeLogin = false;
    this.ascundeInregistrare = true;
    this.ascundeRecuperare = true;
  }

  ngAfterViewInit() {
    this.userRegisterComponent.userSaved$.subscribe(user => {
      this.ascundeLogin = false;
      this.ascundeInregistrare = true;
      this.ascundeRecuperare = true;
    });
  }

  recuperareParola() {
    this.passwordComponent.save();
    this.ascundeLogin = false;
    this.ascundeInregistrare = true;
    this.ascundeRecuperare = true;
  }

  showDialogRecuperareParola() {
    this.ascundeLogin = true;
    this.ascundeInregistrare = true;
    this.ascundeRecuperare = false;
  }
}
