
import { Component, OnInit, OnDestroy, AfterViewInit, Input, TemplateRef, ViewChild } from '@angular/core';
import { fadeInOut } from '../../services/animations';
import { debounceTime, distinctUntilChanged, switchMap, catchError } from 'rxjs/operators';
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar, MatDialog } from '@angular/material';
import { AccountService } from "../../services/account.service";
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { of } from 'rxjs';
import { Companie } from 'src/app/models/companie.model';
import { DetaliuCircuit } from 'src/app/models/DetaliuCircuit.model';
import { Compensare } from 'src/app/models/compensare.model';
import { CompensareDetaliu } from 'src/app/models/CompensareDetaliu.model';
import * as FileSaver from 'file-saver'; 
import { CompensareFacturare, GenerareFactura } from 'src/app/models/CompensareFacturare.model';
import { Factura } from 'src/app/models/factura.model';
import { FacturaServiciu } from 'src/app/models/FacturaServiciu.model';
import { DateTimeAdapter } from 'ng-pick-datetime';
import { ExtrasDeCont } from '../../models/ExtrasDeCont';
import { error } from 'util';

@Component({
  selector: 'extrasdecont',
  templateUrl: './extrasdecont.component.html',
  styleUrls: ['./extrasdecont.component.scss'],
  animations: [fadeInOut]
})
export class ExtrasDeContComponent {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  companieExtras: DetaliuCircuit;
  firmaCautare: string = "";
  selectieExtras: string;
  afiseazaCircuitePentruGenerareFacturiServiciu: string = "N";
  dataInceput: Date;
  dataFinal: Date;
  dataFactura: Date;
  generareExtras: string = "N";
  displayedColumnsFacturi = ['selectie', 'client', 'numar', 'dataExtras', 'dataNumar', 'rezultatTransmitereMail','descarcaExtract'];
  displayedColumns = ['selectie', 'id', 'companie', 'hash', 'suma', 'dataFinalizare', 'actions'];
  extras: ExtrasDeCont;
  isDataLoaded: boolean = false;
  afisazaDateExtrasIntroducere: boolean = false;
  loadingIndicator: boolean = true;
  formResetToggle: boolean = true;
  data: FacturaServiciu[];
  facturi: FacturaServiciu[];
  extractForm: FormGroup;
  isDataLoadedFacturi: boolean = false;
  loadingIndicatorFacturi: boolean = true;
  dataExtras: ExtrasDeCont[];
  istoricExtrase: ExtrasDeCont[];
  dataSourceExtras: MatTableDataSource<ExtrasDeCont>;

  constructor(
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    dateTimeAdapter: DateTimeAdapter<any>
  ) {
    dateTimeAdapter.setLocale('ro-RO');
    this.dataSourceExtras = new MatTableDataSource<ExtrasDeCont>();
    //this.buildForm();
  }

  ngOnInit() {
    this.dataFactura = new Date();
    this.companieExtras = new DetaliuCircuit();
    this.extras = new ExtrasDeCont;
    this.dataFinal = new Date();
    this.dataInceput = new Date();
    this.dataInceput.setDate(this.dataInceput.getDate() - 1);
    this.afiseazaDateExtras();
    this.facturi = [];
  }

  afiseazaMeniuGenerareExtras() {
    this.extras = new ExtrasDeCont();
    this.extras.dataNumar = new Date();
    //this.extras.dataNumar = this.formatOnlyDate(this.extras.dataNumar, ".")
    this.companieExtras = new DetaliuCircuit();

    this.generareExtras = 'Y';
  }

  afiseazaDateExtras() {
    let firma = this.firmaCautare == null || this.firmaCautare == "" ? "undefined" : this.firmaCautare;
    let dataInceput = this.dataInceput == null ? "undefined" : this.formatOnlyDate(this.dataInceput, ".");
    let dataFinal = this.dataFinal == null ? "undefined" : this.formatOnlyDate(this.dataFinal, ".");
    this.accountService
      .getExtrasDeContList(firma, "0", dataInceput, dataFinal)
      .subscribe(results => {
        let linii = results[0];
        let liniiReconverted = JSON.parse(JSON.stringify(linii));
        this.dataExtras = liniiReconverted;
        this.dataSourceExtras.data = this.dataExtras;
        this.istoricExtrase = this.dataExtras;
        this.isDataLoadedFacturi = true;
        this.dataSourceExtras.paginator = this.paginator;
        this.dataSourceExtras.sort = this.sort;
        setTimeout(() => { this.loadingIndicatorFacturi = false; }, 1500);
      },
        error => {
          console.log(error);
        });
  }

  formatOnlyDate(value: Date, separator: string): string {
    var date = new Date(value);
    var mm = date.getMonth() + 1; // getMonth() is zero-based
    var dd = date.getDate();

    return [
      (dd > 9 ? '' : '0') + dd,
      (mm > 9 ? '' : '0') + mm,
      date.getFullYear()
    ].join(separator);
  }

  afiseazaDate() {
    this.afisazaDateExtrasIntroducere = true;
    this.extras.dataNumar = new Date();
    this.afiseazaCircuitePentruGenerareFacturiServiciu = "Y";
    if (typeof this.companieExtras.companieId === "undefined") {
      this.alertService.showStickyMessage("Save Error", "Trebuie sa selectati compania pentru care se va genera extrasul!", MessageSeverity.error, null);
      return;
    }

    if (this.companieExtras.companieId == "") {
      this.alertService.showStickyMessage("Save Error", "Trebuie sa selectati compania pentru care se va genera extrasul!", MessageSeverity.error, null);
      return;
    }

    let firma = this.companieExtras.companieId == null || this.companieExtras.companieId == "" ? "undefined" : this.companieExtras.companieId;
    let dataExtras = this.extras.dataExtras == null ? "undefined" : this.formatOnlyDate(this.extras.dataExtras, ".");
    this.accountService
      .getFacturiInExtrasList(firma, dataExtras)
      .subscribe(results => {
        let linii = results[0];
        let liniiReconverted = JSON.parse(JSON.stringify(linii));
        this.facturi = liniiReconverted;
        console.log(this.facturi);
      },
        error => {
          console.log(error);
        });
  }




  private saveSuccessHelper(mesaj?: string) {
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage("Success", mesaj, MessageSeverity.success);
    //this.afiseazaDate();
  }

  private saveFailedHelper(error: any) {
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage("Save Error", "In procesul de salvare s-au produs urmatoarele erori: ", MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }


  VizualizareExtract(extras: ExtrasDeCont) {
    this.accountService.downloadExtras(extras.id).subscribe(result => {
      let blob = new Blob(result, {
        type: 'application/pdf' // must match the Accept type
      });

      let filename = 'Extract_' + extras.denumire + '_' + this.formatOnlyDate(extras.dataExtras, ".") + '.pdf';
      FileSaver.saveAs(blob, filename);
    });
  }

  TrimitereMail(extras: ExtrasDeCont[]) {
    this.accountService.sendMailExtras(extras).subscribe(
      role => {
        this.saveSuccessHelper(`Extrasul a fost trimis cu succes!`);
    });
  }

  solicitareTrimitereMailuri() {
    let extraseSelectate: ExtrasDeCont[] = [];

    for (let extrase of this.dataSourceExtras.data) {
      if (extrase.selectata) {
        extraseSelectate.push(extrase);
      }
    }

    if (extraseSelectate.length == 0) {
      this.alertService.showStickyMessage("Save Error", "Trebuie sa selectati cel putin un extras!", MessageSeverity.error, null);
      return;
    }

    this.alertService.showDialog('Confirmati', 'Confirmati generarea extraselor?', DialogType.confirm, () => this.TrimitereMailuri(extraseSelectate));
  }

  TrimitereMailuri(extraseSelectate: ExtrasDeCont[]) {
    this.accountService.sendMailExtras(extraseSelectate).subscribe(
      rezultat => {
        this.saveSuccessHelper(`Extrasul a fost trimis cu succes!`);
      },
      error => {
        console.log(error);
      });
    this.afiseazaDateExtras();
  }

  private saveSuccessHelperAnulare(mesaj?: string) {
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage("Success", mesaj, MessageSeverity.success);
    this.afiseazaDateExtras();
  }  

  inapoiInMeniulPrincipal() {
    this.generareExtras = 'N';
    this.afiseazaCircuitePentruGenerareFacturiServiciu = "N";
    this.afisazaDateExtrasIntroducere = false;
  }

  genereazaExtras() {
    //this.extras.clientid = this.companieExtras.companieId;
    let data = this.extras.dataExtras == null ? "undefined" : this.formatOnlyDate(this.extras.dataExtras, ".");
    let dataNumar = this.extras.dataNumar == null ? "undefined" : this.formatOnlyDate(this.extras.dataNumar, ".");
    this.accountService.definireExtras(this.companieExtras.companieId, data, this.extras.numar, dataNumar).subscribe(
      role => {
        this.saveSuccessHelper(`Extrasul a fost salvata cu succes!`);
        this.extras = role;
        let firma = this.companieExtras.denumire == null || this.companieExtras.denumire == "" ? "undefined" : this.companieExtras.denumire;

        this.accountService.downloadExtras(this.extras.id).subscribe(result => {
          let blob = new Blob(result, {
            type: 'application/pdf' // must match the Accept type
          });

          let filename = 'Extract_' + firma + '_' + this.formatOnlyDate(this.extras.dataExtras, ".")  + '.pdf';
          FileSaver.saveAs(blob, filename);
        });
      },
      error => this.saveFailedHelper(error));

  }

}
