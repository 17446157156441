// =============================
// Email: info@ebenmonney.com
// www.ebenmonney.com/templates
// =============================

import { Component, OnInit, OnDestroy, AfterViewInit, Input, TemplateRef, ViewChild, EventEmitter, Output } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar, MatDialog } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';

import { AuthService } from '../../services/auth.service';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { debounceTime, distinctUntilChanged, switchMap, catchError } from 'rxjs/operators';
import { AppTranslationService } from "../../services/app-translation.service";
import { LocalStoreManager } from '../../services/local-store-manager.service';
import { Utilities } from "../../services/utilities";
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { AccountService } from "../../services/account.service";

import { of } from 'rxjs';
import { Companie } from 'src/app/models/companie.model';
import { DetaliuCircuit } from 'src/app/models/DetaliuCircuit.model';

@Component({
  selector: 'selecteazaCompanie',
  templateUrl: './selecteazaCompanie.component.html',
  styleUrls: ['./selecteazaCompanie.component.scss']
})

export class SelecteazaCompanieComponent
{
  companiiCautate: Companie[];
  
  tbCompanie1Autocomplete = new FormControl();

  @Input() detaliuCircuit: DetaliuCircuit;
  @Input() denumireControl: string = 'Companie';
  @Input() inputTextActiv: string;
  @Output() detaliuCircuitChanged = new EventEmitter();

  selectedOption: string;

  constructor(
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private accountService: AccountService
  ) {

  }

  getCompanie(event: any) {
    let searchTerm = '';
    searchTerm += event.target.value;

    if (searchTerm.length >= 2) {
      this.retriveListOfCompanii();
    }
  }

  retriveListOfCompanii() {
    this.tbCompanie1Autocomplete
      .valueChanges
      .pipe(debounceTime(300),
        distinctUntilChanged(),
      switchMap(term => this.accountService.getCompaniiReprezentateListForAutocomplete(term)),
        catchError(error => {
          this.selectedOption = "";
          return of<any[]>([]);
        })
      )
      .subscribe((result) => {
        if (result != null) {
          if (result.length > 0) {
            let liniiReconverted = JSON.parse(JSON.stringify(result[0]));
            this.companiiCautate = liniiReconverted;
          }
          else {
            this.selectedOption = "";
          }
        }
        else {
          this.selectedOption = "";
        }
      });
  }

  save() {

  }

  selecteazaCompanie(companie: Companie) {
    this.tbCompanie1Autocomplete.setValue(companie.denumire);

    this.detaliuCircuit.denumire = companie.denumire;
    this.detaliuCircuit.cif = companie.cif.toString();
    this.detaliuCircuit.companieId = companie.id;

    this.detaliuCircuitChanged.emit(companie);
  }

  public reset() {
    this.tbCompanie1Autocomplete.setValue('');
  }
}
