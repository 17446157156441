// =============================
// Email: info@ebenmonney.com
// www.ebenmonney.com/templates
// =============================

import { Component, OnInit, OnDestroy, AfterViewInit, Input, TemplateRef, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar, MatDialog } from '@angular/material';
import { fadeInOut } from '../../services/animations';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { AccountService } from "../../services/account.service";
import { Companie } from 'src/app/models/Companie.model';
import * as FileSaver from 'file-saver'; 

@Component({
    selector: 'companiereprezentari',
  templateUrl: './companiereprezentari.component.html',
  styleUrls: ['./companiereprezentari.component.scss'],
    animations: [fadeInOut]
})

export class CompanieReprezentariComponent
{
  private isSaving: boolean;
  private isNew: boolean;
  companieForm: FormGroup;
  companie: Companie;
  definireCompanie: boolean = false;

  denumire: string = '';
  judet: string = '';
  reprezentant: string = '';
  stare: string = '';
  displayedColumns = ['cif', 'denumire', 'judet', 'reprezentant', 'stare', 'actions'];
  isDataLoaded: boolean = false;
  loadingIndicator: boolean = true;
  formResetToggle: boolean = true;
  _currentUserId: string;
  data: Companie[];
  dataSource: MatTableDataSource<Companie>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  //

  afiseazaDate() {
    this.loadingIndicator = true;

    let denumire = this.denumire == null || this.denumire == "" ? "undefined" : this.denumire;
    let judet = this.judet == null || this.judet == "" ? "undefined" : this.judet;
    let reprezentant = this.reprezentant == null || this.reprezentant == "" ? "undefined" : this.reprezentant;
    let stare = this.stare == null || this.stare == "" ? "undefined" : this.stare;
    
    this.accountService
      .getCompaniiReprezentateList(denumire, judet, reprezentant, stare, 'Y')
      .subscribe(results => {
        let linii = results[0];

        let liniiReconverted = JSON.parse(JSON.stringify(linii));

        this.data = liniiReconverted;

        this.dataSource.data = this.data;

        this.isDataLoaded = true;

        setTimeout(() => { this.loadingIndicator = false; }, 1500);
      },
        error => {
        });

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  reset() {
    this.denumire = '';
    this.judet = '';
    this.reprezentant = '';
    this.stare = '';
  }

  blocareEditareCIF: boolean;
  modificareCompanie(companie: Companie) {
    this.definireCompanie = true;
    this.companie = companie;
    this.blocareEditareCIF = true;
    this.companie.stareafisare = this.companie.stare == "Neaprobat" ? "In curs de aprobare" : "Aprobat"; 
  }

  adaugare() {
    this.definireCompanie = true;
    this.companie = new Companie();
    this.blocareEditareCIF = false;
  }

  cereAprobareReprezentant(companie: Companie) {
    this.alertService.showDialog('Confirmati', 'Confirmati aprobarea reprezentantului   ' + companie.reprezentant + '   pentru compania   ' + companie.denumire + ' ?', DialogType.confirm, () => this.aprobaCompanie(companie));
  }

  aprobaCompanie(companie: Companie) {
    this.alertService.startLoadingMessage('Confirmare reprezentare...');
    this.accountService.aprobareReprezentareCompanie(companie).subscribe(
      role => this.saveSuccessHelper(companie, 'Reprezentarea a fost aprobata!'),
      error => this.saveFailedHelper(error));
  }

  cereAnulareReprezentare(companie: Companie) {
    this.alertService.showDialog('Confirmati', 'Confirmati anularea reprezentantului   ' + companie.reprezentant + '   pentru compania   ' + companie.denumire + ' ?', DialogType.confirm, () => this.anuleazaReprezentareCompanie(companie));
  }

  anuleazaReprezentareCompanie(companie: Companie) {
    this.alertService.startLoadingMessage('Confirmare reprezentare...');
    this.accountService.anuleazaReprezentareCompanie(companie).subscribe(
      role => {
        this.saveSuccessHelper(companie, 'Reprezentarea a fost anulata!');
        this.afiseazaDate();
      },
      error => this.saveFailedHelper(error));
  }

  afuConfig = {
    uploadAPI: {
      url: "https://example-file-upload-api"
    }
  };

  constructor(
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
  )
  {
    this.dataSource = new MatTableDataSource<Companie>();
    this.buildForm();
  }

  ngOnInit() {
    this.companie = new Companie();
    this.afiseazaDate();
  }
  
  save() {
      this.accountService.newCompanie(this.companie).subscribe(
        role => this.saveSuccessHelper(this.companie, `Datele companiei au fost salvate cu succes!`),
        error => this.saveFailedHelper(error));
  }

  private saveSuccessHelper(companie?: Companie, mesaj?: string) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();

    this.alertService.showMessage("Success", mesaj, MessageSeverity.success);

    this.definireCompanie = false;
    this.afiseazaDate();
  }

  private saveFailedHelper(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage("Save Error", "In procesul de salvare s-au produs urmatoarele erori: ", MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  private buildForm() {
    this.companieForm = this.formBuilder.group({
      tbDenumire: ['', Validators.required],
      tbCIF: ['', Validators.required],
      tbRegcom: ['', Validators.required],
      tbDocument: ['', Validators.required],
      tbLocalitate: ['', Validators.required],
      tbAdresa: ['', Validators.required],
      tbJudet: ['', Validators.required],
      tbBanca: ['', Validators.required],
      tbContBancar: ['', Validators.required],
      tbTelefon: ['', Validators.required],
      tbEmail: ['', Validators.required],
      tbNotificare: ['', Validators.required],
      tbStare: new FormControl({ value: '' })
    });
  }

  get tbNotificare() {
    return this.companieForm.get('tbNotificare');
  }

  get tbEmail() {
    return this.companieForm.get('tbEmail');
  }
  get tbDenumire() {
    return this.companieForm.get('tbDenumire');
  }

  get tbCIF() {
    return this.companieForm.get('tbCIF');
  }

  get tbRegcom() {
    return this.companieForm.get('tbRegcom');
  }

  get tbDocument() {
    return this.companieForm.get('tbDocument');
  }

  get tbLocalitate() {
    return this.companieForm.get('tbLocalitate');
  }

  get tbAdresa() {
    return this.companieForm.get('tbAdresa');
  }

  get tbJudet() {
    return this.companieForm.get('tbJudet');
  }

  get tbBanca() {
    return this.companieForm.get('tbBanca');
  }

  get tbContBancar() {
    return this.companieForm.get('tbContBancar');
  }

  get tbTelefon() {
    return this.companieForm.get('tbTelefon');
  }

  get tbStare() {
    return this.companieForm.get('tbStare');
  }
  
  private cancel() {
    this.definireCompanie = false;
    this.afiseazaDate();
  }

  vizualizareDetaliiCompanie(companie: Companie) {
    this.definireCompanie = true;
    this.companie = companie;
    this.blocareEditareCIF = true;
    this.companie.stareafisare = this.companie.stare == "Neaprobat" ? "In curs de aprobare" : "Aprobat";
  }

  descarcaFisier(mod: string) {
    this.accountService.downloadDocumentCompanie(this.companie.reprezentantid, this.companie.id, mod).subscribe(result => {
      let blob = new Blob(result, {
        type: 'application/pdf' // must match the Accept type
      });

      let filename = 'Document_' + mod + '.pdf';
      FileSaver.saveAs(blob, filename);
    });
  }
}
