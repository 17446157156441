
import { Component, OnInit, OnDestroy, AfterViewInit, Input, TemplateRef, ViewChild } from '@angular/core';
import { fadeInOut } from '../../services/animations';
import { debounceTime, distinctUntilChanged, switchMap, catchError } from 'rxjs/operators';
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar, MatDialog } from '@angular/material';
import { AccountService } from "../../services/account.service";
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { of } from 'rxjs';
import { Companie } from 'src/app/models/companie.model';
import { DetaliuCircuit } from 'src/app/models/DetaliuCircuit.model';
import { Compensare } from 'src/app/models/compensare.model';
import { CompensareDetaliu } from 'src/app/models/CompensareDetaliu.model';
import * as FileSaver from 'file-saver'; 
import { CompensareFacturare, GenerareFactura } from 'src/app/models/CompensareFacturare.model';
import { Factura } from 'src/app/models/factura.model';
import { FacturaServiciu } from 'src/app/models/FacturaServiciu.model';
import { DateTimeAdapter } from 'ng-pick-datetime';

@Component({
  selector: 'proformaClient',
  templateUrl: './proformaClient.component.html',
  styleUrls: ['./proformaClient.component.scss'],
    animations: [fadeInOut]
})
export class ProformaClientComponent {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  companieFactura: DetaliuCircuit;
  firmaCautare: string = "";
  cautareStareFactura: string = "";
  dataInceput: Date;
  dataFinal: Date;
  dataFactura: Date;
  generareFactura: string = "N";
  displayedColumnsFacturi = ['selectie', 'client', 'serie', 'numar', 'dataFacturare', 'cantitate', 'total', 'descarcaFactura'];
  
  isDataLoaded: boolean = false;
  formResetToggle: boolean = true;
  data: CompensareFacturare[];
  
  isDataLoadedFacturi: boolean = false;
  loadingIndicatorFacturi: boolean = true;
  dataFacturi: FacturaServiciu[];
  dataSourceFacturi: MatTableDataSource<FacturaServiciu>;
  numarCircuiteFacturate: number;

  loadingIndicator: boolean = true;
  dataSource: MatTableDataSource<CompensareFacturare>;
  displayedColumns = ['selectie', 'id', 'companie', 'hash', 'suma', 'actions'];
  afiseazaCircuitePentruGenerareFacturiServiciu: boolean = false;

  constructor(
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    dateTimeAdapter: DateTimeAdapter<any>
  ) {
    dateTimeAdapter.setLocale('ro-RO');
    this.dataSourceFacturi = new MatTableDataSource<FacturaServiciu>();
    this.dataSource = new MatTableDataSource<CompensareFacturare>();
  }

  ngOnInit() {
    this.dataFactura = new Date();
    this.companieFactura = new DetaliuCircuit();

    this.dataFinal = new Date();
    this.dataInceput = new Date();
    this.dataInceput.setDate(this.dataInceput.getDate() - 10);

    this.afiseazaDateFactura();
  }

  afiseazaMeniuGenerareFactura() {
    this.dataFactura = new Date();
    this.companieFactura = new DetaliuCircuit();

    this.generareFactura = 'Y';
  }

  afiseazaDateFactura() {

    let firma = this.firmaCautare == null || this.firmaCautare == "" ? "undefined" : this.firmaCautare;
    let dataInceput = this.dataInceput == null ? "undefined" : this.formatOnlyDate(this.dataInceput, ".");
    let dataFinal = this.dataFinal == null ? "undefined" : this.formatOnlyDate(this.dataFinal, ".");
    let stare = this.cautareStareFactura == null || this.cautareStareFactura == "" ? "undefined" : this.cautareStareFactura;
    
    this.accountService
      .getFacturiProformeList(firma, "0", dataInceput, dataFinal)
      .subscribe(results => {
        
        let linii = results[0];

        let liniiReconverted = JSON.parse(JSON.stringify(linii));

        this.dataFacturi = liniiReconverted;

        this.dataSourceFacturi.data = this.dataFacturi;

        this.isDataLoadedFacturi = true;

        this.dataSourceFacturi.paginator = this.paginator;
        this.dataSourceFacturi.sort = this.sort;

        setTimeout(() => { this.loadingIndicatorFacturi = false; }, 1500);
      },
        error => {
        });
  }

  formatOnlyDate(value: Date, separator: string): string {
    var date = new Date(value);
    var mm = date.getMonth() + 1; // getMonth() is zero-based
    var dd = date.getDate();

    return [
      (dd > 9 ? '' : '0') + dd,
      (mm > 9 ? '' : '0') + mm,
      date.getFullYear()
    ].join(separator);
  }
  
  reset() {

  }
  
  solicitaGenerareFactura() {

    if (this.data === undefined) {
      this.alertService.showStickyMessage("Save Error", "Efectuati cautarea circuitelor pentru a putea genera factura!", MessageSeverity.error, null);
      return;
    }

    if (this.data !== undefined &&
      this.data.length === 0) {
      this.alertService.showStickyMessage("Save Error", "Generarea facturii nu se poate realiza, deoarece nu exista circuite in lucru!", MessageSeverity.error, null);
      return;
    }

    this.alertService.showDialog('Confirmati', 'Confirmati generarea facturii?', DialogType.confirm, () => this.genereazaFactura());
  }
  
  genereazaFactura() {
    let compensariSelectate: CompensareFacturare[] = [];

    for (let compensare of this.data) {
      if (compensare.selectata) {
        compensariSelectate.push(compensare);
      }
    }

    if (compensariSelectate.length === 0) {
      this.alertService.showStickyMessage("Save Error", "Trebuie sa selectati minim un circuit pentru a putea genera factura!", MessageSeverity.error, null);
      return;
    }

    let generareFactura = new GenerareFactura();
    generareFactura.companieId = this.companieFactura.companieId;
    generareFactura.dataFactura = this.dataFactura == null ? "undefined" : this.formatOnlyDate(this.dataFactura, ".");
    generareFactura.compensari = compensariSelectate;

    this.accountService.genereazaFacturaProforma(generareFactura).subscribe(
      role => this.saveSuccessHelper(`Generarea facturii s-a realizat cu succes!`),
      error => this.saveFailedHelper(error));
  }
  
  private saveSuccessHelper(mesaj?: string) {
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage("Success", mesaj, MessageSeverity.success);
    this.inapoiInMeniulPrincipal();
    this.afiseazaDateFactura();
  }

  private saveFailedHelper(error: any) {
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage("Save Error", "In procesul de salvare s-au produs urmatoarele erori: ", MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  VizualizareFactura(factura: FacturaServiciu) {
    this.accountService.downloadFacturaProforma(factura.id.toString()).subscribe(result => {
      let blob = new Blob(result, {
        type: 'application/pdf' // must match the Accept type
      });

      let filename = 'FacturaProforma_' + factura.id + '.pdf';
      FileSaver.saveAs(blob, filename);
    });
  }

  inapoiInMeniulPrincipal() {
    this.generareFactura = 'N';
    this.afiseazaCircuitePentruGenerareFacturiServiciu = false;
  }

  afiseazaDate() {

    this.accountService
      .getCompensariListPtFacturareProforma("undefined")
      .subscribe(results => {

        let linii = results[0];

        let liniiReconverted = JSON.parse(JSON.stringify(linii));

        this.data = liniiReconverted;

        this.dataSource.data = this.data;

        this.isDataLoaded = true;
        this.afiseazaCircuitePentruGenerareFacturiServiciu = true;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        setTimeout(() => { this.loadingIndicator = false; }, 1500);

      },
        error => {
        });
  }

}
