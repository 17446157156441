
import { Component, OnInit, OnDestroy, AfterViewInit, Input, TemplateRef, ViewChild } from '@angular/core';
import { fadeInOut } from '../../services/animations';
import { debounceTime, distinctUntilChanged, switchMap, catchError } from 'rxjs/operators';
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar, MatDialog } from '@angular/material';
import { AccountService } from "../../services/account.service";
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { of } from 'rxjs';
import { Companie } from 'src/app/models/companie.model';
import { DetaliuCircuit } from 'src/app/models/DetaliuCircuit.model';
import { Compensare } from 'src/app/models/compensare.model';
import { CompensareDetaliu } from 'src/app/models/CompensareDetaliu.model';
import * as FileSaver from 'file-saver'; 
import { CompensareFacturare, GenerareFactura } from 'src/app/models/CompensareFacturare.model';
import { Factura } from 'src/app/models/factura.model';
import { FacturaServiciu } from 'src/app/models/FacturaServiciu.model';
import { DateTimeAdapter } from 'ng-pick-datetime';

@Component({
  selector: 'compensarinefacturate',
  templateUrl: './compensarinefacturate.component.html',
  styleUrls: ['./compensarinefacturate.component.scss'],
  animations: [fadeInOut]
})
export class CompensariNefacturateComponent {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  companieFactura: DetaliuCircuit;
  afiseazaCircuitePentruGenerareFacturiServiciu: string = "N";
  dataFactura: Date;
  dataCircuite: Date;
  generareFactura: string = "Y";
  displayedColumns = ['selectie', 'id', 'companie', 'hash', 'suma', 'dataFinalizare', 'actions'];

  isDataLoaded: boolean = false;
  loadingIndicator: boolean = true;
  formResetToggle: boolean = true;
  data: CompensareFacturare[];
  dataSource: MatTableDataSource<CompensareFacturare>;
  dezactiveazaSelectieCompanie: string = 'N';
  
  isDataLoadedFacturi: boolean = false;
  dataFacturi: FacturaServiciu[];

  constructor(
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    dateTimeAdapter: DateTimeAdapter<any>
  ) {
    dateTimeAdapter.setLocale('ro-RO');
    this.dataSource = new MatTableDataSource<CompensareFacturare>();
  }

  ngOnInit() {
    this.dezactiveazaSelectieCompanie = 'N';
    this.dataCircuite = new Date();

    this.dataFactura = new Date();
    this.companieFactura = new DetaliuCircuit();
    this.companieFactura.companieId = '0';

    this.dataFactura = new Date();
    
    this.generareFactura = 'Y';
  }
  
  formatOnlyDate(value: Date, separator: string): string {
    var date = new Date(value);
    var mm = date.getMonth() + 1; // getMonth() is zero-based
    var dd = date.getDate();

    return [
      (dd > 9 ? '' : '0') + dd,
      (mm > 9 ? '' : '0') + mm,
      date.getFullYear()
    ].join(separator);
  }

  /*
  ascundeSauAfiseaza() {
    if (this.generareFactura == 'Y') {
      this.generareFactura = 'N';
    }
    else {
      this.generareFactura = 'Y';
    }
  }
  */

  afiseazaDate() {
    this.dezactiveazaSelectieCompanie = 'Y';

    this.afiseazaCircuitePentruGenerareFacturiServiciu = "N";
    
    let companieFactura = this.companieFactura == null ? "undefined" : this.companieFactura.companieId;
    
    this.accountService
      .getCompensariListPtFacturare(companieFactura)
      .subscribe(results => {

        let linii = results[0];

        let liniiReconverted = JSON.parse(JSON.stringify(linii));

        this.data = liniiReconverted;

        this.dataSource.data = this.data;

        this.isDataLoaded = true;

        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        setTimeout(() => { this.loadingIndicator = false; }, 1500);

        this.afiseazaCircuitePentruGenerareFacturiServiciu = "Y";
      },
        error => {
          this.afiseazaCircuitePentruGenerareFacturiServiciu = "N";
        });
  }

  reset() {
    this.dezactiveazaSelectieCompanie = 'N';
    this.afiseazaCircuitePentruGenerareFacturiServiciu = "N";
    this.companieFactura = new DetaliuCircuit();
    this.companieFactura.companieId = '0';
  }

  solicitaGenerareFactura() {
    if (typeof this.companieFactura.companieId === "undefined") {
      this.alertService.showStickyMessage("Save Error", "Trebuie sa selectati compania pentru care se va genera factura!", MessageSeverity.error, null);
      return;
    }

    if (this.companieFactura.companieId == "") {
      this.alertService.showStickyMessage("Save Error", "Trebuie sa selectati compania pentru care se va genera factura!", MessageSeverity.error, null);
      return;
    }

    let compensariSelectate: CompensareFacturare[] = [];

    for (let compensare of this.data) {
      if (compensare.selectata) {
        compensariSelectate.push(compensare);
      }
    }

    if (compensariSelectate.length == 0) {
      this.alertService.showStickyMessage("Save Error", "Trebuie sa selectati cel putin un ciruit de compensare!", MessageSeverity.error, null);
      return;
    }

    this.alertService.showDialog('Confirmati', 'Confirmati generarea facturii?', DialogType.confirm, () => this.genereazaFactura());
  }

  solicitaGenerareFacturaSiChitanta() {
    if (typeof this.companieFactura.companieId === "undefined") {
      this.alertService.showStickyMessage("Save Error", "Trebuie sa selectati compania pentru care se va genera factura!", MessageSeverity.error, null);
      return;
    }

    if (this.companieFactura.companieId == "") {
      this.alertService.showStickyMessage("Save Error", "Trebuie sa selectati compania pentru care se va genera factura!", MessageSeverity.error, null);
      return;
    }

    let compensariSelectate: CompensareFacturare[] = [];

    for (let compensare of this.data) {
      if (compensare.selectata) {
        compensariSelectate.push(compensare);
      }
    }

    if (compensariSelectate.length == 0) {
      this.alertService.showStickyMessage("Save Error", "Trebuie sa selectati cel putin un ciruit de compensare!", MessageSeverity.error, null);
      return;
    }

    this.alertService.showDialog('Confirmati', 'Confirmati generarea facturii si a chitantei?', DialogType.confirm, () => this.genereazaFacturaSiChitanta());
  }

  genereazaFactura() {
    let compensariSelectate: CompensareFacturare[] = [];

    for (let compensare of this.data) {
      if (compensare.selectata) {
        compensariSelectate.push(compensare);
      }
    }

    let generareFactura = new GenerareFactura();
    generareFactura.companieId = this.companieFactura.companieId;
    generareFactura.dataFactura = this.dataFactura == null ? "undefined" : this.formatOnlyDate(this.dataFactura, ".");
    generareFactura.compensari = compensariSelectate;

    this.accountService.genereazaFacturaServiciu(generareFactura).subscribe(
      role => this.saveSuccessHelper(`Generarea facturii s-a realizat cu succes!`),
      error => this.saveFailedHelper(error));
  }

  genereazaFacturaSiChitanta() {
    let compensariSelectate: CompensareFacturare[] = [];

    for (let compensare of this.data) {
      if (compensare.selectata) {
        compensariSelectate.push(compensare);
      }
    }

    let generareFactura = new GenerareFactura();
    generareFactura.companieId = this.companieFactura.companieId;
    generareFactura.dataFactura = this.dataFactura == null ? "undefined" : this.formatOnlyDate(this.dataFactura, ".");
    generareFactura.compensari = compensariSelectate;

    this.accountService.genereazaFacturaSiChitantaServiciu(generareFactura).subscribe(
      role => this.saveSuccessHelper(`Generarea facturii si a chitantei s-a realizat cu succes!`),
      error => this.saveFailedHelper(error));
  }

  private saveSuccessHelper(mesaj?: string) {
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage("Success", mesaj, MessageSeverity.success);
    this.afiseazaDate();
  }

  private saveFailedHelper(error: any) {
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage("Save Error", "In procesul de salvare s-au produs urmatoarele erori: ", MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }
  
  inapoiInMeniulPrincipal() {
    this.generareFactura = 'N';
    this.afiseazaDate();
  }
}
