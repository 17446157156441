// =============================
// Email: info@ebenmonney.com
// www.ebenmonney.com/templates
// =============================

import { Component } from '@angular/core';
import { fadeInOut } from '../../services/animations';
import { AccountService } from "../../services/account.service";
import * as FileSaver from 'file-saver';

@Component({
  selector: 'imputernicire',
  templateUrl: './imputernicire.component.html',
  styleUrls: ['./imputernicire.component.scss'],
    animations: [fadeInOut]
})
export class Imputernicire {

  constructor(

    private accountService: AccountService

  ) { }

  descarcaImputernicire() {
    this.accountService.descarcaImputernicire().subscribe(result => {
      let blob = new Blob(result, {
        type: 'application/doc' // must match the Accept type
      });

      let filename = 'Imputernicire.doc';
      FileSaver.saveAs(blob, filename);
    });
  }
}
