
import { Component, OnInit, OnDestroy, AfterViewInit, Input, TemplateRef, ViewChild } from '@angular/core';
import { fadeInOut } from '../../services/animations';
import { debounceTime, distinctUntilChanged, switchMap, catchError } from 'rxjs/operators';
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar, MatDialog } from '@angular/material';
import { AccountService } from "../../services/account.service";
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { of } from 'rxjs';
import { Companie } from 'src/app/models/companie.model';
import { DetaliuCircuit } from 'src/app/models/DetaliuCircuit.model';
import { Compensare } from 'src/app/models/compensare.model';
import { CompensareDetaliu } from 'src/app/models/CompensareDetaliu.model';
import * as FileSaver from 'file-saver'; 

@Component({
    selector: 'calculcircuite',
    templateUrl: './calculcircuite.component.html',
    styleUrls: ['./calculcircuite.component.scss'],
    animations: [fadeInOut]
})
export class CalculCircuiteComponent {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  
  circuitCompensareForm: FormGroup;
  definireCompensare: string = "N";

  displayedColumns = ['id', 'suma', 'hash', 'stare', 'tipCompensare', 'dataCreare', 'actions'];
  isDataLoaded: boolean = false;
  loadingIndicator: boolean = true;
  formResetToggle: boolean = true;
  _currentUserId: string;
  data: Compensare[];
  dataSource: MatTableDataSource<Compensare>;

  compensareDetaliata: Compensare;

  afuConfig = {
    multiple: false,
    formatsAllowed: ".pdf",
    maxSize: "1",
    uploadAPI: {
      url: "https://example-file-upload-api",
      headers: {
        "Content-Type": "text/plain;charset=UTF-8"
      }
    },
    theme: "attachPin",
    hideProgressBar: true,
    hideResetBtn: true,
    hideSelectBtn: true,
    replaceTexts: {
      attachPinBtn: 'Selecteaza fisier...'
    }
  };
  
  constructor(
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private accountService: AccountService
  ) {
    this.dataSource = new MatTableDataSource<Compensare>();
    this.circuitCompensareForm = this.formBuilder.group({
      
    });
  }

  ngOnInit() {
    setTimeout(() => { this.loadingIndicator = false; }, 1500);
  }

  afiseazaDate() {
    this.loadingIndicator = true;
    
    this.accountService
      .getCompensariCircuiteList()
      .subscribe(results => {
        
        let linii = results[0];

        let liniiReconverted = JSON.parse(JSON.stringify(linii));

        this.data = liniiReconverted;

        this.dataSource.data = this.data;

        this.isDataLoaded = true;

        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.loadingIndicator = false;
        
        let nrCircuite = this.data.length;
        let mesaj = 'Au fost identificate ' + nrCircuite + ' circuite de compensar!';
        this.alertService.showDialog('Informare', mesaj, DialogType.alert, () => null);
      },
        error => {
          this.loadingIndicator = false;
          this.saveFailedHelper(error);
        });
  }
  
  public cereAprobareGenerareCompensare(compensare: Compensare) {
    this.alertService.showDialog('Confirmare', 'Confirmati generarea compensarii?', DialogType.confirm, () => this.saveCompensare(compensare));
  }

  saveCompensare(compensare: Compensare) {
    this.accountService.definireCircuitCompensare(compensare.detalii).subscribe(
      role => this.saveSuccessHelper(compensare, `Compensarea a fost creata cu succes!`),
      error => this.saveFailedHelper(error));
  }

  private saveSuccessHelper(compensare: Compensare, mesaj?: string) {
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage("Success", mesaj, MessageSeverity.success);
    this.definireCompensare = 'N';
    this.data = this.data.filter(obj => obj !== compensare);
    this.dataSource.data = this.data;
    this.isDataLoaded = true;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  private saveFailedHelper(error: any) {
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage("Save Error", "In procesul de salvare s-au produs urmatoarele erori: ", MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

}
