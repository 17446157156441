import { Component, OnInit, OnDestroy, AfterViewInit, Input, TemplateRef, ViewChild } from '@angular/core';
import { fadeInOut } from '../../services/animations';
import { debounceTime, distinctUntilChanged, switchMap, catchError } from 'rxjs/operators';
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar, MatDialog } from '@angular/material';
import { AccountService } from "../../services/account.service";
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { of } from 'rxjs';
import { Companie } from 'src/app/models/companie.model';
import { DetaliuCircuit } from 'src/app/models/DetaliuCircuit.model';
import { Compensare } from 'src/app/models/compensare.model';
import { CompensareDetaliu } from 'src/app/models/CompensareDetaliu.model';
import * as FileSaver from 'file-saver'; 
import { DateTimeAdapter } from 'ng-pick-datetime';
import { Asociat } from 'src/app/models/Asociat.model';

@Component({
    selector: 'asociati',
    templateUrl: './asociati.component.html',
    styleUrls: ['./asociati.component.scss'],
    animations: [fadeInOut]
})
export class AsociatiComponent {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  detaliiCircuit: DetaliuCircuit[];
  asociatForm: FormGroup;
  definireAsociat: string = "N";

  displayedColumns = ['companie', 'asociat', 'actions'];
  isDataLoaded: boolean = false;
  loadingIndicator: boolean = true;
  formResetToggle: boolean = true;
  _currentUserId: string;
  data: Asociat[];
  dataSource: MatTableDataSource<Asociat>;

  cui_denumire: string = '';
  
  constructor(
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    dateTimeAdapter: DateTimeAdapter<any>
  ) {
      dateTimeAdapter.setLocale('ro-RO');
      this.dataSource = new MatTableDataSource<Asociat>();
      this.asociatForm = this.formBuilder.group({});
  }

  ngOnInit() {
    this.afiseazaDate();
  }

  afiseazaDate() {
    let cui_denumire = this.cui_denumire == null || this.cui_denumire == "" ? "undefined" : this.cui_denumire;
    this.accountService
      .getAsociatiList(cui_denumire)
      .subscribe(results => {
        let linii = results[0];
        let liniiReconverted = JSON.parse(JSON.stringify(linii));
        this.data = liniiReconverted;
        this.dataSource.data = this.data;
        this.isDataLoaded = true;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        setTimeout(() => { this.loadingIndicator = false; }, 1500);
      },
        error => {
        });
  }
  
  adaugare() {
    this.definireAsociat = 'Y';

    let companie1 = new DetaliuCircuit();
    companie1.nrCrt = 1;

    let companie2 = new DetaliuCircuit();
    companie2.nrCrt = 2;

    this.detaliiCircuit = [companie1, companie2];
  }

  stergeAsociere(asociat: Asociat) {
    this.accountService.stergeAsociat(asociat).subscribe(
      role => this.saveSuccessHelperDefinireAsociati(role, `Asociatul a fost sters!`),
      error => this.saveFailedHelper(error));
  }
  
  saveAsociati() {
    let dateleSuntCompletate = true;
    for (let detaliu of this.detaliiCircuit) {
      if (detaliu.companieId == null) {
        dateleSuntCompletate = false;
        break;
      }
    }

    if (!dateleSuntCompletate) {
      this.alertService.showMessage("Info", "Trebuie sa selectati toate componentele!", MessageSeverity.warn);
      return;
    }

    this.accountService.definireAsociat(this.detaliiCircuit).subscribe(
      role => this.saveSuccessHelperDefinireAsociati(role, `Asociatul a fost definit cu succes!`),
      error => this.saveFailedHelper(error));
  }

  private saveSuccessHelperDefinireAsociati(compensareId: string, mesaj?: string) {
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage("Success", mesaj, MessageSeverity.success);
    this.cancelAdaugare();
  }

  private saveFailedHelper(error: any) {
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage("Save Error", "In procesul de salvare s-au produs urmatoarele erori: ", MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  cancelAdaugare() {
    this.definireAsociat = 'N';
    this.afiseazaDate();
  }
}
