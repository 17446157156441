// =============================
// Email: info@ebenmonney.com
// www.ebenmonney.com/templates
// =============================

import { Component } from '@angular/core';
import { fadeInOut } from '../../services/animations';

@Component({
  selector: 'informareGdpr',
  templateUrl: './informareGdpr.component.html',
  styleUrls: ['./informareGdpr.component.scss'],
    animations: [fadeInOut]
})
export class InformareGdprComponent { }
